import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import { clientConfig } from 'utils/config';

declare global {
	interface IMembershipState {
		membershipConfig: IMembershipConfig | null;
	}

	interface IMembershipConfig {
		registrationEnabled: boolean;
	}
}

export const MembershipModuleName = 'membership';

@Module({ namespaced: true, name: MembershipModuleName })
class MembershipModule extends VuexModule implements IMembershipState {
	@MutationAction
	public async setConfig(value: IMembershipConfig) {
		return {
			membershipConfig: value
		};
	}
	public membershipConfig: IMembershipConfig | null;

	constructor(arg: any) {
		super(arg);
		this.membershipConfig =
			clientConfig.moduleConfigurations == null
				? null
				: (clientConfig.moduleConfigurations['membership'] as IMembershipConfig);
	}
}

export { MembershipModule };
