import { LocalizeFilter } from 'ui-controls-core/filters/localize-filter';
import { FormatFilter } from 'ui-controls-core/filters/format-filter';
import { UppercaseFilter } from 'ui-controls-core/filters/uppercase-filter';
import { AutofocusDirective } from 'ui-controls-core/directives/autofocus-directive';
import { PreventBackspaceNavigationDirective } from 'ui-controls-core/directives/prevent-backspace-navigation-directive';
import { VisibilityDirective } from 'ui-controls-core/directives/visibility-directive';
import { BackButton } from 'ui-controls-core/buttons/back-button/back-button';
import { CloseButton } from 'ui-controls-core/buttons/close-button/close-button';
import { CloseIcon } from 'ui-controls-core/icons/close-icon/close-icon';
import { SvgIcon } from 'ui-controls-core/icons/svg-icon/svg-icon';
import Vue from 'vue';
import { LocalizeMediaFilter } from './filters/localize-media-filter';
import { LocalizeWbTextFilter } from './filters/localize-wb-text-filter';

export function registerUIComponents() {
	Vue.directive('pnz-prevent-backspace-navigation', new PreventBackspaceNavigationDirective());
	Vue.directive('pnz-autofocus', new AutofocusDirective());
	Vue.directive('pnz-visible', new VisibilityDirective());

	Vue.component('pnz-back-button', BackButton);
	Vue.component('pnz-close-button', CloseButton);
	Vue.component('pnz-close-icon', CloseIcon);
	Vue.component('pnz-svg-icon', SvgIcon);

	Vue.filter('localize', LocalizeFilter);
	Vue.filter('format', FormatFilter);
	Vue.filter('uppercase', UppercaseFilter);
	Vue.filter('localize-media', LocalizeMediaFilter);
	Vue.filter('localize-wb-text', LocalizeWbTextFilter);
}
