import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import { clientConfig } from 'utils/config';

declare global {
	interface ISystemState {
		antiForgeryToken: string;
	}

	interface IClientConfig {
		antiForgeryToken: string | null;
	}
}

export const SystemModuleName = 'system';

@Module({ namespaced: true, name: SystemModuleName })
class SystemModule extends VuexModule implements ISystemState {
	@MutationAction
	public async setAntiForgeryToken(antiForgeryToken: any) {
		return {
			antiForgeryToken: antiForgeryToken
		};
	}
	public antiForgeryToken: string;

	constructor(arg: any) {
		super(arg);
		this.antiForgeryToken = clientConfig.antiForgeryToken!;
	}
}

export { SystemModule };
