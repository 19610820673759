import { DirectiveOptions, VNodeDirective } from 'vue';

export class AutofocusDirective implements DirectiveOptions {
	public inserted(el: HTMLElement, binding: VNodeDirective) {
		const value = binding.value;
		if (value === true) {
			el.focus();
		}
	}
}
