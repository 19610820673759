import { $, $wnd, isMobile } from './_utility';

/*------------------------------------------------------------------

  Init Background Audio

-------------------------------------------------------------------*/
function initBackgroundAudio() {
    const self = this;
    const $toggle = $('.nk-bg-audio-toggle');
    if (!self.options.backgroundMusic || typeof soundManager === 'undefined') {
        $toggle.hide();
        return;
    }
    let api;

    // hide / show play icon
    $toggle.find('.nk-bg-audio-play-icon').hide();

    function saveParams() {
        if (api) {
            localStorage.nkBackgroundAudio = JSON.stringify({
                playing: !api.paused && api.playState,
                progress: api.position,
            });
        }
    }
    // save on close window and every 20 seconds
    $wnd.on('unload', saveParams);
    setInterval(saveParams, 20000);

    function getParams() {
        let params = {
            playing: self.options.backgroundMusicAutoplay,
            progress: 0,
        };

        // restore local data
        if (localStorage && typeof localStorage.nkBackgroundAudio !== 'undefined') {
            const storedData = JSON.parse(localStorage.nkBackgroundAudio);
            params = $.extend(params, storedData);
        }

        // prevent autoplay on mobile devices
        if (isMobile) {
            params.playing = false;
        }

        return params;
    }

    function onPlay() {
        $toggle.find('.nk-bg-audio-play-icon').hide();
        $toggle.find('.nk-bg-audio-pause-icon').show();
    }
    function onStop() {
        $toggle.find('.nk-bg-audio-pause-icon').hide();
        $toggle.find('.nk-bg-audio-play-icon').show();
    }


    const params = getParams();

    // toggle button if autoplay
    if (params.playing) {
        onPlay();
    } else {
        onStop();
    }

    // fade
    let fadeInterval;
    function fadeOut() {
        let volume = api.volume;
        const dur = 1000;
        const toVol = 0;
        const interval = dur / Math.abs(volume - toVol);
        clearInterval(fadeInterval);
        fadeInterval = setInterval(() => {
            volume = volume > toVol ? volume - 1 : volume + 1;
            api.setVolume(volume);
            if (volume === toVol) {
                clearInterval(fadeInterval);
                api.pause();
            }
        }, interval);
    }
    function fadeIn() {
        let volume = 0;
        const dur = 1000;
        const toVol = self.options.backgroundMusicVolume;
        const interval = dur / Math.abs(volume - toVol);
        api.play({
            url: self.options.backgroundMusic,
        });
        api.setVolume(volume);

        clearInterval(fadeInterval);
        fadeInterval = setInterval(() => {
            volume = volume > toVol ? volume - 1 : volume + 1;
            api.setVolume(volume);
            if (volume === toVol) {
                clearInterval(fadeInterval);
            }
        }, interval);
    }

    soundManager.onready(() => {
        let firstLoad = 1;
        api = soundManager.createSound({
            onplay() {
                onPlay();
            },
            onresume() {
                onPlay();
            },
            onpause() {
                onStop();
            },
            onstop() {
                onStop();
            },
            volume: self.options.backgroundMusicVolume,
            onload(ok) {
                if (!ok && this._iO && this._iO.onerror) {
                    this._iO.onerror();
                }
            },
            onfinish() {
                if (self.options.backgroundMusicLoop) {
                    api.play();
                }
            },
            onbufferchange() {
                // move to saved progress position on first load
                if (firstLoad && api.duration) {
                    firstLoad = 0;
                    api.setPosition(params.progress);
                }
            },
        });

        // autoplay
        if (params.playing) {
            fadeIn();
        }

        // play / pause
        $toggle.on('click', () => {
            if (api.paused || !api.playState) {
                fadeIn();
            } else {
                api.pause();
            }
        });

        // window focus / blur
        let pausedOnBlur = false;
        $wnd.on('blur focus', (e) => {
            if (e.type === 'blur') {
                // don't pause the background audio when clicked on iframe.
                if (document.activeElement && document.activeElement.nodeName === 'IFRAME') {
                    return;
                }
                if (!api.paused && api.playState) {
                    pausedOnBlur = true;
                    fadeOut();
                }
            } else if (pausedOnBlur) {
                pausedOnBlur = false;
                fadeIn();
            }
        });
    });
}

export { initBackgroundAudio };
