import { $, $wnd, wndW, wndH, debounceResize } from './_utility';

/*------------------------------------------------------------------

  Init Background Video

-------------------------------------------------------------------*/
function initBackgroundVideo() {
    const $bg = $('.nk-page-background');
    if (!$bg.length || typeof window.VideoWorker === 'undefined') {
        return;
    }

    function resizeVideo($video, api) {
        if (!$video || !$video[0]) {
            return;
        }

        // native video size
        const vW = api.videoWidth || 1280;
        const vH = api.videoHeight || 720;

        if (wndW / vW > wndH / vH) {
            $video.css({
                width: wndW,
                height: vH * wndW / vW,
                marginTop: (wndH - vH * wndW / vW) / 2,
                marginLeft: 0,
            });
        } else {
            $video.css({
                width: vW * wndH / vH,
                height: wndH,
                marginTop: 0,
                marginLeft: (wndW - vW * wndH / vH) / 2,
            });
        }
    }

    $bg.each(function eachBackground() {
        const $this = $(this);
        let url = $this.attr('data-video') || '';

        // deprecated syntax
        if (!url) {
            url = [];
            const mp4 = $this.attr('data-bg-mp4') || '';
            const webm = $this.attr('data-bg-webm') || '';
            const ogv = $this.attr('data-bg-ogv') || '';
            const poster = $this.attr('data-bg-poster') || '';
            if (mp4) {
                url.push(`mp4:${mp4}`);
            }
            if (webm) {
                url.push(`webm:${webm}`);
            }
            if (ogv) {
                url.push(`ogv:${ogv}`);
            }
            url = url.join(',');

            // add background image
            if (poster) {
                $this.css({
                    'background-image': `url("${poster}")`,
                });
            }
        }

        // play video
        if (url) {
            const api = new VideoWorker(url, {
                autoplay: 1,
                loop: 1,
                mute: 1,
                controls: 0,
            });

            if (api && api.isValid()) {
                let $video;
                api.getIframe((iframe) => {
                    // add iframe
                    $video = $(iframe);
                    const $parent = $video.parent();
                    $video.appendTo($this);
                    $parent.remove();
                    resizeVideo($video, api);
                });

                api.on('started', () => {
                    $this.addClass('nk-page-background-loaded');
                    resizeVideo($video, api);
                });

                // cover video on resize
                debounceResize(() => {
                    resizeVideo($video, api);
                });

                $wnd.on('blur focus', (e) => {
                    // don't pause the background video when clicked on iframe.
                    if (document.activeElement && document.activeElement.nodeName === 'IFRAME') {
                        return;
                    }
                    if (e.type === 'blur') {
                        api.pause();
                    } else {
                        api.play();
                    }
                });
            }
        }
    });
}

export { initBackgroundVideo };
