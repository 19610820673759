import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { LogSeverity } from 'utils/logging';
import { Deferred } from 'utils/misc/deferred';
import { inject, injectable } from 'inversify';
import { UtilsNamings } from 'utils/utils-namings';
import { InterceptorBase } from 'utils/http/interceptors/interceptor-base';

declare global {
	export interface IPnzRequestConfig {
		retryNumber?: number;
	}
}

const maxTimeout = 15000;

@injectable()
export class HttpReplayInterceptor extends InterceptorBase {
	constructor(@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService) {
		super();
	}

	public request: ((request: AxiosRequestConfig) => Promise<any> | AxiosRequestConfig) | null = null;

	public requestError: ((error: AxiosError) => Promise<any> | AxiosRequestConfig) | null = null;

	public response: ((response: AxiosResponse<IServerResponse>) => Promise<any>) | null = null;

	public responseError = (error: AxiosError): Promise<any> => {
		const response = error.response;
		if (!response) {
			return Promise.reject(error);
		}
		if (response.status === 500 || (response.status >= 400 && response.status < 500)) {
			return Promise.reject(error);
		}
		const config = <IPnzRequestConfig & AxiosRequestConfig>response.config;
		this.log(
			`response error handled for:${response.config ? response.config.url : 'n/a'}. retryCount:${
				config ? config.retryCount : 'n/a'
			}`
		);
		if (config && config.retryCount && config.retryCount > 0) {
			const copyConfig = Object.assign({}, config);
			copyConfig.retryCount!--;
			copyConfig.retryNumber = copyConfig.retryNumber == null ? 1 : copyConfig.retryNumber + 1;

			const timeout = Math.min(copyConfig.retryNumber * 1000, maxTimeout);

			const deferred = new Deferred();
			setTimeout(() => {
				this.log(
					`retry ${copyConfig.retryNumber} for ${response.config.method} ${response.config.url} ${JSON.stringify(
						response.config.data,
						null,
						2
					)}`
				);
				// const notification: INotificationState<IRetryData> = {
				//   type: 'retry',
				//   data: { retryNumber: copyConfig.retryNumber }
				// };
				// deferred.notify(notification);

				axios(copyConfig).then(
					success => {
						deferred.resolve(success);
					},
					(rejection: any) => {
						deferred.reject(rejection);
					} /*, (notification) => {
            deferred.notify(notification);
          }*/
				);
			}, timeout);
			return deferred.promise;
		}
		return Promise.reject(response);
	};

	private log(message: string, logSeverity: LogSeverity = LogSeverity.INFO): void {
		this.loggingService.log(`[HttpReplayInterceptor] ${message}`, logSeverity);
	}
}
