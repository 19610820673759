const DEFAULT_KEY = 'pnz-shared-mutations';

const MAX_MESSAGE_LENGTH = 4 * 1024;
let messageCounter = 1;

function splitMessage(message: any) {
	const partsCount = Math.ceil(message.length / MAX_MESSAGE_LENGTH);
	return Array.from({ length: partsCount }).map((_, idx) =>
		message.substr(idx * MAX_MESSAGE_LENGTH, MAX_MESSAGE_LENGTH)
	);
}

export class LocalStorageStrategy implements ISharedMutationStrategy {
	private uniqueId: string;
	private messageBuffer: any[];
	private options: { key: string };
	public static available(
		{ window: windowImpl, localStorage: localStorageImpl } = {
			window: window,
			localStorage: localStorage
		}
	) {
		if (!windowImpl || !localStorageImpl) {
			return false;
		}
		try {
			localStorageImpl.setItem('vuex-shared-mutations-test-key', Date.now().toString());
			localStorageImpl.removeItem('vuex-shared-mutations-test-key');
			return true;
		} catch (e) {
			return false;
		}
	}

	constructor(options = {}) {
		if (
			!LocalStorageStrategy.available({
				window: window,
				localStorage: localStorage
			})
		) {
			throw new Error('Strategy unavailable');
		}
		this.uniqueId = `${Date.now()}-${Math.random()}`;
		this.messageBuffer = [];
		this.options = {
			key: DEFAULT_KEY,
			...options
		};
	}

	// eslint-disable-next-line class-methods-use-this
	public addEventListener(fn: Function) {
		return window.addEventListener('storage', (event: StorageEvent) => {
			if (!event.newValue) {
				return false;
			}

			if (!event.key || event.key.indexOf('##') === -1 || event.key.split('##')[0] !== this.options.key) {
				return false;
			}
			const message = JSON.parse(event.newValue);
			/* istanbul ignore next: IE does not follow storage event spec */
			if (message.author === this.uniqueId) {
				return false;
			}
			this.messageBuffer.push(message.messagePart);
			if (this.messageBuffer.length === message.total) {
				const mutation = JSON.parse(this.messageBuffer.join(''));
				this.messageBuffer = [];
				fn(mutation);
			}
			return true;
		});
	}

	public share(message: any) {
		const rawMessage = JSON.stringify(message);
		const messageParts = splitMessage(rawMessage);
		messageParts.forEach((m, idx) => {
			messageCounter += 1;
			const key = `${this.options.key}##${idx}`;
			localStorage.setItem(
				key,
				JSON.stringify({
					author: this.uniqueId,
					part: idx,
					total: messageParts.length,
					messagePart: m,
					messageCounter
				})
			);
			localStorage.removeItem(key);
		});
	}
}
