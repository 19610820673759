import { Component, Prop } from 'vue-property-decorator';

import { FormContainer } from 'ui-controls-core/forms/form-container-base';
import { FormBase } from 'ui-controls-core/forms/form-base';
import { FormElementBase } from './form-element-base';

@Component
export class FieldElementBase<
	TFormContainer extends FormContainer<TForm, TRule, TModel>,
	TForm extends FormBase,
	TRule,
	TModel extends object = any
> extends FormElementBase<TFormContainer, TForm, TRule, TModel> {
	@Prop({ type: String })
	public fieldRef!: string;

	@Prop({ type: String, default: 'text' })
	public type!: string;

	@Prop({ type: Boolean })
	public autoFocus!: boolean;

	@Prop({ type: Number, default: 255 })
	public maxLength!: number;

	@Prop({ type: Boolean, default: false })
	public multiple!: boolean;

	@Prop({ type: Array, default: () => [] })
	public options!: [];

	@Prop({ type: Object, default: null })
	public inputOptions!: Object;
}
