import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { injectable, postConstruct } from 'inversify';

@injectable()
export abstract class InterceptorBase {
	private readonly requestRegistrations: number[] = [];
	private readonly responseRegistrations: number[] = [];

	public init(): InterceptorBase {
		if (this.request) {
			this.requestRegistrations.push(axios.interceptors.request.use(this.request));
		}
		if (this.requestError) {
			this.requestRegistrations.push(axios.interceptors.request.use(undefined, this.requestError));
		}
		if (this.response) {
			this.responseRegistrations.push(axios.interceptors.response.use(this.response));
		}
		if (this.responseError) {
			this.responseRegistrations.push(axios.interceptors.response.use(undefined, this.responseError));
		}
		return this;
	}

	public destroy(): void {
		this.requestRegistrations.forEach(_ => {
			axios.interceptors.request.eject(_);
		});
		this.responseRegistrations.forEach(_ => {
			axios.interceptors.response.eject(_);
		});
	}

	public abstract request: ((request: AxiosRequestConfig) => Promise<any> | AxiosRequestConfig) | null;
	public abstract requestError: ((error: AxiosError) => Promise<any> | AxiosRequestConfig) | null;
	public abstract response:
		| ((response: AxiosResponse<IServerResponse>) => Promise<any> | AxiosResponse<IServerResponse>)
		| null;
	public abstract responseError: ((error: AxiosError) => Promise<any>) | null;
}
