import { injectable } from 'inversify';

declare global {
	interface IDsMediaUrlService {
		getMediaLocalizedUrl(id: string, downloadable?: boolean): string;
	}
}

@injectable()
export class DsMediaUrlService implements IDsMediaUrlService {
	public getMediaLocalizedUrl(id: string, downloadable: boolean = false): string {
		if (downloadable) {
			return `/api/ds/download/${id}`;
		}
		return `/api/ds/media/${id}`;
	}
}
