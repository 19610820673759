import { container } from 'utils/ioc/container';
import { WsNamings } from '@/ws-namings';
import { WsMembershipActionInterceptor } from './ws-membership-action-interceptor';

export function configureIoC() {
	container
		.bind<IMembershipActionInterceptor>(WsNamings.wsMembershipActionInterceptorName)
		.to(WsMembershipActionInterceptor)
		.inSingletonScope();
}
