import { UtilsNamings } from 'utils/utils-namings';
import { container } from 'utils/ioc/container';

const LocalizeWbTextFilter: IFilter<ITextLocalizationResponse> = function(
	this: undefined,
	input: ITextLocalizationResponse
): string | null {
	const currentLocaleService = container.get<ICurrentLocaleService>(UtilsNamings.currentLocaleServiceName);
	const currentLocale = currentLocaleService.getCurrentLocaleId();
	return input.translations[currentLocale];
};

export { LocalizeWbTextFilter };
