import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './error-block.html';

import './error-block.scss';

@WithRender
@Component
export class ErrorBlock extends Vue {
	@Prop({ type: String })
	public error!: string;
}
