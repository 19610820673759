import { MembershipNamings } from 'membership/membership-namings';
import { container } from 'utils/ioc/container';
import { MembershipService } from 'membership/membership-service';

export function configureIoC() {
	container
		.bind<IMembershipService>(MembershipNamings.membershipServiceName)
		.to(MembershipService)
		.inSingletonScope();
}
