import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaAdSourceControllerService
		extends IRestServiceBase<
				{},
				IAdSourceResponse,
				IAdSourceFilterRequest,
				IAdSourceCreateRequest,
				IAdSourceUpdateRequest,
				IAdSourcePartialUpdateRequest
			> {}
}

@injectable()
export class GaAdSourceControllerService
	extends RestServiceBase<
		{},
		IAdSourceResponse,
		IAdSourceFilterRequest,
		IAdSourceCreateRequest,
		IAdSourceUpdateRequest,
		IAdSourcePartialUpdateRequest
	>
	implements IGaAdSourceControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/ad-source', $http, $q, apiConfigProvider);
	}
}
