import { injectable } from 'inversify';

@injectable()
export class FormattingService implements IFormattingService {
	public formatOrdered(text: string, values: any[]): string {
		if (!values) {
			return text;
		}
		for (let i = 0; i < values.length; i++) {
			const regex = new RegExp('\\{' + i + '\\}', 'g');
			text = text.replace(regex, values[i]);
		}
		return text;
	}

	public formatKeyed(text: string, values: { [key: string]: any }): string {
		if (!values) {
			return text;
		}
		Object.keys(values).forEach((key: string) => {
			const regex = new RegExp('\\{' + key + '\\}', 'gi');
			text = text.replace(regex, values[key]);
		});
		return text;
	}
}
