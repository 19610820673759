import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';
import WithRender from './app.html';

import './app.scss';

@WithRender
@Component({
	name: 'PnzTowerWebsiteApp'
})
export class App extends UIComponent {
	constructor() {
		super();
	}
}
