import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IWsNavigateControllerService {
		navigate(request: INavigateRequest): IResponsePromise<INavigateResponse>;
	}
}

@injectable()
export class WsNavigateControllerService extends HttpServiceBase implements IWsNavigateControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public navigate(request: INavigateRequest): IResponsePromise<INavigateResponse> {
		const path = '/navigate/';
		const config = this.getMethodConfig('navigate');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<INavigateResponse>>(url, request, config.requestConfig);
	}
}
