import { injectable } from 'inversify';
import { TimeoutService } from './timeout-service';

declare global {
	interface IIntervalService {
		$interval(func: Function, interval?: number): Function;
		$cancel(func: Function): void;
	}
}

@injectable()
export class IntervalService implements IIntervalService {
	private static promiseIdFiledName: string = '_intervalId';
	private timers: IMap<NodeJS.Timer> = {};
	public $interval(func: Function, interval?: number): Function {
		const timer = setInterval(() => {
			func();
		}, interval || 0);
		const id = Math.random();
		this.timers[id] = timer;
		(<any>func)[IntervalService.promiseIdFiledName] = id;
		return func;
	}

	public $cancel(func: Function): void {
		const id: number = (<any>func)[IntervalService.promiseIdFiledName];
		const timer = this.timers[id];
		if (timer) {
			clearInterval(timer);
		}
	}
}
