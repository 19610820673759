import { CallInfo, InversifyTracer, ReturnInfo } from 'inversify-tracer';
import { container } from 'utils/ioc/container';

const tracer = new InversifyTracer();

tracer.on('call', (callInfo: CallInfo) => {
	try {
		// tslint:disable-next-line:no-console
		console.log(
			`${callInfo.className} ${callInfo.methodName} called with ${JSON.stringify(callInfo.parameters, null, 2)}`
		);
	} catch {}
});

tracer.on('return', (returnInfo: ReturnInfo) => {
	try {
		// tslint:disable-next-line:no-console
		console.log(
			`${returnInfo.className} ${returnInfo.methodName} returned ${returnInfo.result} - ${returnInfo.executionTime}ms`
		);
	} catch {}
});

tracer.apply(container);
