import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface ILsGameLoginControllerService {
		getGameLoginState(request: IGameLoginStateRequest): IResponsePromise<IPanzarGameLoginStateData>;

		login(request: IGameLoginRequest): IResponsePromise<IGameLoginData>;

		logout(request: IGameLogoutRequest): IResponsePromise<void>;
	}
}

@injectable()
export class LsGameLoginControllerService extends HttpServiceBase implements ILsGameLoginControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public getGameLoginState(request: IGameLoginStateRequest): IResponsePromise<IPanzarGameLoginStateData> {
		const path = '/api/ls/gamelogin/getgamestate/';
		const config = this.getMethodConfig('getGameLoginState');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IPanzarGameLoginStateData>>(url, request, config.requestConfig);
	}

	public login(request: IGameLoginRequest): IResponsePromise<IGameLoginData> {
		const path = '/api/ls/gamelogin/login/';
		const config = this.getMethodConfig('login');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IGameLoginData>>(url, request, config.requestConfig);
	}

	public logout(request: IGameLogoutRequest): IResponsePromise<void> {
		const path = '/api/ls/gamelogin/logout/';
		const config = this.getMethodConfig('logout');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}
}
