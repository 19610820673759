import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { injectable } from 'inversify';

import regions from '#/regions.json';

declare global {
	interface IStaticService {
		regionExists(name: string): boolean;
		getRegion(name: string): IRegionData;
		getRegions(): IRegionData[];
		getLocales(): ILocale[];
	}
}

@injectable()
export class StaticService implements IStaticService {
	private regionData: IRegionData[];
	constructor() {
		this.regionData = map(regions.regions, (region: IRegion) => {
			return {
				id: region.id,
				name: region.name,
				defaultLocale: find(regions.locales, __ => __.id === region.defaultLocale),
				locales: filter(regions.locales, (locale: ILocale) => {
					return includes(region.locales, locale.id);
				})
			} as IRegionData;
		});
	}

	public regionExists(name: string): boolean {
		return find(regions.regions, __ => __.name === name) != null;
	}

	public getRegion(name: string): IRegionData {
		const region = find(this.regionData, __ => __.name === name);
		if (!region) {
			throw new Error(`region with name:'${name}' not found`);
		}
		return region;
	}

	public getRegions(): IRegionData[] {
		return this.regionData;
	}

	public getLocales(): ILocale[] {
		return regions.locales;
	}
}
