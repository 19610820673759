import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaWbCategoryControllerService
		extends IRestServiceBase<
				{},
				IWbCategoryResponse,
				IWbCategoryFilterRequest,
				IWbCategoryCreateRequest,
				IWbCategoryUpdateRequest,
				IWbCategoryPartialUpdateRequest
			> {}
}

@injectable()
export class GaWbCategoryControllerService
	extends RestServiceBase<
		{},
		IWbCategoryResponse,
		IWbCategoryFilterRequest,
		IWbCategoryCreateRequest,
		IWbCategoryUpdateRequest,
		IWbCategoryPartialUpdateRequest
	>
	implements IGaWbCategoryControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/wb-category', $http, $q, apiConfigProvider);
	}
}
