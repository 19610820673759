export const DefaultHttpErrorMessage =
	'A network error occurred. Try to disable proxy or antivirus, clear cache, reload the page and try again.';
export const clientVersion = 1;
export const ClientVersionHeaderName = '-x-client-version';
export const AntiForgeryTokenHeaderName = '-x-xsrf-token';
export const HttpServerHeaderName = '-x-server-version';
export const XTokenHeaderName = '-x-token';
export const XTokenExpirationHeaderName = '-x-token-expiration';
export const ClientTimeZoneOffset = '-x-timezone-offset';
export const CorrelationIdHeaderName = '-x-correlation-id';
