import { configureIoC } from './configure-ioc';
import { UIControlsModule } from 'ui-controls/ui-controls-module';
import { MembershipNamings } from 'membership/membership-namings';
import { UIControlsNamings } from 'ui-controls/ui-controls-namings';
import { UtilsNamings } from 'utils/utils-namings';
import { ServerApiNamings } from 'server-api/server-api-namings';
import { UtilsModule } from 'utils/utils-module';
import { ServerApi } from 'server-api/server-api-module';

export class MembershipModule implements IModule {
	public moduleName = MembershipNamings.moduleName;

	public registerSubModules(context: IAppContext): Promise<void> {
		context.moduleContainer.register(UtilsNamings.moduleName, UtilsModule);
		context.moduleContainer.register(ServerApiNamings.moduleName, ServerApi);
		context.moduleContainer.register(UIControlsNamings.moduleName, UIControlsModule);
		return Promise.resolve();
	}
	public configureIoC(context: IAppContext): Promise<void> {
		configureIoC();
		return Promise.resolve();
	}
	public configureRoutes(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public registerUIComponents(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public configureModule(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public beforeRun(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public afterRun(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
}
