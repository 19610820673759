import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';
import WithRender from './empty.html';

import './empty.scss';

@WithRender
@Component
export class Empty extends UIComponent {
	constructor() {
		super();
	}
}
