import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	//interface IPlayerPartialUpdateRequest extends IPlayerRequestBase {}

	interface IGaPlayerControllerService
		extends IRestServiceBase<
				{},
				IPlayerResponse,
				IPlayerFilterRequest,
				IPlayerCreateRequest,
				IPlayerUpdateRequest,
				IPlayerPartialUpdateRequest
			> {
		createCrystalPackage(
			id: string,
			data: IPlayerCrystalPackageCreateRequest,
			cancelToken?: Promise<void>
		): Promise<IResponsePayload<void>>;
	}
}

@injectable()
export class GaPlayerControllerService
	extends RestServiceBase<
		{},
		IPlayerResponse,
		IPlayerFilterRequest,
		IPlayerCreateRequest,
		IPlayerUpdateRequest,
		IPlayerPartialUpdateRequest
	>
	implements IGaPlayerControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/player', $http, $q, apiConfigProvider);
	}

	public createCrystalPackage(
		id: string,
		data: IPlayerCrystalPackageCreateRequest,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `${id}/crystal-package`, data, {}, cancelToken);
	}
}
