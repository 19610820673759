import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface ILsAccountControllerService {
		getAvailableBanTypes(): IResponsePromise<number[]>;

		getRegistrationConfiguration(): IResponsePromise<IRegistrationConfiguration>;

		getSecretQuestion(request: ISecretQuestionRequest): IResponsePromise<ISecretQuestionData>;

		login(request: ILoginRequest): IResponsePromise<ILoginSuccessData>;

		loginExternal(request: ILoginExternalRequest): IResponsePromise<ILoginExternalResponse>;

		logout(request: ILogoutRequest): IResponsePromise<void>;

		recoverPassword(request: IRecoverPasswordRequest): IResponsePromise<void>;

		recoverPasswordByCode(request: IVerificationCodeRequest): IResponsePromise<IRecoverPasswordData>;

		register(request: IRegistrationRequest): IResponsePromise<void>;

		registerShort(request: IShortRegistrationRequest): IResponsePromise<ILoginSuccessData>;

		secretQuestionPasswordRecovery(request: ISecretQuestionAnswerRequest): IResponsePromise<IRecoverPasswordData>;

		setPassword(request: ISetPasswordRequest): IResponsePromise<void>;

		changePassword(request: IChangePasswordRequest): IResponsePromise<void>;
	}
}

@injectable()
export class LsAccountControllerService extends HttpServiceBase implements ILsAccountControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public getAvailableBanTypes(): IResponsePromise<number[]> {
		const path = '/api/ls/account/getavailablebantypes/';
		const config = this.getMethodConfig('getAvailableBanTypes');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<number[]>>(url, {}, config.requestConfig);
	}

	public getRegistrationConfiguration(): IResponsePromise<IRegistrationConfiguration> {
		const path = '/api/ls/account/getregistrationconfiguration/';
		const config = this.getMethodConfig('getRegistrationConfiguration');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IRegistrationConfiguration>>(url, {}, config.requestConfig);
	}

	public getSecretQuestion(request: ISecretQuestionRequest): IResponsePromise<ISecretQuestionData> {
		const path = '/api/ls/account/getsecretquestion/';
		const config = this.getMethodConfig('getSecretQuestion');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<ISecretQuestionData>>(url, request, config.requestConfig);
	}

	public login(request: ILoginRequest): IResponsePromise<ILoginSuccessData> {
		const path = '/api/ls/account/login/';
		const config = this.getMethodConfig('login');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<ILoginSuccessData>>(url, request, config.requestConfig);
	}

	public loginExternal(request: ILoginExternalRequest): IResponsePromise<ILoginExternalResponse> {
		const path = '/api/ls/account/loginExternal/';
		const config = this.getMethodConfig('login');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<ILoginExternalResponse>>(url, request, config.requestConfig);
	}

	public logout(request: ILogoutRequest): IResponsePromise<void> {
		const path = '/api/ls/account/logout/';
		const config = this.getMethodConfig('logout');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}

	public recoverPassword(request: IRecoverPasswordRequest): IResponsePromise<void> {
		const path = '/api/ls/account/recoverpassword/';
		const config = this.getMethodConfig('recoverPassword');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}

	public recoverPasswordByCode(request: IVerificationCodeRequest): IResponsePromise<IRecoverPasswordData> {
		const path = '/api/ls/account/recoverpasswordbycode/';
		const config = this.getMethodConfig('recoverPasswordByCode');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IRecoverPasswordData>>(url, request, config.requestConfig);
	}

	public register(request: IRegistrationRequest): IResponsePromise<void> {
		const path = '/api/ls/account/register/';
		const config = this.getMethodConfig('register');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}

	public registerShort(request: IShortRegistrationRequest): IResponsePromise<ILoginSuccessData> {
		const path = '/api/ls/account/registershort/';
		const config = this.getMethodConfig('registerShort');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<ILoginSuccessData>>(url, request, config.requestConfig);
	}

	public secretQuestionPasswordRecovery(request: ISecretQuestionAnswerRequest): IResponsePromise<IRecoverPasswordData> {
		const path = '/api/ls/account/secretquestionpasswordrecovery/';
		const config = this.getMethodConfig('secretQuestionPasswordRecovery');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IRecoverPasswordData>>(url, request, config.requestConfig);
	}

	public setPassword(request: ISetPasswordRequest): IResponsePromise<void> {
		const path = '/api/ls/account/setpassword/';
		const config = this.getMethodConfig('setPassword');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}

	public changePassword(request: IChangePasswordRequest): IResponsePromise<void> {
		const path = '/api/ls/account/changepassword/';
		const config = this.getMethodConfig('changePassword');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}
}
