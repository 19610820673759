import { PasswordRecoveryRouteNames } from './password-recovery-route-namings';
import { LoginRouteNames } from 'membership/auth/login/routes/login-routes-namings';

const EnterVerificationCodeForm = () =>
	import(
		/* webpackChunkName: "password-recovery" */ 'membership/auth/password-recovery/views/enter-verification-code-form/enter-verification-code-form'
	).then((_) => _.EnterVerificationCodeForm);
const PasswordRecoverySelector = () =>
	import(
		/* webpackChunkName: "password-recovery" */ 'membership/auth/password-recovery/views/password-recovery-selector/password-recovery-selector'
	).then((_) => _.PasswordRecoverySelector);
const EmailPasswordRecoveryForm = () =>
	import(
		/* webpackChunkName: "password-recovery" */ 'membership/auth/password-recovery/views/email-password-recovery-form/email-password-recovery-form'
	).then((_) => _.EmailPasswordRecoveryForm);
const SecretQuestionPasswordRecoveryForm = () =>
	import(
		/* webpackChunkName: "password-recovery" */ 'membership/auth/password-recovery/views/secret-question-password-recovery-form/secret-question-password-recovery-form'
	).then((_) => _.SecretQuestionPasswordRecoveryForm);
const SecretQuestionPasswordRecoveryAnswerForm = () =>
	import(
		/* webpackChunkName: "password-recovery" */ 'membership/auth/password-recovery/views/secret-question-password-recovery-answer-form/secret-question-password-recovery-answer-form'
	).then((_) => _.SecretQuestionPasswordRecoveryAnswerForm);
const SetNewPassword = () =>
	import(
		/* webpackChunkName: "password-recovery" */ 'membership/auth/password-recovery/views/set-new-password-form/set-new-password-form'
	).then((_) => _.SetNewPasswordForm);

declare global {
	interface IVerificationCodeStateParams {
		email: string;
	}

	interface ISecretQuestionPasswordRecoveryAnswerStateParams {
		email: string;
		secretQuestion: string;
		recoveryToken: string;
	}

	interface ISetPasswordStateParams {
		token: string;
		preserveGameSession?: boolean;
	}
}

const PasswordRecoveryRoutes: IWizardRouteConfig[] = [
	{
		path: PasswordRecoveryRouteNames.SelectPasswordRecovery,
		name: PasswordRecoveryRouteNames.SelectPasswordRecovery,
		component: PasswordRecoverySelector,
		meta: {
			title: 'passwordRecovery' as const,
			back: LoginRouteNames.Login
		}
	},
	{
		path: PasswordRecoveryRouteNames.EmailPasswordRecovery,
		name: PasswordRecoveryRouteNames.EmailPasswordRecovery,
		component: EmailPasswordRecoveryForm,
		meta: {
			title: 'passwordRecovery' as const,
			back: PasswordRecoveryRouteNames.SelectPasswordRecovery
		}
	},
	{
		path: PasswordRecoveryRouteNames.EnterVerificationCode,
		name: PasswordRecoveryRouteNames.EnterVerificationCode,
		component: EnterVerificationCodeForm,
		meta: {
			title: 'passwordRecovery' as const,
			back: PasswordRecoveryRouteNames.SelectPasswordRecovery
		},
		beforeEnter: (to, from, next) => {
			const params = (to.params as any) as IVerificationCodeStateParams;
			if (!params || !params.email) {
				next(PasswordRecoveryRouteNames.SelectPasswordRecovery);
			} else {
				next();
			}
		}
	},
	{
		path: PasswordRecoveryRouteNames.SecretQuestionPasswordRecovery,
		name: PasswordRecoveryRouteNames.SecretQuestionPasswordRecovery,
		component: SecretQuestionPasswordRecoveryForm,
		meta: {
			title: 'passwordRecovery' as const,
			back: PasswordRecoveryRouteNames.SelectPasswordRecovery
		}
	},
	{
		path: PasswordRecoveryRouteNames.SecretQuestionPasswordRecoveryAnswer,
		name: PasswordRecoveryRouteNames.SecretQuestionPasswordRecoveryAnswer,
		component: SecretQuestionPasswordRecoveryAnswerForm,
		meta: {
			title: 'passwordRecovery' as const,
			back: PasswordRecoveryRouteNames.SelectPasswordRecovery
		}
	},
	{
		path: PasswordRecoveryRouteNames.SetNewPassword,
		name: PasswordRecoveryRouteNames.SetNewPassword,
		component: SetNewPassword,
		meta: {
			title: 'enterPassword' as const,
			back: PasswordRecoveryRouteNames.SelectPasswordRecovery
		},
		beforeEnter: (to, from, next) => {
			const params = (to.params as any) as ISetPasswordStateParams;
			if (!params || !params.token) {
				next(PasswordRecoveryRouteNames.SelectPasswordRecovery);
			} else {
				next();
			}
		}
	}
];

export { PasswordRecoveryRoutes };
