import { getCurrentTimezoneOffset } from './common/utils';

declare global {
	interface IClientConfig {
		language?: string;
		serverTimeZoneOffset: number;
		defaultRegion: string;
		headerPrefix: string;
		moduleConfigurations: { [key: string]: any } | null;
	}

	// tslint:disable-next-line:interface-name
	interface Window {
		CLIENT_CONFIG: IClientConfig;
	}

	const STATIC_CLIENT_CONFIG: IClientConfig;
}

const clientConfig = window.CLIENT_CONFIG || STATIC_CLIENT_CONFIG;

Object.assign(clientConfig, { clientTimeStamp: new Date() });

export { clientConfig };
