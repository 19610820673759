import { Deferred } from 'utils/misc/deferred';
import { injectable } from 'inversify';

declare global {
	interface IQService {
		defer<T>(): Deferred<T>;
	}
}

@injectable()
export class QService implements IQService {
	public defer<T>(): Deferred<T> {
		return new Deferred();
	}
}
