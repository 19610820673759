import { injectable, inject } from 'inversify';
import Router from 'vue-router';
import { UtilsNamings } from '../utils-namings';

declare global {
	interface IStateService {
		go(name: string, params?: any): void;

		transitionTo(name: string): void;
	}
}

@injectable()
export class StateService implements IStateService {
	constructor(@inject(UtilsNamings.appRouterName) private router: Router) {}
	public transitionTo(name: string): void {
		this.router.push({ name: name });
	}
	public go(name: string, params?: any): void {
		this.router.push({ name: name, params: params });
	}
}
