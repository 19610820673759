import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	export interface ILocalStorageService {
		isSupported: boolean;
		getVersion(): number;
		setVersion(version: number): void;
		set<T>(key: string, value: T): void;
		get<T>(key: string): T | null;
		keys(): string[];
		remove(key: string): void;
		clearAll(regularExpression?: RegExp): void;
		length(): number;
	}
}

@injectable()
export class LocalStorageService implements ILocalStorageService {
	private versionKey = 'storage_version';
	private prefix: string = 'pnz';
	public isSupported: boolean;

	constructor(@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService) {
		this.isSupported = localStorage.isSupported;
	}


	public getVersion(): number {
		return this.get<number>(this.versionKey) || 0;
	}

	public setVersion(version: number): void {
		return this.set<number>(this.versionKey, version);
	}

	public set<T>(key: string, value: T): void {
		const prefixedKey = this.getPrefixedKey(key);
		const item = value === undefined ? '' : JSON.stringify(value);
		localStorage.setItem(prefixedKey, item);
	}

	public get<T>(key: string): T | null {
		const prefixedKey = this.getPrefixedKey(key);
		const item = localStorage.getItem(prefixedKey);
		if (item === null) {
			return null;
		}
		try {
			return JSON.parse(item);
		} catch (e) {
			this.remove(key);
			throw e;
		}
	}

	public keys(): string[] {
		const keys: string[] = new Array(localStorage.length);
		for (let i = 0; i < localStorage.length; i++) {
			let key: string | null = localStorage.key(i);
			if (key != null) {
				if (this.prefix && key.indexOf(this.prefix) === 0) {
					key = key.slice(this.prefix.length + 1);
				}
				keys[i] = key;
			}
		}
		return keys;
	}

	public remove(key: string): void {
		const prefixedKey = this.getPrefixedKey(key);
		localStorage.removeItem(prefixedKey);
	}

	public clearAll(regularExpression?: RegExp): void {
		if (regularExpression == null) {
			localStorage.clear();
		} else {
			const keys = this.keys();
			for (const key of keys) {
				if (regularExpression.test(key)) {
					localStorage.remove(key);
				}
			}
		}
	}

	public length(): number {
		return localStorage.length;
	}

	private getPrefixedKey(key: string): string {
		if (this.prefix) {
			return `${this.prefix}.${key}`;
		} else {
			return key;
		}
	}
}
