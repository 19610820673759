import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';
import WithRender from './svg-icon.html';

import './svg-icon.scss';

@WithRender
@Component
export class SvgIcon extends UIComponent {
	@Prop({ required: true })
	public icon!: string;

	@Prop({ default: '' })
	public size!: string;

	get svgClass() {
		let classString = 'svg-icon fa';
		if (this.icon) {
			classString += ' fa-' + this.icon;
		}
		if (this.size) {
			classString += ' fa-' + this.size;
		}
		return classString;
	}
}
