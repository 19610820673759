import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IGaAccountControllerService {
		logout(): IResponsePromise<void>;
	}
}

@injectable()
export class GaAccountControllerService extends HttpServiceBase implements IGaAccountControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public logout(): IResponsePromise<void> {
		const path = '/internal/ga/account/logout/';
		const config = this.getMethodConfig('logout');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, {}, config.requestConfig);
	}
}
