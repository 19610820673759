import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';

declare global {
	interface IUserSettings {
		dateTimeFormat: DateTimeFormat;
		setDateTimeFormat(dateTimeFormat: DateTimeFormat): void;
	}
}

export enum DateTimeFormat {
	Local = 'local',
	Server = 'server',
	Utc = 'utc'
}

export const UserSettingsModuleName = 'user-settings';

@Module({ namespaced: true, name: UserSettingsModuleName })
class UserSettingsModule extends VuexModule implements IUserSettings {
	public dateTimeFormat: DateTimeFormat = DateTimeFormat.Local;

	@MutationAction
	public async setDateTimeFormat(dateTimeFormat: DateTimeFormat) {
		return {
			dateTimeFormat: dateTimeFormat
		};
	}

	constructor(arg: any) {
		super(arg);
	}
}

export { UserSettingsModule };
