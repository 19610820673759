import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaWbMediaLocalizationControllerService
		extends IRestServiceBase<
				{},
				IWbMediaLocalizationResponse,
				IWbMediaLocalizationFilterRequest,
				IWbMediaLocalizationCreateRequest,
				IWbMediaLocalizationModificationRequest,
				IWbMediaLocalizationModificationRequest
			> {}
}

@injectable()
export class GaWbMediaLocalizationControllerService
	extends RestServiceBase<
		{},
		IWbMediaLocalizationResponse,
		IWbMediaLocalizationFilterRequest,
		IWbMediaLocalizationCreateRequest,
		IWbMediaLocalizationModificationRequest,
		IWbMediaLocalizationModificationRequest
	>
	implements IGaWbMediaLocalizationControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/wb-media-localization', $http, $q, apiConfigProvider);
	}
}
