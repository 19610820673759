import find from 'lodash/find';
import { container } from 'utils/ioc/container';
import { InterceptorBase } from '../http/interceptors/interceptor-base';

declare global {
	interface IAppContext {
		readonly moduleContainer: IModuleContainer;
	}

	interface IModuleMethods {
		registerSubModules(context: IAppContext): Promise<void>;
		configureIoC(context: IAppContext): Promise<void>;
		configureRoutes(context: IAppContext): Promise<void>;
		registerUIComponents(context: IAppContext): Promise<void>;
		configureModule(context: IAppContext): Promise<void>;
		beforeRun(context: IAppContext): Promise<void>;
		afterRun(context: IAppContext): Promise<void>;
	}

	interface IModule extends IModuleMethods {
		readonly moduleName: string;
	}

	type IModuleInitializer = new () => IModule;

	interface IModuleContainer {
		register(name: string, module: IModuleInitializer): void;
		flush(): void;
	}
}

export abstract class Application implements IModule {
	public abstract moduleName: string;
	protected moduleContainer: ModuleContainer;
	protected abstract context: IAppContext;
	private destroyers: Function[] = [];

	constructor() {
		this.moduleContainer = new ModuleContainer();
	}

	public abstract registerSubModules(context: IAppContext): Promise<void>;
	public abstract configureIoC(context: IAppContext): Promise<void>;
	public abstract configureRoutes(context: IAppContext): Promise<void>;
	public abstract registerUIComponents(context: IAppContext): Promise<void>;
	public abstract configureModule(context: IAppContext): Promise<void>;
	public abstract beforeRun(context: IAppContext): Promise<void>;
	public abstract afterRun(context: IAppContext): Promise<void>;
	protected abstract beforeStop(): void;

	public configure(): Promise<void> {
		return this.registerSubModulesInternal(this.context)
			.then(() => {
				return this.configureIoCInternal(this.context);
			})
			.then(() => {
				return this.configureRoutesInternal(this.context);
			})
			.then(() => {
				return this.registerUIComponentsInternal(this.context);
			})
			.then(() => {
				return this.configureModuleInternal(this.context);
			});
	}

	public run(): Promise<void> {
		return this.beforeRunInternal(this.context).then(() => {
			return this.afterRunInternal(this.context);
		});
	}

	public stop(): void {
		this.beforeStop();
		this.moduleContainer.flush();
		this.destroyers.forEach(_ => _());
	}

	private registerSubModulesInternal(context: IAppContext): Promise<void> {
		return this.registerSubModules(context).then(() => {
			return this.moduleContainer.registerSubModules(context);
		});
	}

	private registerUIComponentsInternal(context: IAppContext): Promise<void> {
		return this.registerUIComponents(context).then(() => {
			return this.moduleContainer.registerUIComponents(context);
		});
	}
	private configureIoCInternal(context: IAppContext): Promise<void> {
		return this.moduleContainer.configureIoC(context).then(() => {
			this.configureIoC(context);
		});
		// return this.configureIoC(context).then(() => {
		// 	return this.moduleContainer.configureIoC(context);
		// });
	}
	private configureRoutesInternal(context: IAppContext): Promise<void> {
		return this.configureRoutes(context).then(() => {
			return this.moduleContainer.configureRoutes(context);
		});
	}

	protected abstract getInterceptors(context: IAppContext): Array<new (...args: any[]) => InterceptorBase>;

	private configureModuleInternal(context: IAppContext): Promise<void> {
		return this.configureModule(context).then(() => {
			return this.moduleContainer.configureModule(context);
		});
	}
	private beforeRunInternal(context: IAppContext): Promise<void> {
		const interceptorConstructors: Array<new (...args: any[]) => InterceptorBase> = this.getInterceptors(context);
		interceptorConstructors.forEach(_ => {
			const interceptor = container.resolve<InterceptorBase>(_);
			interceptor.init();
			this.destroyers.push(() => {
				interceptor.destroy();
			});
		});

		return this.beforeRun(context).then(() => {
			return this.moduleContainer.beforeRun(context);
		});
	}

	private afterRunInternal(context: IAppContext): Promise<void> {
		return this.afterRun(context).then(() => {
			return this.moduleContainer.afterRun(context);
		});
	}

	protected registerDestroyHandler(fn: Function) {
		this.destroyers.push(fn);
	}
}

export class ModuleContainer implements IModuleMethods, IModuleContainer {
	private static modules: IModule[] = [];

	public register(name: string, moduleInitializer: IModuleInitializer) {
		if (find(ModuleContainer.modules, __ => __.moduleName === name)) {
			return;
		}
		const module = new moduleInitializer();
		ModuleContainer.modules.push(module);
	}

	public flush(): void {
		ModuleContainer.modules = [];
	}

	public registerSubModules(context: IAppContext): Promise<void> {
		return this.execute('registerSubModules', context);
	}

	public configureRoutes(context: IAppContext): Promise<void> {
		return this.execute('configureRoutes', context);
	}

	public registerUIComponents(context: IAppContext): Promise<void> {
		return this.execute('registerUIComponents', context);
	}

	public configureIoC(context: IAppContext): Promise<void> {
		return this.execute('configureIoC', context);
	}

	public configureModule(context: IAppContext): Promise<void> {
		return this.execute('configureModule', context);
	}

	public beforeRun(context: IAppContext): Promise<void> {
		return this.execute('beforeRun', context);
	}

	public afterRun(context: IAppContext): Promise<void> {
		return this.execute('afterRun', context);
	}

	private execute(func: keyof IModuleMethods, context: IAppContext): Promise<void> {
		return <any>Promise.all(
			ModuleContainer.modules.map(module => {
				this.trace(`calling ${func} on module ${module.moduleName}`);
				const method = module[func];
				return method
					.call(module, context)
					.then(() => {
						this.trace(`finished ${func} on module ${module.moduleName}`);
					})
					.catch((error: any) => {
						this.trace(`failed ${func} on module ${module.moduleName}. error:'${error}'`);
						throw error;
					});
			})
		);
	}

	private trace(message: string) {
		//tslint:disable-next-line:no-console
		console.log('[ModuleContainer] ' + message);
	}
}
