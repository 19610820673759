import Vue from 'vue';

export const NullAny = null as any;
export const EmptyStringAny = '' as any;

export const nameof = <T>(name: keyof T) => name;

export function camelize(str: string) {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
			return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
		})
		.replace(/\s+/g, '');
}

export function findChildCmpByNameRecursive<T extends Vue>(container: Vue, componentName: string): T {
	let element: T | null = <T>container.$children.find((child: Vue) => {
		return child.$options.name === componentName;
	});
	if (element == null) {
		for (const child of container.$children) {
			element = findChildCmpByNameRecursive<T>(child, componentName);
			if (element != null) {
				return element;
			}
		}
	} else {
		return element;
	}
	throw new Error(`component ${componentName} not found`);
}

export function findAllChildCmpByNameRecursive<T extends Vue>(container: Vue, componentName: string): T[] {
	const elements: T[] | null = <T[]>container.$children.filter((child: Vue) => {
		return child.$options.name === componentName;
	});
	for (const element of container.$children) {
		const childElements = findAllChildCmpByNameRecursive<T>(element, componentName);
		if (childElements != null) {
			elements.push(...childElements);
		}
	}
	return elements;
}

export function findParentCmpByNameRecursive<T extends Vue>(container: Vue, componentName: string): T {
	if (container.$parent != null) {
		if (container.$parent.$options.name === componentName) {
			return container.$parent as T;
		} else {
			return findParentCmpByNameRecursive<T>(container.$parent, componentName);
		}
	}
	throw new Error(`component ${componentName} not found`);
}

export function findNodeUpperByClasses(element: Element, classes: string[]): Element | null {
	const parentNode = element.parentNode as Element;
	if (parentNode) {
		const classList = parentNode.classList;
		if (classList && classes.every(className => classList.contains(className))) {
			return parentNode;
		} else {
			return findNodeUpperByClasses(parentNode, classes);
		}
	}
	return null;
}

export const getCurrentTimezoneOffset = (): number => {
	return -(new Date().getTimezoneOffset() / 60);
};

export class Uid {
	public static EMPTY = '00000000-0000-0000-0000-000000000000';
	public static create(bytes = 2): Uid {
		return new Uid([Uid.gen(bytes)].join('-'));
	}

	private static gen(count: number) {
		let out: string = '';
		for (let i: number = 0; i < count; i++) {
			// tslint:disable-next-line:no-bitwise
			out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		}
		return out;
	}

	private constructor(private value: string) {}

	public toString(): string {
		return this.value;
	}
}

export function getPropByPath(obj: any, path: string) {
	let tempObj = obj;
	path = path.replace(/\[(\w+)\]/g, '.$1');
	path = path.replace(/^\./, '');

	const keys = path.split('.');
	let i = 0;

	for (const len = keys.length; i < len - 1; ++i) {
		const key = keys[i];
		if (key in tempObj) {
			tempObj = tempObj[key];
		} else {
			throw new Error('Invalid prop path to form item');
		}
	}
	return tempObj[keys[i]];
}

export function setPropByPath(obj: any, path: string, value: any): void {
	let tempObj = obj;
	path = path.replace(/\[(\w+)\]/g, '.$1');
	path = path.replace(/^\./, '');

	const keys = path.split('.');
	let i = 0;
	for (const len = keys.length; i < len; ++i) {
		const key = keys[i];
		if (i === len - 1) {
			Vue.set(tempObj, key, value);
		} else {
			tempObj = tempObj[key];
		}
	}
}

export function evenRound(num: number, decimalPlaces: number) {
	const d = decimalPlaces || 0;
	const m = Math.pow(10, d);
	const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
	const i = Math.floor(n);
	const f = n - i;
	const e = 1e-8; // Allow for rounding errors in f
	const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
	return d ? r / m : r;
}
