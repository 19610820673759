import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaAdPixelControllerService
		extends IRestServiceBase<
				{},
				IAdPixelResponse,
				IAdPixelFilterRequest,
				IAdPixelCreateRequest,
				IAdPixelUpdateRequest,
				IAdPixelPartialUpdateRequest
			> {}
}

@injectable()
export class GaAdPixelControllerService
	extends RestServiceBase<
		{},
		IAdPixelResponse,
		IAdPixelFilterRequest,
		IAdPixelCreateRequest,
		IAdPixelUpdateRequest,
		IAdPixelPartialUpdateRequest
	>
	implements IGaAdPixelControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/ad-pixel', $http, $q, apiConfigProvider);
	}
}
