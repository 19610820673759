import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';

declare global {
	type HostScreen = 'desktop' | 'mobile';

	export interface IHostState {
		screen: HostScreen;
	}
}

export const HostModuleName = 'host';

@Module({ namespaced: true, name: HostModuleName })
export class HostModule extends VuexModule implements IHostState {
	public screen: HostScreen = 'desktop';

	@MutationAction
	public async toggleDevice(screen: HostScreen) {
		return {
			screen: screen
		};
	}
}
