import { injectable } from 'inversify';

class MethodApiConfig implements IMethodApiConfig, IMethodApiConfigProvider {
	public transform!: (url: string, request: any) => string;
	public readonly requestConfig: IPnzRequestConfig = {};

	public getMethodUrl(url: string, request: any): string {
		return this.transform ? this.transform(url, request) : url;
	}

	public setMethodUrlTransform(transform: (url: string, request: any) => string): void {
		this.transform = transform;
	}
}

class ControllerApiConfig<T extends HttpServiceBase> implements IControllerApiConfig<T> {
	private methodConfigurations: {
		[key: string]: IMethodApiConfigProvider;
	} = {};
	public readonly requestConfig: IPnzRequestConfig = {};

	public getMethodConfig(method: string): IMethodApiConfig {
		return this.getMethodConfigOrCreate(method);
	}

	public configureMethod(
		method: string,
		configProvider: (methodConfig: IMethodApiConfigProvider) => void
	): IControllerApiConfig<T> {
		const methodConfiguration = this.getMethodConfigOrCreate(method);
		configProvider(methodConfiguration);
		return this;
	}

	private getMethodConfigOrCreate(method: string): IMethodApiConfigProvider {
		let methodConfiguration = this.methodConfigurations[method];
		if (!methodConfiguration) {
			methodConfiguration = this.methodConfigurations[method] = new MethodApiConfig();
		}
		return methodConfiguration;
	}
}

@injectable()
export class ApiConfigProvider implements IApiConfigProvider {
	private static configurations: { [key: string]: any } = {};

	public getControllerConfig<T extends IHttpServiceBase>(controller: T): Readonly<IControllerApiConfig<T>> {
		const controllerType = controller.constructor.name;
		return this.getControllerConfigOrCreate(controllerType);
	}

	public configureController<T extends IHttpServiceBase>(
		controllerConstructor: new (...anyArgs: any[]) => T,
		configProvider: (config: IControllerApiConfigProvider<T>) => void
	) {
		const controllerType = controllerConstructor.name;
		const controllerConfiguration = this.getControllerConfigOrCreate(controllerType);
		configProvider(controllerConfiguration);
		return this;
	}

	private getControllerConfigOrCreate<T extends HttpServiceBase>(
		controllerType: string
	): IControllerApiConfigProvider<T> {
		let controllerConfiguration = ApiConfigProvider.configurations[controllerType];
		if (!controllerConfiguration) {
			controllerConfiguration = ApiConfigProvider.configurations[controllerType] = new ControllerApiConfig<T>();
		}
		return controllerConfiguration;
	}
}

@injectable()
export abstract class HttpServiceBase implements IHttpServiceBase {
	protected constructor(
		protected $http: IHttpService,
		protected $q: IQService,
		private apiConfigProvider: IApiConfigProvider
	) {}

	protected makeGet<T extends IResponsePayload<any>>(
		url: string,
		config: IPnzRequestConfig = {},
		cancelToken?: Promise<void>
	): Promise<T> {
		const resultedConfig = this.getConfig(config);
		return this.convert($http => {
			return $http.makeGet(url, resultedConfig, cancelToken);
		});
	}

	protected makePost<T extends IResponsePayload<any>>(
		url: string,
		data: any,
		config: IPnzRequestConfig = {},
		cancelToken?: Promise<void>
	): Promise<T> {
		const resultedConfig = this.getConfig(config);
		return this.convert($http => {
			return $http.makePost(url, data, resultedConfig, cancelToken);
		});
	}

	protected makePut<T extends IResponsePayload<any>>(
		url: string,
		data: any,
		config: IRequestShortcutConfig = {},
		cancelToken?: Promise<void>
	): Promise<T> {
		const resultedConfig = this.getConfig(config);
		return this.convert($http => {
			return $http.makePut(url, data, resultedConfig, cancelToken);
		});
	}

	protected makePatch<T extends IResponsePayload<any>>(
		url: string,
		data: any,
		config: IRequestShortcutConfig = {},
		cancelToken?: Promise<void>
	): Promise<T> {
		const resultedConfig = this.getConfig(config);
		return this.convert($http => {
			return $http.makePatch(url, data, resultedConfig, cancelToken);
		});
	}

	protected makeDelete<T extends IResponsePayload<any>>(
		url: string,
		config: IRequestShortcutConfig = {},
		cancelToken?: Promise<void>
	): Promise<T> {
		const resultedConfig = this.getConfig(config);
		return this.convert($http => {
			return $http.makeDelete(url, resultedConfig, cancelToken);
		});
	}

	protected getMethodConfig(action: keyof this): Readonly<IMethodApiConfig> {
		if (typeof action !== 'string') {
			throw new Error(`Action must be string, but is '${action}`);
		}
		const controllerConfig = this.apiConfigProvider.getControllerConfig(this);
		return controllerConfig.getMethodConfig(action);
	}

	private getConfig(config: IRequestShortcutConfig): IPnzRequestConfig {
		const extra = this.getControllerConfig();
		return Object.assign(
			{},
			<IPnzApiRequestMarker>{
				pnzApiRequest: true
			},
			extra,
			config
		);
	}

	private getControllerConfig(): Readonly<IPnzRequestConfig> {
		const controllerConfig = this.apiConfigProvider.getControllerConfig(this);
		return controllerConfig.requestConfig;
	}

	private convert<T>(executor: ($http: IHttpService) => Promise<T>): Promise<T> {
		// const deferred = this.$q.defer<T>();
		// executor(this.$http).then((success: T) => {
		//   deferred.resolve(success);
		// }, rejection => {
		//   deferred.reject(rejection);
		// }, notification => {
		//   deferred.notify(notification);
		// })
		// return deferred.promise;
		return executor(this.$http);
	}
}
