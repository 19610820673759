import { HostModule, HostModuleName } from 'ui-controls/stores/host-module';
import { UserModule, UserModuleName } from 'utils/stores/user-module';
import { Store } from 'vuex';
import { SystemModuleName, SystemModule } from 'utils/stores/system-module';
import { UserSettingsModuleName, UserSettingsModule } from 'utils/stores/user-settings-module';
import { MembershipModuleName, MembershipModule } from 'membership/stores/membership-module';

declare global {
	interface IRootState {
		[HostModuleName]: IHostState;
		[UserModuleName]: IUserState;
		[SystemModuleName]: ISystemState;
		[MembershipModuleName]: IMembershipState;
		[UserSettingsModuleName]: IUserSettings;
	}
}

export function configureStores(store: Store<IRootState>) {
	store.registerModule(HostModuleName, HostModule);
	store.registerModule(UserModuleName, UserModule);
	store.registerModule(SystemModuleName, SystemModule);
	store.registerModule(MembershipModuleName, MembershipModule);
	store.registerModule(UserSettingsModuleName, UserSettingsModule, {
		preserveState: UserSettingsModuleName in store.state
	});
}
