import Router from 'vue-router';
import { GetAuthRoutes } from 'membership/auth/routes/auth-routes';
import { WsRouteNamings } from './routes/ws-route-namings';
import { Empty } from './views/empty/empty';
import { GetProfileRoutes } from 'membership/profile/@routes/profile-routes';
const AuthDialog = () =>
	import(/* webpackChunkName: "auth" */ './views/auth-dialog/auth-dialog').then(_ => _.AuthDialog);
const CrystalPackDialog = () =>
	import(/* webpackChunkName: "payments" */ './views/crystal-pack-dialog/crystal-pack-dialog').then(
		_ => _.CrystalPackDialog
	);

export function configureRoutes(router: Router) {
	router.addRoutes([
		{
			path: '/',
			component: Empty
		},
		{
			path: WsRouteNamings.Auth,
			component: AuthDialog,
			beforeEnter: (to, from, next) => {
				to.params.closeUrl = from.fullPath!;
				next();
			},
			children: GetAuthRoutes({
				closable: true
			})
		},
		{
			path: WsRouteNamings.Profile,
			component: AuthDialog,
			beforeEnter: (to, from, next) => {
				to.params.closeUrl = from.fullPath!;
				next();
			},
			children: GetProfileRoutes({
				closable: true,
				preserveGameSession: false
			}),
			meta: { authorizedOnly: true }
		} as IRouteConfigEx,
		{
			path: WsRouteNamings.PurchaseCrystalPack,
			component: CrystalPackDialog,
			beforeEnter: (to, from, next) => {
				to.params.closeUrl = from.fullPath!;
				next();
			},
			meta: { authorizedOnly: true }
		} as IRouteConfigEx,
		{
			path: WsRouteNamings.Error,
			name: WsRouteNamings.Error,
			redirect: '/'
		},
		{ path: '', redirect: '/' },
		{ path: '*', redirect: '/' }
	]);
}
