export class UtilsNamings {
	public static moduleName = 'pnz-utils';
	public static loggingServiceName = 'pnz-logging-service';
	public static eventHubServiceName = 'pnz-event-hub-service';
	public static globalEventHubName = 'pnz-event-hub';
	public static arrayServiceName = 'pnz-array-service';
	public static appConfigProviderName = 'pnz-app-config-provider';
	public static httpErrorHandlerName = 'pnz-http-error-handler';
	public static currentLocaleServiceName = 'pnz-current-locale-service';
	public static localizationServiceName = 'pnz-localization-service';
	public static localizationLoadingService = 'pnz-localization-loading-service';
	public static staticServiceName = 'pnz-static-service';
	public static localStorageServiceName = 'pnz-local-storage-service';
	public static authStateServiceName = 'pnz-auth-state-service';
	public static utilsServiceName = 'pnz-utils-service';
	public static taskLocalStateServiceName = 'pnz-task-local-state-service';
	public static taskManagerServiceName = 'pnz-task-manager-service';
	public static formattingServiceName = 'pnz-formatting-service';
	public static subscriptionServiceName = 'pnz-subscription-service';
	public static compileServiceName = 'pnz-compile-service';
	public static apiConfigProviderName = 'pnz-api-config-provider';
	public static timeoutServiceName = 'pnz-timeout-service';
	public static intervalServiceName = 'pnz-interval-service';
	public static qServiceName = 'pnz-q-service';
	public static httpServiceName = 'pnz-http-service';

	public static appBootstrapServiceName = 'pnz-app-bootstrap-service';
	public static moduleBootstrapServiceName = 'pnz-module-bootstrap-service';

	public static stateServiceName = 'pnz-state-service';

	public static appRootElementName = 'pnz-app-root-element';
	public static appRouterName = 'pnz-router';

	public static appStoreName = 'pnz-store';

	public static appI18nName = 'pnz-i18n';

	public static httpActivityHandlerName = 'pnz-http-activity-handler';

	public static routeUtilsServiceName = 'pnz-route-utils-service';
}
