import { PropOptions } from 'vue';

export class BootstrapColsPropOptions implements PropOptions<Number | String> {
	public type = [Number, String];
	public default = 12;
	public validator(value: any) {
		if (Number(value) >= 1 && Number(value) <= 12) {
			return true;
		}
		return false;
	}
}
