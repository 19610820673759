import { ServerApiNamings } from 'server-api/server-api-namings';
import { UtilsNamings } from 'utils/utils-namings';
import { LogSeverity } from 'utils/logging/log-severity';
import { injectable, inject } from 'inversify';
import { AccountLoginType } from 'server-api/common/common-enums';

declare global {
	export interface IMembershipActionInterceptor {
		beforeLogIn(request: ILoginRequest): Promise<void>;
		beforeExternalLogIn(request: ILoginExternalRequest): Promise<void>;
		beforeRegistration(request: IRegistrationRequest | IShortRegistrationRequest): Promise<void>;
		beforePasswordSet(request: ISetPasswordRequest): Promise<void>;
		afterPasswordSet(): Promise<void>;
		onAuthReceived(
			accountLoginType: AccountLoginType,
			authReceivedAction: AuthReceivedAction,
			isPersistent: boolean
		): Promise<void>;
		onExternalAuthReceived(): Promise<void>;
	}

	interface IMembershipService extends ILogContextProvider {
		login(
			name: string,
			password: string | null,
			needRemember: boolean,
			redirectUri: string
		): Promise<IResponsePayload<ILoginSuccessData>>;

		loginExternalAuthData(
			loginType: AccountLoginType,
			authData: IExternalAuthData
		): Promise<IResponsePayload<ILoginExternalResponse>>;
		registerShort(data: IShortRegistrationRequest): Promise<IResponsePayload<ILoginSuccessData>>;
		addActionInterceptor(interceptor: IMembershipActionInterceptor): void;

		//#region Password recovery

		recoverPassword(request: IRecoverPasswordRequest): Promise<IEmptyResponseResponse>;

		getSecretQuestion(request: ISecretQuestionRequest): Promise<IResponsePayload<ISecretQuestionData>>;

		recoverPasswordByCode(request: IVerificationCodeRequest): Promise<IResponsePayload<IRecoverPasswordData>>;

		setNewPassword(request: ISetPasswordRequest): IEmptyPromise;

		changePassword(model: IChangePasswordRequest): IEmptyPromise;

		//#end region
	}

	type IExternalAuthData = any;
}

export enum AuthReceivedAction {
	Login,
	Registration,
	PasswordRecovery
}

export abstract class MembershipActionInterceptor implements IMembershipActionInterceptor {
	public beforeLogIn(request: ILoginRequest): Promise<void> {
		return Promise.resolve();
	}
	public beforeExternalLogIn(request: ILoginExternalRequest): Promise<void> {
		return Promise.resolve();
	}
	public beforeRegistration(request: IRegistrationRequest): Promise<void> {
		return Promise.resolve();
	}
	public beforePasswordSet(request: ISetPasswordRequest): Promise<void> {
		return Promise.resolve();
	}
	public afterPasswordSet(): Promise<void> {
		return Promise.resolve();
	}
	public onAuthReceived(
		accountLoginType: AccountLoginType,
		authReceivedAction: AuthReceivedAction,
		isPersistent: boolean
	): Promise<void> {
		return Promise.resolve();
	}
	public onExternalAuthReceived(): Promise<void> {
		return Promise.resolve();
	}
}

class DocumentEventMembershipActionInterceptor extends MembershipActionInterceptor {
	constructor() {
		super();
	}
	public onAuthReceived(
		accountLoginType: AccountLoginType,
		authReceivedAction: AuthReceivedAction,
		isPersistent: boolean
	): Promise<void> {
		const body = $(window.document.body);
		body.trigger('pnzAuthReceived');
		return Promise.resolve();
	}
}

@injectable()
export class MembershipService implements IMembershipService {
	private interceptors: IMembershipActionInterceptor[];

	constructor(
		@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService,
		@inject(ServerApiNamings.lsAccountControllerServiceName)
		private accountControllerService: ILsAccountControllerService
	) {
		this.interceptors = [];
		this.interceptors.push(new DocumentEventMembershipActionInterceptor());
	}

	public addActionInterceptor(interceptor: IMembershipActionInterceptor) {
		this.interceptors.push(interceptor);
	}

	//#region login

	public login(
		login: string,
		password: string,
		rememberUser: boolean = false,
		redirectUri: string | null = null
	): Promise<IResponsePayload<ILoginSuccessData>> {
		this.trace('login called');
		const request: ILoginRequest = {
			rememberUser: rememberUser,
			login: login,
			password: password,
			redirectUrl: redirectUri
		};
		return this.onBeforeLogin(request).then(() => {
			return this.accountControllerService
				.login(request)
				.then((payload: IResponsePayload<ILoginSuccessData>) => {
					return this.onAuthReceived(AccountLoginType.Panzar, AuthReceivedAction.Login, rememberUser).then(() => {
						return payload;
					});
				})
				.then(response => {
					this.trace('login succeed');
					return response;
				})
				.catch(rejection => {
					this.trace('login failed');
					return Promise.reject(rejection);
				});
		});
	}

	public loginExternalAuthData(
		loginType: AccountLoginType,
		authData: IExternalAuthData
	): Promise<IResponsePayload<ILoginExternalResponse>> {
		this.trace(`loginExternalAuthData called for:'${loginType}'`);
		const request: ILoginExternalRequest = {
			accountLoginTypeId: loginType,
			authData: authData
		};

		return this.onBeforeLoginExternal(request).then(() => {
			return this.accountControllerService
				.loginExternal(request)
				.then((payload: IResponsePayload<ILoginExternalResponse>) => {
					const authReceivedAction = payload.data.registered
						? AuthReceivedAction.Registration
						: AuthReceivedAction.Login;
					return this.onAuthReceived(loginType, authReceivedAction, false).then(() => {
						return payload;
					});
				})
				.then(response => {
					this.trace('login external succeed');
					return response;
				})
				.catch(rejection => {
					this.trace('login external failed');
					return Promise.reject(rejection);
				});
		});
	}

	private onBeforeLogin(request: ILoginRequest): Promise<void> {
		this.trace('onBeforeLogin called');
		const promises: Array<Promise<void>> = [];
		this.interceptors.forEach(interceptor => {
			promises.push(interceptor.beforeLogIn(request));
		});
		return Promise.all(promises).then(() => {
			this.trace('onBeforeLogin completed');
			return Promise.resolve();
		});
	}

	private onBeforeLoginExternal(request: ILoginExternalRequest): Promise<void> {
		this.trace('onBeforeLoginExternal called');
		const promises: Array<Promise<void>> = [];
		this.interceptors.forEach(interceptor => {
			promises.push(interceptor.beforeExternalLogIn(request));
		});
		return Promise.all(promises).then(() => {
			this.trace('onBeforeLoginExternal completed');
			return Promise.resolve();
		});
	}

	//#endregion login

	//#region short registration

	public registerShort(data: IShortRegistrationRequest): Promise<IResponsePayload<ILoginSuccessData>> {
		this.trace('registerShort called');
		return this.onBeforeRegistration(data)
			.then(() => {
				return this.accountControllerService.registerShort(data);
			})
			.then((payload: IResponsePayload<ILoginSuccessData>) => {
				return this.onAuthReceived(AccountLoginType.Panzar, AuthReceivedAction.Registration, false).then(() => {
					this.trace('registerShort succeed');
					return payload;
				});
			})
			.catch((rejection: any) => {
				this.trace('registerShort failed');
				return Promise.reject(rejection);
			});
	}

	//#endregion short registration

	//#region Password recovery

	public recoverPassword(request: IRecoverPasswordRequest): Promise<IEmptyResponseResponse> {
		this.trace('recoverPassword called');
		return this.accountControllerService.recoverPassword(request);
	}

	public getSecretQuestion(request: ISecretQuestionRequest): Promise<IResponsePayload<ISecretQuestionData>> {
		this.trace('getSecretQuestion called');
		return this.accountControllerService.getSecretQuestion(request);
	}

	public recoverPasswordByCode(request: IVerificationCodeRequest): Promise<IResponsePayload<IRecoverPasswordData>> {
		this.trace('recoverPasswordByCode called');
		return this.accountControllerService.recoverPasswordByCode(request).then(result => {
			this.onAuthReceived(AccountLoginType.Panzar, AuthReceivedAction.PasswordRecovery, false);
			return result;
		});
	}

	public setNewPassword(request: ISetPasswordRequest): IEmptyPromise {
		this.trace('setPassword called');
		this.interceptors.forEach(interceptor => {
			interceptor.beforePasswordSet(request);
		});
		return this.accountControllerService.setPassword(request).then(result => {
			this.interceptors.forEach(interceptor => {
				interceptor.afterPasswordSet();
			});
			return result;
		});
	}

	public changePassword(request: IChangePasswordRequest): IEmptyPromise {
		this.trace('changePassword called');
		return this.accountControllerService.changePassword(request);
	}

	//#endregion

	//#region logout

	public logout(redirectUrl?: string): IEmptyPromise {
		this.trace('logout called');
		const request: ILogoutRequest = {
			redirectUrl: redirectUrl
		};
		return this.accountControllerService.logout(request);
	}

	//#endregion logout

	private onAuthReceived(
		accountLoginType: AccountLoginType,
		authReceivedAction: AuthReceivedAction,
		isPersistent: boolean
	): Promise<void> {
		this.trace('triggering onBeforeLogon');
		const promises: Array<Promise<void>> = [];
		this.interceptors.forEach(interceptor => {
			promises.push(interceptor.onAuthReceived(accountLoginType, authReceivedAction, isPersistent));
		});
		return Promise.all(promises).then(response => {
			this.trace('interceptors released onBeforeLogon');
			return Promise.resolve();
		});
	}

	private onBeforeRegistration(request: IRegistrationRequest | IShortRegistrationRequest): Promise<void> {
		this.trace('onBeforeRegistration called');
		const promises: Array<Promise<void>> = [];
		this.interceptors.forEach(interceptor => {
			promises.push(interceptor.beforeRegistration(request));
		});
		return Promise.all(promises).then(() => {
			this.trace('onBeforeRegistration completed');
		});
	}

	private trace(message: string, severity: LogSeverity = LogSeverity.INFO) {
		this.loggingService.log('[MembershipService] ' + message, severity);
	}

	public processLogContext(context: any, severity: LogSeverity): void {}
}
