import { UIComponent } from 'ui-controls-core/ui-component';
import { LogSeverity } from 'utils/logging/log-severity';
import { camelize, findChildCmpByNameRecursive } from 'utils/common/utils';
import { FormBase } from './form-base';
import { DefaultHttpErrorMessage } from '../../utils/http/consts';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

declare global {
	type FormErrorReason = 'cancelled' | 'closed';

	interface IFormParams {
		success(data: any): void;
		error(reason: FormErrorReason): void;
	}

	interface ISubmitSuccessEventArgs<TResponseData> {
		formName: string | undefined;
		responseResult: IResponsePayload<TResponseData>;
	}
}

export const FormContainerProvideKey = 'form-container';
export const FormModelProvideKey = 'form-model';

export enum FormContainerEventNames {
	Submit = 'form:submit',
	SubmitSuccess = 'form:submit:success',
	SubmitFailure = 'form:submit:failure',
	ErrorChange = 'form:error:change'
}

//@ts-ignore
@Component({
	provide(this: FormContainer<FormBase, object>) {
		return {
			[FormContainerProvideKey]: this,
			[FormModelProvideKey]: this.model
		};
	}
})
export abstract class FormContainer<TForm extends FormBase, TRule, TModel extends object = any> extends UIComponent<
	FormContainerEventNames
> {
	@Prop({ type: Boolean, default: false })
	public isReadonly!: boolean;

	public abstract model: TModel;
	public abstract rules: { [P in keyof RequiredWritablePart<TModel>]: TRule[] };
	public formError: string | null = null;
	public isLoading: boolean = false;
	protected submitted: boolean = false;

	public formRef: TForm | null = null;

	protected abstract get formComponentName(): string;

	public abstract reset(): void;

	public abstract clearErrors(): void;

	public abstract isValid(): Promise<boolean>;

	public abstract validate(): Promise<boolean>;

	public submit() {
		this.trace('form submission attempt');
		this.submitted = true;
		this.isValid().then(valid => {
			if (!valid) {
				this.trace(`form is invalid due pre-check. skipping submission...`);
				return;
			}
			this.validate().then(valid2 => {
				if (!valid2) {
					this.trace(`form is invalid due check. skipping submission...`);
					return;
				}
				if (this.isLoading) {
					this.trace('form is loading. skipping submission...');
					return;
				}
				this.clearErrors();
				this.formError = null;
				this.eventHub.trigger(FormContainerEventNames.ErrorChange);
				this.isLoading = true;
				this.processSubmit(this.destroyPromise)
					.then(submitResult => {
						this.trace('submission succeeded');
						this.success({ result: submitResult });
						this.eventHub.trigger(FormContainerEventNames.SubmitSuccess, submitResult);
						const submitSuccessEventArgs: ISubmitSuccessEventArgs<any> = {
							formName: this.$options.name,
							responseResult: submitResult
						};
						this.globalEventHub.trigger(FormContainerEventNames.SubmitSuccess, submitSuccessEventArgs);
					})
					.catch(failure => {
						this.trace(`submission failed due to:'${JSON.stringify(failure)}'`, LogSeverity.WARNING);
						this.processFailure(failure);
						this.eventHub.trigger(FormContainerEventNames.SubmitFailure, this);
						return failure;
					})
					.finally(() => {
						this.isLoading = false;
						this.eventHub.trigger(FormContainerEventNames.Submit);
					});
			});
		});
	}

	protected abstract processSubmit(destroyPromise: Promise<void>): Promise<any>;

	protected abstract setFieldValidity(fieldName: string, isValid: boolean, error: string): void;

	protected processFailure(actionFailure: IResponseError | string) {
		if (!actionFailure) {
			this.formError = DefaultHttpErrorMessage;
		} else if (typeof actionFailure === 'string') {
			this.formError = actionFailure;
		} else if (actionFailure instanceof Error) {
			this.formError = actionFailure.message;
		} else {
			if (actionFailure.errorMessage != null) {
				this.formError = actionFailure.errorMessage;
			} else if (actionFailure.validationErrors != null) {
				this.processValidationErrors(actionFailure.validationErrors);
			} else {
				this.formError = actionFailure.errorMessage || DefaultHttpErrorMessage;
			}
		}
		this.eventHub.trigger(FormContainerEventNames.ErrorChange);
	}

	protected processValidationErrors = (errors: IValidationError[]) => {
		this.clearErrors();
		errors.forEach(error => {
			const fieldName = camelize(error.parameter);
			if (!this.isFieldExits(fieldName)) {
				return;
			}
			this.setFieldValidity(fieldName, false, error.error);
		});
	};

	protected abstract isFieldExits(fieldName: string): boolean;

	protected success(param: { result: any }) {
		const formParams = (this.$route.params as never) as IFormParams;
		if (formParams.success) {
			formParams.success(param);
		}
	}

	public abstract isFieldInvalid(fieldName: string): boolean;

	public abstract isFieldValid(fieldName: string): boolean;

	public abstract getFieldError(fieldName: string): string | null;
}
