import VueI18n from 'vue-i18n';
import { Validator } from 'NodeModules/vee-validate/dist/vee-validate.minimal.esm.js';

export class VeeValidateLocalizationLoader implements ILocalizationLoader {
	constructor(private i18n: VueI18n) {}

	public load(locale: string): Promise<void> {
		return import(
			/* webpackChunkName: "locale-vee-[request]" */ /* webpackInclude: /(en|ru).js$/ */ `NodeModules/vee-validate/dist/locale/${locale}`
		)
			.then(messages => {
				Validator.localize(locale, messages.default);
			})
			.catch(e => {
				throw new Error('failed to load vee validate locale. reason: ' + e);
			});
	}
}
