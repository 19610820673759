import { configureIoC } from 'server-api/configure-ioc';
import { ServerApiNamings } from './server-api-namings';
import { UtilsNamings } from 'utils/utils-namings';
import { UtilsModule } from 'utils/utils-module';

export class ServerApi implements IModule {
	public moduleName = ServerApiNamings.moduleName;

	public registerSubModules(context: IAppContext): Promise<void> {
		context.moduleContainer.register(UtilsNamings.moduleName, UtilsModule);
		return Promise.resolve();
	}
	public configureIoC(context: IAppContext): Promise<void> {
		configureIoC();
		return Promise.resolve();
	}
	public configureRoutes(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public registerUIComponents(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public configureModule(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public beforeRun(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public afterRun(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
}
