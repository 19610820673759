import { HttpServiceBase } from './http-service-base';

declare global {
	interface IPagedResult<T> {
		totalCount: number;
		items: T[];
	}

	interface IRestServiceBase<
		TEntity,
		TReturnEntityDto,
		TGetPageRequestDto extends IPagedSortedFilteredResultRequest,
		TCreateRequestDto,
		TUpdateRequestDto,
		TUpdatePartialRequestDto
	> {
		get(id: string, cancelToken?: Promise<void>): Promise<IResponsePayload<TReturnEntityDto>>;
		getPage(
			params?: TGetPageRequestDto,
			cancelToken?: Promise<void>
		): Promise<IResponsePayload<IPagedResult<TReturnEntityDto>>>;

		getAll(params?: ISortedFilteredResultRequest): Promise<IResponsePayload<TReturnEntityDto[]>>;
		post(data: TCreateRequestDto, cancelToken?: Promise<void>): Promise<IResponsePayload<TEntity>>;
		put(id: string, data: TUpdateRequestDto, cancelToken?: Promise<void>): Promise<IResponsePayload<void>>;
		patch(
			id: string,
			data: Partial<TUpdatePartialRequestDto>,
			cancelToken?: Promise<void>
		): Promise<IResponsePayload<TEntity>>;
		delete(id: string, cancelToken?: Promise<void>): Promise<IResponsePayload<void>>;

		readonly baseUrl: string;
	}
}

export class RestServiceBase<
	TEntity,
	TReturnEntityDto,
	TGetPageRequestDto extends IPagedSortedFilteredResultRequest,
	TCreateRequestDto,
	TUpdateRequestDto,
	TUpdatePartialRequestDto
> extends HttpServiceBase
	implements
		IRestServiceBase<
			TEntity,
			TReturnEntityDto,
			TGetPageRequestDto,
			TCreateRequestDto,
			TUpdateRequestDto,
			TUpdatePartialRequestDto
		> {
	constructor(
		public readonly baseUrl: string,
		$http: IHttpService,
		$q: IQService,
		apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
		if (!this.baseUrl.endsWith('/')) {
			this.baseUrl += '/';
		}
	}
	public get(id: string, cancelToken?: Promise<void>): Promise<IResponsePayload<TReturnEntityDto>> {
		return super.makeGet<IResponsePayload<TReturnEntityDto>>(this.baseUrl + `${id}`, {}, cancelToken);
	}

	public getPage(
		params: TGetPageRequestDto,
		cancelToken?: Promise<void>
	): Promise<IResponsePayload<IPagedResult<TReturnEntityDto>>> {
		return super.makeGet<IResponsePayload<IPagedResult<TReturnEntityDto>>>(
			this.baseUrl,
			{
				params: params
			},
			cancelToken
		);
	}

	public getAll(
		params: ISortedFilteredResultRequest,
		cancelToken?: Promise<void>
	): Promise<IResponsePayload<TReturnEntityDto[]>> {
		return super.makeGet<IResponsePayload<TReturnEntityDto[]>>(
			this.baseUrl,
			{
				params: params
			},
			cancelToken
		);
	}

	public post(data: TCreateRequestDto, cancelToken?: Promise<void>) {
		return super.makePost<IResponsePayload<TEntity>>(this.baseUrl, data, {}, cancelToken);
	}

	public put(id: string, data: TUpdateRequestDto, cancelToken?: Promise<void>) {
		return super.makePut<IResponsePayload<void>>(this.baseUrl + `${id}`, data, {}, cancelToken);
	}

	public patch(id: string, data: Partial<TUpdatePartialRequestDto>, cancelToken?: Promise<void>) {
		return super.makePatch<IResponsePayload<TEntity>>(this.baseUrl + `${id}`, data, {}, cancelToken);
	}

	public delete(id: string, cancelToken?: Promise<void>) {
		return super.makeDelete<IResponsePayload<void>>(this.baseUrl + `${id}`, {}, cancelToken);
	}
}
