import { container } from 'utils/ioc/container';
import { ServerApiNamings } from './server-api-namings';
import { LsAccountControllerService } from './ls/ls-account-controller-service';
import { LsGameLoginControllerService } from './ls/ls-game-login-controller-service';
import { LsLauncherControllerService } from './ls/ls-launcher-controller-service';

import { GaWbNewsMessageControllerService } from './ga/ga-wb-news-message-controller-service';
import { GaWbTagControllerService } from './ga/ga-wb-tag-controller-service';
import { GaWbMediaCatalogueControllerService } from './ga/ga-wb-media-catalogue-controller-service';
import { GaWbMediaControllerService } from './ga/ga-wb-media-controller-service';
import { DsMediaUrlService } from './ds/ds-media-url-service';
import { GaWbCategoryControllerService } from './ga/ga-wb-category-controller-service';
import { GaWbMediaLocalizationControllerService } from './ga/ga-wb-media-localization-controller-service';
import { GaWbNewsMessagePlacementControllerService } from './ga/ga-wb-news-message-placement-controller-service';
import { GaAccountControllerService } from './ga/ga-account-controller-service';
import { DsNewsControllerService } from './ds/ds-news-controller-service';
import { GaAdCampaignControllerService } from './ga/ga-ad-campaign-controller-service';
import { GaAdPixelControllerService } from './ga/ga-ad-pixel-controller-service';
import { GaAdSourceControllerService } from './ga/ga-ad-source-controller-service';
import { GaAdTargetInstanceControllerService } from './ga/ga-ad-terget-instance-controller-service';
import { GaAccountsControllerService } from './ga/ga-accounts-controller-service';
import { GaComputerBanControllerService } from './ga/ga-computer-ban-controller-service';
import { GaPlayerControllerService } from './ga/ga-player-controller-service';
import { GaAccountLoginControllerService } from './ga/ga-account-login-controller-service';
import { DsCrystalPackControllerService } from './ds/ds-crystal-pack-controller-service';
import { WsCrystalPackControllerService } from './ws/ws-crystal-pack-controller-service';
import { DsPromoCodeControllerService } from './ds/ds-promo-code-controller-service';
import { LsTicketControllerService } from './ls/ls-ticket-controller-service';
import { WsNavigateControllerService } from './ws/ws-navigate-controller-service';
import { WsPaymentSteamControllerService } from './ws/ws-payment-steam-controller-service';
import { WsPaymentControllerService } from './ws/ws-payment-controller-service';
import { GaTournamentControllerService } from './ga/ga-tournament-controller-service';
import { DsHelpDeskControllerService } from './ds/ds-help-desk-controller-service';
import { GaHelpDeskTicketControllerService } from './ga/ga-help-desk-ticket-controller-service';
import { GaAccountBanControllerService } from './ga/ga-account-ban-controller-service';
export function configureIoC() {
	container
		.bind<LsAccountControllerService>(ServerApiNamings.lsAccountControllerServiceName)
		.to(LsAccountControllerService);
	container
		.bind<LsGameLoginControllerService>(ServerApiNamings.lsGameLoginControllerServiceName)
		.to(LsGameLoginControllerService);

	container
		.bind<LsTicketControllerService>(ServerApiNamings.lsTicketControllerServiceName)
		.to(LsTicketControllerService);

	container
		.bind<LsLauncherControllerService>(ServerApiNamings.lsLauncherControllerServiceName)
		.to(LsLauncherControllerService);

	container
		.bind<GaWbNewsMessageControllerService>(ServerApiNamings.gaWbNewsMessageControllerServiceName)
		.to(GaWbNewsMessageControllerService);

	container
		.bind<IGaWbNewsMessagePlacementControllerService>(ServerApiNamings.gaWbNewsMessagePlacementControllerServiceName)
		.to(GaWbNewsMessagePlacementControllerService);

	container.bind<GaWbTagControllerService>(ServerApiNamings.gaWbTagControllerServiceName).to(GaWbTagControllerService);

	container
		.bind<GaWbCategoryControllerService>(ServerApiNamings.gaWbCategoryControllerServiceName)
		.to(GaWbCategoryControllerService);

	container
		.bind<GaWbMediaCatalogueControllerService>(ServerApiNamings.gaWbMediaCatalogueControllerServiceName)
		.to(GaWbMediaCatalogueControllerService);

	container
		.bind<GaWbMediaControllerService>(ServerApiNamings.gaWbMediaControllerServiceName)
		.to(GaWbMediaControllerService);
	container
		.bind<GaWbMediaLocalizationControllerService>(ServerApiNamings.gaWbMediaLocalizationControllerServiceName)
		.to(GaWbMediaLocalizationControllerService);

	container.bind<DsMediaUrlService>(ServerApiNamings.dsMediaUrlServiceName).to(DsMediaUrlService);

	container
		.bind<GaAccountControllerService>(ServerApiNamings.gaAccountControllerServiceName)
		.to(GaAccountControllerService);

	container
		.bind<GaTournamentControllerService>(ServerApiNamings.gaTournamentsControllerServiceName)
		.to(GaTournamentControllerService);

	container.bind<DsNewsControllerService>(ServerApiNamings.dsNewsControllerServiceName).to(DsNewsControllerService);

	container
		.bind<DsCrystalPackControllerService>(ServerApiNamings.dsCrystalPackControllerServiceName)
		.to(DsCrystalPackControllerService);

	container
		.bind<DsPromoCodeControllerService>(ServerApiNamings.dsPromoCodeControllerServiceName)
		.to(DsPromoCodeControllerService);

	container
		.bind<DsHelpDeskControllerService>(ServerApiNamings.dsHelpDeskControllerServiceName)
		.to(DsHelpDeskControllerService);

	container
		.bind<GaAdCampaignControllerService>(ServerApiNamings.gaAdCampaignControllerServiceName)
		.to(GaAdCampaignControllerService);

	container
		.bind<GaAdPixelControllerService>(ServerApiNamings.gaAdPixelControllerServiceName)
		.to(GaAdPixelControllerService);

	container
		.bind<GaAdSourceControllerService>(ServerApiNamings.gaAdSourceControllerServiceName)
		.to(GaAdSourceControllerService);

	container
		.bind<GaAdTargetInstanceControllerService>(ServerApiNamings.gaAdTargetInstanceControllerServiceName)
		.to(GaAdTargetInstanceControllerService);

	container
		.bind<GaAccountsControllerService>(ServerApiNamings.gaAccountsControllerServiceName)
		.to(GaAccountsControllerService);

	container
		.bind<GaHelpDeskTicketControllerService>(ServerApiNamings.gaHelpDeskTicketControllerServiceName)
		.to(GaHelpDeskTicketControllerService);

	container
		.bind<GaPlayerControllerService>(ServerApiNamings.gaPlayerControllerServiceName)
		.to(GaPlayerControllerService);

	container
		.bind<GaAccountLoginControllerService>(ServerApiNamings.gaAccountLoginControllerServiceName)
		.to(GaAccountLoginControllerService);

	container
		.bind<GaComputerBanControllerService>(ServerApiNamings.gaComputerBanControllerServiceName)
		.to(GaComputerBanControllerService);

	container
		.bind<GaAccountBanControllerService>(ServerApiNamings.gaAccountBanControllerServiceName)
		.to(GaAccountBanControllerService);

	container
		.bind<WsNavigateControllerService>(ServerApiNamings.wsNavigateControllerServiceName)
		.to(WsNavigateControllerService);

	container
		.bind<WsCrystalPackControllerService>(ServerApiNamings.wsCrystalPackControllerServiceName)
		.to(WsCrystalPackControllerService);

	container
		.bind<WsPaymentSteamControllerService>(ServerApiNamings.wsPaymentSteamControllerServiceName)
		.to(WsPaymentSteamControllerService);

	container
		.bind<WsPaymentControllerService>(ServerApiNamings.wsPaymentControllerServiceName)
		.to(WsPaymentControllerService);
}
