import { Component, Vue, Prop } from 'vue-property-decorator';
import { mergeData } from 'vue-functional-data-merge';
import { CreateElement, VNode, PropOptions } from 'vue';
import { BootstrapColsPropOptions } from 'ui-controls/bootstrap/grid';

import './form-group.scss';

@Component
export class FormGroup extends Vue {
	@Prop(new BootstrapColsPropOptions())
	public colSm: any;

	@Prop(new BootstrapColsPropOptions())
	public offsetSm: any;

	@Prop({ type: Boolean, default: false })
	public withError!: boolean;

	@Prop({ type: Boolean, default: true })
	public withTopMargin!: boolean;
	public render(createElement: CreateElement): VNode {
		return createElement(
			'div',
			mergeData(this.$data, {
				staticClass: `form-group col-sm-${this.colSm} offset-sm-${this.offsetSm} ${
					this.withError ? 'pnz-form-group-with-error' : ''
				}`
			}),
			[
				createElement(
					'div',
					{
						staticClass: `pnz-form-group ${this.withTopMargin ? 'pnz-form-group-with-top-margin' : ''}`
					},
					this.$slots.default
				)
			]
		);
	}
}
