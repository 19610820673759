<div class="pnz-spin-preloader" v-show="showPreloader" v-bind:style="{'background-color' : backgroundColor}">
	<div class="pnz-spin-preloader-inner">
		<div class="pnz-spin-preloader-inner2">
			<div class="pnz-spin-preloader-inner3">
			</div>
		</div>
	</div>
	<div class="pnz-spin-preloader-text" v-if="text">
		{{ text }}
	</div>
</div>
