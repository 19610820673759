import { injectable } from 'inversify';

declare global {
	interface ITimeoutService {
		timeout(delay?: number): Promise<void>;
		cancel(promise: Promise<any>): void;
	}
}

@injectable()
export class TimeoutService implements ITimeoutService {
	private static promiseIdFiledName: string = '_promiseId';
	private deferreds: IMap<() => void> = {};
	public timeout(delay?: number): Promise<void> {
		let id: any;
		const promise = new Promise<void>((resolve, reject) => {
			id = setTimeout(() => {
				try {
					resolve();
				} catch (e) {
					reject(e);
				} finally {
					delete this.deferreds[id];
				}
			}, delay || 0);
			this.deferreds[id] = reject;
		});
		(<any>promise)[TimeoutService.promiseIdFiledName] = id;
		return promise;
	}

	public cancel(promise: Promise<any>): void {
		const id: number = (<any>promise)[TimeoutService.promiseIdFiledName];
		const reject = this.deferreds[id];
		if (reject) {
			reject();
		}
	}
}
