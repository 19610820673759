import { Component, Prop } from 'vue-property-decorator';
import WithRender from './wizard.html';
import { UIComponent } from 'ui-controls-core/ui-component';

require('./wizard.scss');

declare global {
	interface IWizardRouteConfigMeta extends IRouteConfigMeta {
		back?: string;
	}

	type IWizardRouteConfig = IRouteConfigEx<IWizardRouteConfigMeta>;
}

@WithRender
@Component
export class Wizard extends UIComponent {
	public closeUrl?: string;
	public mounted() {
		this.closeUrl = this.$route.params.closeUrl;
	}

	@Prop({ type: Boolean, default: false })
	public closable!: boolean;

	public cachedViews = {};
	public transitionDirection: string | null = null;

	public get routeMeta(): IWizardRouteConfigMeta {
		return <IWizardRouteConfigMeta>this.$route.meta;
	}

	public get isBackButtonVisible(): boolean {
		return !!this.routeMeta.back;
	}
	public get key() {
		return this.$route.fullPath;
	}

	public get title() {
		if (!this.routeMeta.title) {
			return '';
		}
		return this.localize(this.routeMeta.title);
	}

	public onBackClick() {
		this.$router.push({ name: this.routeMeta.back!, query: this.$route.query, params: this.$route.params });
		this.transitionDirection = 'slide-left';
	}

	public onCloseClick() {
		if (this.closeUrl) {
			this.$router.push(this.closeUrl);
		}
	}

	public transitionAfterEnter() {
		this.transitionDirection = null;
	}
}
