import { UtilsNamings } from 'utils/utils-namings';
import { EventHub } from 'utils/events/event-hub';
import { injectable, inject } from 'inversify';
import { Uid } from '../common/utils';

declare global {
	interface IEventHubService {
		create(uid: Uid, name?: string | null): IEventHub;
	}

	interface IEventHub<TEvents = any> {
		bind(eventName: TEvents, callback: (...params: any[]) => void): () => void;
		unbind(eventName: TEvents, callback: (...params: any[]) => void): void;
		trigger(eventName: TEvents, ...args: any[]): void;
		close(): void;
	}
}

@injectable()
export class EventHubService implements IEventHubService {
	constructor(@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService) {}

	public create(uid: Uid, name: string | null = null): IEventHub {
		return new EventHub(this.loggingService, uid, name);
	}
}
