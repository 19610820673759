import { Component, Vue, Prop } from 'vue-property-decorator';
import { container } from 'utils/ioc/container';
import WithRender from './preloader.html';

import './preloader.scss';
import { UtilsNamings } from 'utils/utils-namings';

@WithRender
@Component
export class Preloader extends Vue {
	@Prop()
	public text!: string;

	@Prop()
	public delay!: string;

	@Prop()
	public backgroundColor!: string;

	private timeoutPromise: Promise<void> | null = null;

	private showPreloader: boolean = false;
	private timeoutService: ITimeoutService;

	constructor() {
		super();
		this.timeoutService = container.get<ITimeoutService>(UtilsNamings.timeoutServiceName);
	}

	public mounted() {
		const delay = parseInt(this.delay);
		this.timeoutPromise = null;
		if (delay > 0) {
			this.showPreloader = false;
			this.timeoutPromise = this.timeoutService.timeout(delay);
			this.timeoutPromise.then(() => {
				this.showPreloader = true;
				this.timeoutPromise = null;
			});
		} else {
			this.showPreloader = true;
		}
	}

	public beforeDestroy() {
		if (this.timeoutPromise) {
			this.timeoutService.cancel(this.timeoutPromise);
		}
	}
}
