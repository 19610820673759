import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaAccountsControllerService
		extends IRestServiceBase<
				{},
				IAccountResponse,
				IAccountFilterRequest,
				IAccountCreateRequest,
				IAccountUpdateRequest,
				IAccountPartialUpdateRequest
			> {
		createItem(
			id: string,
			data: IAccountItemCreateRequest,
			cancelToken?: Promise<void> | undefined
		): Promise<IResponsePayload<void>>;
		createBulk(data: IAccountBulkCreateRequest, cancelToken?: Promise<void>): Promise<IResponsePayload<void>>;

		createCrystalPackage(
			id: string,
			data: IAccountCrystalPackageCreateRequest,
			cancelToken?: Promise<void>
		): Promise<IResponsePayload<void>>;

		deleteRcSession(id: string, cancelToken?: Promise<void>): Promise<IResponsePayload<void>>;

		deleteGameSession(id: string, cancelToken?: Promise<void>): Promise<IResponsePayload<void>>;
	}
}

@injectable()
export class GaAccountsControllerService
	extends RestServiceBase<
		{},
		IAccountResponse,
		IAccountFilterRequest,
		IAccountCreateRequest,
		IAccountUpdateRequest,
		IAccountPartialUpdateRequest
	>
	implements IGaAccountsControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/account', $http, $q, apiConfigProvider);
	}

	public createItem(
		id: string,
		data: IAccountItemCreateRequest,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `${id}/item`, data, {}, cancelToken);
	}

	public createBulk(
		data: IAccountBulkCreateRequest,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `bulk`, data, {}, cancelToken);
	}
	public createCrystalPackage(
		id: string,
		data: IAccountCrystalPackageCreateRequest,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `${id}/crystal-package`, data, {}, cancelToken);
	}

	public deleteRcSession(id: string, cancelToken?: Promise<void> | undefined): Promise<IResponsePayload<void>> {
		return super.makeDelete<IResponsePayload<void>>(this.baseUrl + `${id}/rc-session`, {}, cancelToken);
	}
	public deleteGameSession(id: string, cancelToken?: Promise<void> | undefined): Promise<IResponsePayload<void>> {
		return super.makeDelete<IResponsePayload<void>>(this.baseUrl + `${id}/game-session`, {}, cancelToken);
	}
}
