import { DirectiveOptions } from 'vue';
import $ from 'jquery';

export class PreventBackspaceNavigationDirective implements DirectiveOptions {
	public inserted(el: HTMLElement) {
		$(el).on('keydown', PreventBackspaceNavigationDirective.handler);
	}

	public unbind(el: HTMLElement) {
		$(el).off('keydown', <any>PreventBackspaceNavigationDirective.handler);
	}

	private static handler(event: JQuery.TriggeredEvent): void | boolean {
		// tslint:disable-next-line
		if (event.keyCode === 8) {
			let doPrevent = true;
			const types = [
				'text',
				'password',
				'file',
				'search',
				'email',
				'number',
				'date',
				'color',
				'datetime',
				'datetime-local',
				'month',
				'range',
				'search',
				'tel',
				'time',
				'url',
				'week'
			];
			const d = $(event.target);
			const disabled = d.prop('readonly') || d.prop('disabled');
			if (!disabled) {
				if ((<any>d[0]).isContentEditable) {
					doPrevent = false;
				} else if (d.is('input')) {
					let type = d.attr('type');
					if (type) {
						type = type.toLowerCase();
					}
					if (types.indexOf(type!) > -1) {
						doPrevent = false;
					}
				} else if (d.is('textarea')) {
					doPrevent = false;
				}
			}
			if (doPrevent) {
				event.preventDefault();
				return false;
			}
			return true;
		}
	}
}
