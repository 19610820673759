import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface ITournamentParticipantResponse {
		id: string;
		playerNames: INamedEntityResponse[];
	}

	interface ITournamentResponse {
		id: string;
		name: string;
		description?: any;
		tournamentTypeId: string;
		levelModeId: string;
		publicationMoment: Date;
		registrationStartMoment: Date;
		registrationEndMoment: Date;
		startOnGmMoment: Date;
		startOnSvMoment: Date;
		endMoment?: Date;
		deleteDate?: Date;
		penaltyForNotAppearingDays: number | null;
		masterAccounts: ITournamentParticipantResponse[];
		playerManagerAccounts: ITournamentParticipantResponse[];
		players: IPlayerResponse[];
		spectators: IPlayerResponse[];
		rewards: ITournamentRewardData[];
		result: ITournamentResultData;
	}

	interface ITournamentRewardData {
		beginPlaceInclusive: number;
		endPlaceExclusive: number;
		outGameReward: string;
	}

	interface ITournamentResultData {
		places: IPlayerResponse[];
		unplacedPlayers: IPlayerResponse[];
		endMoment: Date;
	}

	interface ITournamentFilterRequest {}
	interface ITournamentRequestBase {
		name?: string;
		description: string | null;
		tournamentTypeId: string;
		levelModeId: string;
		publicationMoment: Date;
		registrationStartMoment: Date;
		registrationEndMoment: Date;
		startOnGmMoment: Date;
		startOnSvMoment: Date;
		penaltyForNotAppearingDays: number | null;
		masterAccounts: string[];
		playerManagerAccounts: string[];
		players: IPlayerIdTuple[];
		spectators: IPlayerIdTuple[];
		rewards: ITournamentRewardData[];
	}

	interface ITournamentModificationRequest extends ITournamentRequestBase {}

	interface ITournamentCreateRequest extends ITournamentModificationRequest {}

	interface ITournamentUpdateRequest extends ITournamentModificationRequest {}

	interface ITournamentPartialUpdateRequest extends ITournamentModificationRequest {}

	interface ISetResultRequest {
		endMoment: Date;
		places: IPlayerIdTuple[];
		unplacedPlayers: IPlayerIdTuple[];
	}

	interface IAddPlayerRequest {
		playerIdTuple: IPlayerIdTuple;
		isSpectator: boolean;
	}

	interface IUpdatePlayerRequest {
		playerIdTuple: IPlayerIdTuple;
		isSpectator: boolean;
	}

	interface IGaTournamentControllerService
		extends IRestServiceBase<
				{},
				ITournamentResponse,
				ITournamentFilterRequest,
				ITournamentCreateRequest,
				ITournamentUpdateRequest,
				ITournamentPartialUpdateRequest
			> {
		startSv(id: string): Promise<IResponsePayload<void>>;
		startGm(id: string): Promise<IResponsePayload<void>>;

		addPlayer(id: string, data: IAddPlayerRequest): Promise<IResponsePayload<void>>;
		updatePlayer(id: string, data: IUpdatePlayerRequest): Promise<IResponsePayload<void>>;

		deletePlayer(id: string, playerId: string): Promise<IResponsePayload<void>>;

		setResult(
			id: string,
			data: ISetResultRequest,
			cancelToken?: Promise<void> | undefined
		): Promise<IResponsePayload<void>>;
	}
}

@injectable()
export class GaTournamentControllerService
	extends RestServiceBase<
		{},
		ITournamentResponse,
		ITournamentFilterRequest,
		ITournamentCreateRequest,
		ITournamentUpdateRequest,
		ITournamentPartialUpdateRequest
	>
	implements IGaTournamentControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/tournament', $http, $q, apiConfigProvider);
	}
	public startGm(id: string, cancelToken?: Promise<void> | undefined): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `${id}/gm-start`, {}, {}, cancelToken);
	}
	public startSv(id: string, cancelToken?: Promise<void> | undefined): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `${id}/sv-start`, {}, {}, cancelToken);
	}
	public setResult(
		id: string,
		data: ISetResultRequest,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makePut<IResponsePayload<void>>(this.baseUrl + `${id}/result`, data, {}, cancelToken);
	}
	public addPlayer(
		id: string,
		data: IAddPlayerRequest,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `${id}/player`, data, {}, cancelToken);
	}
	public updatePlayer(
		id: string,
		data: IUpdatePlayerRequest,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makePut<IResponsePayload<void>>(this.baseUrl + `${id}/player`, data, {}, cancelToken);
	}
	public deletePlayer(
		id: string,
		playerId: string,
		cancelToken?: Promise<void> | undefined
	): Promise<IResponsePayload<void>> {
		return super.makeDelete<IResponsePayload<void>>(this.baseUrl + `${id}/player/${playerId}`, {}, cancelToken);
	}
}
