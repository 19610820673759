import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaHelpDeskTicketControllerService
		extends IRestServiceBase<
				{},
				IHelpDeskTicketResponse,
				IHelpDeskTicketFilterRequest,
				IHelpDeskTicketCreateRequest,
				IHelpDeskTicketUpdateRequest,
				IHelpDeskTicketPartialUpdateRequest
			> {}

	interface IHelpDeskTicketResponse {
		id: string;
		accountId: string;
		playerName?: string;
		accountLoginName?: string;
		accountLoginTypeId?: string;
		helpdeskReasonId: string;
		replyEmail: string;
		mailMessageSendStatusId: string;
		token: string;
	}

	interface IHelpDeskTicketFilterRequest {}
	interface IHelpDeskTicketCreateRequest {}
	interface IHelpDeskTicketUpdateRequest {}
	interface IHelpDeskTicketPartialUpdateRequest {}
}

@injectable()
export class GaHelpDeskTicketControllerService
	extends RestServiceBase<
		{},
		IHelpDeskTicketResponse,
		IHelpDeskTicketFilterRequest,
		IHelpDeskTicketCreateRequest,
		IHelpDeskTicketUpdateRequest,
		IHelpDeskTicketPartialUpdateRequest
	>
	implements IGaHelpDeskTicketControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/help-desk-ticket', $http, $q, apiConfigProvider);
	}
}
