import { MembershipActionInterceptor, AuthReceivedAction } from 'membership/membership-service';
import { LogSeverity } from 'utils/logging';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { AccountLoginType } from 'server-api/common/common-enums';

@injectable()
export class WsMembershipActionInterceptor extends MembershipActionInterceptor {
	constructor(@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService) {
		super();
	}

	public beforeLogIn(request: ILoginRequest): Promise<void> {
		return Promise.resolve();
	}

	public beforeExternalLogIn(request: ILoginExternalRequest): Promise<void> {
		return Promise.resolve();
	}

	public beforeRegistration(request: IRegistrationRequest | IShortRegistrationRequest): Promise<void> {
		return Promise.resolve();
	}

	public onAuthReceived(
		accountLoginType: AccountLoginType,
		authReceivedAction: AuthReceivedAction,
		isPersistent: boolean
	): Promise<void> {
		//window.location.reload();
		return Promise.resolve();
	}

	public onExternalAuthReceived() {
		return Promise.resolve();
	}

	private trace(message: string, severity: LogSeverity = LogSeverity.INFO) {
		this.loggingService.log('[WsMembershipActionInterceptor] ' + message, severity);
	}
}
