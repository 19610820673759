import { UtilsNamings } from '../utils-namings';
import { LogSeverity } from 'utils/logging/log-severity';
import { injectable, inject } from 'inversify';
import find from 'lodash/find';
import { Uid } from '../common/utils';
import { clientConfig } from '../config';

declare global {
	interface ICurrentLocaleService {
		hub: IEventHub<CurrentLocaleServiceEvents>;
		setCurrentLocale(locale: string, load?: boolean): void;
		getCurrentLocaleName(): string;
		getCurrentLocaleId(): string;
		getDefaultLocaleId(): string;
		getAvailableLocales(): ILocale[];
		setCurrentRegion(name: string): void;
		getCurrentRegionName(): string;
	}
}
class Keys {
	public static currentLocale = 'currentLocale';
}

export enum CurrentLocaleServiceEvents {
	LocaleChanged = 'locale:changed',
	RegionChanged = 'region:changed'
}

@injectable()
export class CurrentLocaleService implements ICurrentLocaleService {
	public hub: IEventHub<CurrentLocaleServiceEvents>;
	private defaultLocale!: ILocale;
	private region!: string;

	private locale!: ILocale;
	constructor(
		@inject(UtilsNamings.localStorageServiceName) private localStorageService: ILocalStorageService,
		@inject(UtilsNamings.localizationLoadingService) private localizationStorageService: ILocalizationLoadingService,
		@inject(UtilsNamings.staticServiceName) private staticService: IStaticService,
		@inject(UtilsNamings.eventHubServiceName) eventHubService: IEventHubService,
		@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService
	) {
		this.hub = eventHubService.create(Uid.create(), 'CurrentLocaleService');
		this.setCurrentRegion(clientConfig.defaultRegion);
	}
	public setCurrentLocale(locale: string, load = true): void {
		this.log('current locale set to ' + locale);
		const regionData = this.staticService.getRegion(this.region);
		const localeRecord = find(regionData.locales, { name: locale });
		if (!localeRecord) {
			throw new Error(`locale ${locale} doesn't exist for region ${this.region}`);
		}
		this.localStorageService.set(Keys.currentLocale, locale);
		this.locale = localeRecord;
		this.updateDOMForLocale(locale);
		if (load) {
			this.localizationStorageService.ensureLocale(locale).then(() => {
				this.hub.trigger(CurrentLocaleServiceEvents.LocaleChanged, locale);
			});
		}
	}

	private updateDOMForLocale(localeName: string) {
		const html = document.querySelector('html')!;
		html.setAttribute('lang', localeName);
	}

	public getCurrentLocaleName(): string {
		return this.locale.name;
	}

	public getCurrentLocaleId(): string {
		return this.locale.id;
	}

	public getDefaultLocaleId(): string {
		return this.defaultLocale.id;
	}

	public getAvailableLocales(): ILocale[] {
		const regionData = this.staticService.getRegion(this.region);
		if (!regionData) {
			throw new Error(`region data for region ${this.region} not found`);
		}
		return regionData.locales;
	}

	public setCurrentRegion(name: string, initialization = true): void {
		this.log('current region set to ' + name);
		const region = this.staticService.getRegion(name);
		if (!region) {
			throw new Error(`region with name ${name} not found`);
		}
		this.updateDOMForRegion(name);
		this.region = name;
		this.defaultLocale = region.defaultLocale;
		this.hub.trigger(CurrentLocaleServiceEvents.RegionChanged, name);
		let userLocale = this.localStorageService.get<string>(Keys.currentLocale);
		if (!userLocale) {
			userLocale = region.defaultLocale.name;
		}
		this.setCurrentLocale(userLocale, !initialization);
	}

	private updateDOMForRegion(regionName: string) {
		const body = document.querySelector('body')!;
		if (this.region && body.classList.contains('region-' + this.region)) {
			body.classList.remove('region-' + this.region);
		}
		body.setAttribute('region', regionName);
		body.classList.add('region-' + regionName);
	}

	public getCurrentRegionName(): string {
		return this.region;
	}

	private log(message: string): void {
		this.loggingService.log('[CurrentLocaleService] ' + message, LogSeverity.INFO);
	}
}
