import { createDefaultStrategy } from './strategies/default';
import { Store } from 'vuex';
import { Uid } from '../common/utils';

declare global {
	interface ISharedMutationStrategy {
		addEventListener(fn: Function): void;
		share(message: any): void;
	}
}

let sharingInProgress = false;
export function createMutationsSharer({ predicate, strategy, ...rest }: any = {}) {
	const ownUid = Uid.create().toString();
	if ('storageKey' in rest || 'sharingKey' in rest) {
		window.console.warn('Configuration directly on plugin was removed, configure specific strategies if needed');
	}

	if (!Array.isArray(predicate) && typeof predicate !== 'function') {
		throw new Error('Either array of accepted mutations or predicate function must be supplied');
	}

	const predicateFn =
		typeof predicate === 'function'
			? predicate
			: ({ type }: any) => {
					if (predicate.indexOf(type) !== -1) {
						return true;
					}
					return false;
			  };

	const selectedStrategy = strategy || createDefaultStrategy();
	return (store: Store<any>) => {
		store.subscribe((mutation: any, state: any) => {
			if (sharingInProgress || mutation.uid === ownUid) {
				return Promise.resolve(false);
			}
			return Promise.resolve(predicateFn(mutation, state)).then(shouldShare => {
				if (!shouldShare) {
					return;
				}
				// tslint:disable-next-line:no-console
				console.log('sharing mutation:' + JSON.stringify(mutation));
				selectedStrategy.share({ mutation: Object.assign(mutation, { uid: ownUid }) });
			});
		});

		selectedStrategy.addEventListener(({ mutation }: { mutation: any }) => {
			if (mutation.uid === ownUid) {
				return;
			}
			try {
				// tslint:disable-next-line:no-console
				console.log('mutation handled:' + JSON.stringify(mutation));
				sharingInProgress = true;
				store.commit(mutation.type, mutation.payload);
			} finally {
				sharingInProgress = false;
			}
			return 'done';
		});
	};
}
