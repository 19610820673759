import Vue, { PluginObject } from 'vue';
import { UtilsNamings } from 'utils/utils-namings';
import { Modal } from './modal/modal';
import { container } from 'utils/ioc/container';
import { Uid } from 'utils/common/utils';

declare global {
	interface IModalDialogOptions {}

	interface IDialogCallbackParams {
		success: Function;
		error(reason?: any): void;
	}
}

class ModalApi extends Vue {
	public eventHub!: IEventHub;

	constructor() {
		super();
	}

	public created() {
		const eventHubService = container.get<IEventHubService>(UtilsNamings.eventHubServiceName);
		this.eventHub = eventHubService.create(Uid.create(), 'modal-api');

		this.eventHub.bind('opened', (data: any) => {
			this.$emit('modals:opened', data);
		});

		this.eventHub.bind('closed', (data: any) => {
			this.$emit('modals:closed', data);
		});

		this.eventHub.bind('destroyed', (data: any) => {
			this.$emit('modals:destroyed', data);
		});

		this.$on('new', (createOptions: any) => {
			this.open(createOptions);
		});

		this.$on('close', (data: any) => {
			this.close(data);
		});

		this.$on('dismiss', (index: any) => {
			this.dismiss(index || null);
		});
	}

	public open(createOptions = null) {
		this.eventHub.trigger('new', createOptions);
	}

	public close(data = null) {
		this.eventHub.trigger('close', data);
	}

	public dismiss(index = null) {
		this.eventHub.trigger('dismiss', index);
	}
}

export const ModalDialogPlugin: PluginObject<IModalDialogOptions> = {
	install(vue: typeof Vue, options?: IModalDialogOptions) {
		const $modals = (Vue.prototype.$modals = new ModalApi());

		Vue.mixin({
			created(this: Vue) {
				this.$on('modals:new', (createOptions: any) => {
					$modals.eventHub.trigger('new', createOptions);
				});

				this.$on('modals:close', (data: any) => {
					$modals.eventHub.trigger('close', data);
				});

				this.$on('modals:dismiss', (index: number) => {
					$modals.eventHub.trigger('dismiss', index);
				});
			}
		});

		Vue.component('pnz-modal', Modal);
	}
};
