import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaWbTagControllerService
		extends IRestServiceBase<
				{},
				IWbTagResponse,
				IWbTagFilter,
				IWbTagCreateRequest,
				IWbTagUpdateRequest,
				IWbTagPartialUpdateRequest
			> {}
}

@injectable()
export class GaWbTagControllerService
	extends RestServiceBase<
		{},
		IWbTagResponse,
		IWbTagFilter,
		IWbTagCreateRequest,
		IWbTagUpdateRequest,
		IWbTagPartialUpdateRequest
	>
	implements IGaWbTagControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/wb-tag', $http, $q, apiConfigProvider);
	}
}
