import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IWsPaymentSteamControllerService {
		purchase(request: ISteamPurchaseRequest, cancelToken: Promise<void>): IResponsePromise<ISteamPurchaseApiResponse>;
	}
}

@injectable()
export class WsPaymentSteamControllerService extends HttpServiceBase implements IWsPaymentSteamControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public purchase(
		request: ISteamPurchaseRequest,
		cancelToken: Promise<void>
	): IResponsePromise<ISteamPurchaseApiResponse> {
		const path = '/api/xp/payment-steam/purchase';
		const config = this.getMethodConfig('purchase');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<ISteamPurchaseApiResponse>>(url, request, config.requestConfig, cancelToken);
	}
}
