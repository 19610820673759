import { UtilsNamings } from 'utils/utils-namings';
import { injectable, multiInject, inject } from 'inversify';
import { LogSeverity } from 'utils/logging/log-severity';
import { Uid } from '../common/utils';

@injectable()
export class EventHub implements IEventHub {
	private events: IMap<Array<(...args: any[]) => void>>;
	private asyncEvents: IMap<Array<(...args: any[]) => Promise<void>>>;

	constructor(
		@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService,
		private id: Uid,
		private name: string | null = null
	) {
		this.events = {};
		this.asyncEvents = {};
	}

	public bind(eventName: string, callback: () => void): () => void {
		this.trace('bind:' + eventName);
		this.events[eventName] = this.events[eventName] || [];
		this.events[eventName].push(callback);
		return () => {
			this.unbind(eventName, callback);
		};
	}

	public bindAsync(eventName: string, callback: () => Promise<void>): () => void {
		this.asyncEvents[eventName] = this.asyncEvents[eventName] || [];
		this.asyncEvents[eventName].push(callback);
		return () => {
			this.unbind(eventName, callback);
		};
	}

	public unbind(eventName: string, callback: () => void) {
		this.trace('unbind:' + eventName);
		if (!this.events[eventName]) {
			return;
		}
		const index = this.events[eventName].indexOf(callback);
		this.events[eventName].splice(index, 1);
	}

	public unbindAsync(eventName: string, callback: () => Promise<any>) {
		if (!this.asyncEvents[eventName]) {
			return;
		}
		const index = this.asyncEvents[eventName].indexOf(callback);
		this.asyncEvents[eventName].splice(index, 1);
	}

	public close() {
		for (const eventName of Object.keys(this.events)) {
			delete this.events[eventName];
		}
	}

	public trigger(eventName: string, ...args: any[]) {
		this.trace(`trigger called. event:'${eventName}' args:${args}`);
		if (eventName in this.events === false) {
			this.trace(`trigger called. event:'${eventName}'. no listeners found`);
			return;
		}
		const start = new Date().getTime();
		for (let i = 0; i < this.events[eventName].length; i++) {
			const fn = this.events[eventName][i];
			fn.apply(this, Array.prototype.slice.call(arguments, 1));
		}
		const elapsed = new Date().getTime() - start;
		this.trace(`trigger event:'${eventName}' completed in ${elapsed}. args:${args}`);
	}

	public triggerAsync(eventName: string, ...args: any[]): Promise<void> {
		this.trace(`trigger called. event:'${eventName}' args:${args}`);
		if (eventName in this.asyncEvents === false) {
			this.trace(`trigger called. event:'${eventName}'. no listeners found`);
			return Promise.resolve();
		}
		const start = new Date().getTime();
		const promises: Array<Promise<void>> = [];
		for (let i = 0; i < this.asyncEvents[eventName].length; i++) {
			const fn = this.asyncEvents[eventName][i];
			const promise = fn.apply(this, Array.prototype.slice.call(arguments, 1)) as Promise<void>;
			promises.push(promise);
		}
		return Promise.all(promises).then(() => {
			const elapsed = new Date().getTime() - start;
			this.trace(`trigger event:'${eventName}' completed in ${elapsed}. args:${args}`);
		});
	}

	private trace(message: string, severity: LogSeverity = LogSeverity.INFO) {
		this.loggingService.log(`[EVENT_HUB] [${this.id}] ` + (this.name ? `[${this.name}] ` : '') + message, severity);
	}

	private stringify(args: any[]): string {
		let result = '';
		for (const arg of args) {
			let strValue = '';
			try {
				strValue = JSON.stringify(arg);
			} catch (e) {
				if (arg === null) {
					strValue = 'null';
				} else if (arg === undefined) {
					strValue = 'undefined';
				} else if (arg.constructor) {
					strValue = arg.constructor.name;
				} else {
					strValue = arg.toString();
				}
			}
			if (result === '') {
				result = strValue;
			} else {
				result = result + ', ' + strValue;
			}
		}
		return '[' + result + ']';
	}
}
