import { Component, Vue } from 'vue-property-decorator';
import { mergeData } from 'vue-functional-data-merge';
import { CreateElement, VNode } from 'vue';

@Component({
	props: {}
})
export class FormRow extends Vue {
	public render(createElement: CreateElement): VNode {
		return createElement(
			'div',
			mergeData(this.$data, {
				staticClass: 'form-row'
			}),
			this.$slots.default
		);
	}
}
