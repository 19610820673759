import { Component, Prop, Vue } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';

import { container } from 'utils/ioc/container';
import { MembershipNamings } from 'membership/membership-namings';
import WithRender from './short-registration-form.html';
import { LoginRouteNames } from 'membership/auth/login/routes/login-routes-namings';
import { LicenseAgreementRouteNames } from 'membership/auth/license-agreement/license-agreement-route-namings';
import { RegistrationRouteNames } from '../../routes/registration-routes-namings';

require('./short-registration-form.scss');

class Model {
	public email!: string;
	public password!: string;
	public passwordConfirmation!: string;
	public redirectUrl?: string | null;
	public promoCode?: string;
}

@WithRender
@Component
export class ShortRegistrationForm extends BootstrapFormContainer<Model> {
	public get rules() {
		return {
			email: ['required'],
			password: ['required', 'min:6'],
			passwordConfirmation: ['required', 'min:6', 'pnz-password-confirmation:' + this.model.password]
		};
	}
	private membershipService: IMembershipService;

	public model: Model;

	constructor() {
		super();
		this.model = new Model();
		this.membershipService = container.get<IMembershipService>(MembershipNamings.membershipServiceName);
	}

	public processSubmit(): Promise<any> {
		this.model.redirectUrl = this.$route.query.redirectUrl as string;
		return this.membershipService.registerShort(this.model);
	}

	public onPasswordRecoveryClick() {
		this.$router.push({ name: LoginRouteNames.Login, query: this.$route.query, params: this.$route.params });
	}

	public licenseLinkClick() {
		if (this.$route.name === RegistrationRouteNames.LandingRegistration) {
			this.$router.push({
				name: LicenseAgreementRouteNames.LandingLicenseAgreement,
				query: this.$route.query,
				params: this.$route.params
			});
		} else {
			this.$router.push({
				name: LicenseAgreementRouteNames.LicenseAgreement,
				query: this.$route.query,
				params: this.$route.params
			});
		}
	}
}
