import { InterceptorBase } from 'utils/http/interceptors/interceptor-base';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { inject, injectable } from 'inversify';
import { UtilsNamings } from 'utils/utils-namings';
import { LogSeverity } from 'utils/logging';
import { container } from '../../ioc/container';
import { Store } from 'vuex';
import { SystemModule } from '../../stores/system-module';
import { getModule } from 'vuex-module-decorators';
import { UserSettingsModule, DateTimeFormat } from '../../stores/user-settings-module';
import { getCurrentTimezoneOffset } from '../../common/utils';
import {
	AntiForgeryTokenHeaderName,
	clientVersion,
	ClientTimeZoneOffset,
	XTokenHeaderName,
	XTokenExpirationHeaderName,
	HttpServerHeaderName,
	ClientVersionHeaderName,
	CorrelationIdHeaderName
} from '../consts';
import { clientConfig } from '../../config';
import { Uid } from '../../common';

@injectable()
export class HttpInterceptor extends InterceptorBase {
	constructor(
		@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService,
		@inject(UtilsNamings.authStateServiceName) private authStateService: IAuthStateService,
		@inject(UtilsNamings.currentLocaleServiceName) private currentLocaleService: ICurrentLocaleService,
		@inject(UtilsNamings.appStoreName) private store: Store<any>
	) {
		super();
	}

	private getDateTimeZoneOffsetHeader(): number | null {
		const userSettingsModule = getModule(UserSettingsModule, this.store);
		switch (userSettingsModule.dateTimeFormat) {
			case DateTimeFormat.Local: {
				return getCurrentTimezoneOffset();
			}
			case DateTimeFormat.Server: {
				return clientConfig.serverTimeZoneOffset;
			}
			default: {
				return null;
			}
		}
	}

	public request = (request: AxiosRequestConfig): Promise<any> | AxiosRequestConfig => {
		let token: string | null = null;
		if ((<IPnzApiRequestMarker>(<any>request)).pnzApiRequest === true) {
			token = this.authStateService.getAuthToken();
			if (token) {
				request.headers['Authorization'] = 'Bearer ' + token;
			}
		}
		const systemModule = getModule(SystemModule, this.store);
		request.headers[(clientConfig.headerPrefix + CorrelationIdHeaderName).toLowerCase()] = Uid.create();
		request.headers[(clientConfig.headerPrefix + AntiForgeryTokenHeaderName).toLowerCase()] =
			systemModule.antiForgeryToken;
		request.headers[(clientConfig.headerPrefix + ClientVersionHeaderName).toLowerCase()] = clientVersion;
		const clientTimeZoneOffsetHeader = this.getDateTimeZoneOffsetHeader();
		if (clientTimeZoneOffsetHeader) {
			request.headers[(clientConfig.headerPrefix + ClientTimeZoneOffset).toLowerCase()] = clientTimeZoneOffsetHeader;
		}
		const locale = this.currentLocaleService.getCurrentLocaleName();
		if (locale) {
			request.headers['Accept-Language'] = locale;
		}
		if (!(<any>request).isTemplateRequest) {
			this.log(
				`intercepting request. url:${request.url}. auth-token:'${
					token == null ? '+' : '-'
				}'. locale:'${locale}'. cl-ver:'${clientVersion}'. data:${JSON.stringify(request.data, null, 2)}`
			);
		}
		return request;
	};

	public requestError: ((error: AxiosError) => Promise<any> | AxiosRequestConfig) | null = null;

	public response = (response: AxiosResponse<IServerResponse>): Promise<any> => {
		if (!response) {
			return Promise.reject('no response');
		}
		if (!response.config) {
			return Promise.reject('no response config');
		}
		if ((<IPnzRequestConfig>response.config).isTemplateRequest) {
			return Promise.resolve(response);
		}
		if ((<IPnzApiRequestMarker>(<any>response.config)).pnzApiRequest === true && typeof response.data !== 'object') {
			return Promise.reject(response);
		}
		if (!response.config.url || response.config.url.indexOf('/_') === 0) {
			return Promise.resolve(response);
		}

		const token = response.headers[(clientConfig.headerPrefix + XTokenHeaderName).toLowerCase()];
		const tokenExpiration = response.headers[(clientConfig.headerPrefix + XTokenExpirationHeaderName).toLowerCase()];
		this.log(
			`success response reported with status: ${response.status}. url: ${response.config.url}. auth-token:'${
				token ? '+' : '-'
			}'. auth-token-exp:'${tokenExpiration}'. data: ${JSON.stringify(response.data, null, 2)}`
		);

		if (tokenExpiration === '-1') {
			this.log(`clearing auth token due to response from ${response.config.url}`);
			this.authStateService.setAuthToken(null);
		} else if (token) {
			this.authStateService.setAuthToken(token);
		}
		const antiForgeryTokenHeader =
			response.headers[(clientConfig.headerPrefix + AntiForgeryTokenHeaderName).toLowerCase()];

		if (antiForgeryTokenHeader) {
			this.log(`update XSRF token to:'${antiForgeryTokenHeader}`);
			const systemModule = getModule(SystemModule, this.store);
			return systemModule.setAntiForgeryToken(antiForgeryTokenHeader).then(() => {
				return this.processResponseInternal(response);
			});
		}
		return this.processResponseInternal(response);
	};

	private processResponseInternal(response: AxiosResponse<IServerResponse>): Promise<any> {
		if (response.headers[(clientConfig.headerPrefix + HttpServerHeaderName).toLowerCase()] === '1') {
			const serverResponse = (response.data as any) as IServerResponse;
			if (serverResponse) {
				if (serverResponse.error) {
					this.log(
						`response error: ${JSON.stringify(serverResponse.error)}. config: ${JSON.stringify(
							response.config
						).substring(0, 100)}`,
						LogSeverity.WARNING
					);
					if ((<IPnzRequestConfig>response.config).skipErrorHandling !== true) {
						if (container.isBound(UtilsNamings.httpErrorHandlerName)) {
							const handler = container.get<IHttpErrorHandler>(UtilsNamings.httpErrorHandlerName);
							const result = handler.handleErrorServerResponse(serverResponse, response);
							if (result != null) {
								return result;
							}
						}
					}
					return Promise.reject(serverResponse.error);
				}
				if (serverResponse.redirectUrl) {
					this.log(`redirecting to: ${serverResponse.redirectUrl}`);
					window.location.href = serverResponse.redirectUrl;
					return new Promise(() => {}); //TODO use state router
				}
				if (!serverResponse.payload) {
					throw Error('Error reading response payload:' + JSON.stringify(response.data, null, 2));
				}
				(<any>response.data) = serverResponse.payload;
				return Promise.resolve(response);
			}
		}
		return Promise.resolve(response);
	}

	public responseError = (error: AxiosError): Promise<any> => {
		if (error.response) {
			if (container.isBound(UtilsNamings.httpErrorHandlerName)) {
				const handler = container.get<IHttpErrorHandler>(UtilsNamings.httpErrorHandlerName);
				const result = handler.handleHttpError({ status: error.response.status });
				if (result != null) {
					return result;
				}
			}
		}
		return Promise.reject(error);
	};

	private log = (message: string, logSeverity: LogSeverity = LogSeverity.INFO) => {
		this.loggingService.log(`[HttpInterceptor] ${message}`, logSeverity);
	};
}
