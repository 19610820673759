import { Component, Prop } from 'vue-property-decorator';
import WithRender from './form-group-with-error.html';

import './form-group-with-error.scss';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import { BootstrapColsPropOptions } from 'ui-controls/bootstrap/grid';
import { PnzBootstrapForm } from '../bootstrap-form/bootstrap-form';
import { FieldElementBase } from 'ui-controls-core/forms/field-element-base';
import Vue from 'vue';
import { FormSelectPlugin } from 'bootstrap-vue';

Vue.use(FormSelectPlugin);

@WithRender
@Component({
	inject: {
		$validator: '$validator'
	}
})
export class FromGroupWithErrorBlock extends FieldElementBase<
	BootstrapFormContainer,
	PnzBootstrapForm,
	VeeValidateInputRule
> {
	@Prop(new BootstrapColsPropOptions())
	public colSm: any;

	@Prop(new BootstrapColsPropOptions())
	public offsetSm: any;

	public get rules(): string {
		const rules = this.container.rules as { [key: string]: VeeValidateInputRule[] };
		return (rules[this.field] || []).join('|');
	}

	public get isErrorBlockVisible() {
		return this.container.isErrorBlockVisible(this.field);
	}
}
