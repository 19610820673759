import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';
import WithRender from './close-icon.html';

import './close-icon.scss';
import { CreateElement } from 'vue';

@WithRender
@Component
export class CloseIcon extends UIComponent {
	constructor() {
		super();
	}

	public render(h: CreateElement) {
		return h(
			'svg',
			{
				attrs: {
					width: '12px',
					height: '12px',
					viewBox: '0 0 12 12',
					xmlSpace: 'preserve'
				}
			},
			[
				h('line', {
					attrs: {
						x1: 1,
						y1: 11,
						x2: 11,
						y2: 1
					},
					style: {
						strokeLinecap: 'round',
						strokeLinejoin: 'round'
					}
				}),
				h('line', {
					attrs: {
						x1: 1,
						y1: 1,
						x2: 11,
						y2: 11
					},
					style: {
						strokeLinecap: 'round',
						strokeLinejoin: 'round'
					}
				})
			]
		);
	}
}
