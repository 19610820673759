import { Prop, Component } from 'vue-property-decorator';

import './bootstrap-form-container.scss';
import { FormContainer } from 'ui-controls-core/forms/form-container-base';
import { PnzBootstrapForm } from './bootstrap-form/bootstrap-form';

declare global {
	type VeeValidateInputRule = 'required' | 'min' | string;
}

//@ts-ignore
@Component({
	$_veeValidate: {
		validator: 'new' // give me my own validator scope.
	},
	provide(this: BootstrapFormContainer) {
		return {
			$validator: this.$validator
		};
	}
})
export abstract class BootstrapFormContainer<TModel extends object = any> extends FormContainer<
	PnzBootstrapForm,
	VeeValidateInputRule,
	TModel
> {
	protected get formComponentName(): string {
		return 'PnzBootstrapForm';
	}
	public reset() {
		if (this.$validator) {
			this.$validator.errors.clear();
		}
		this.trace('form reset');
	}

	public isValid(): Promise<boolean> {
		if (!this.$validator.errors || !this.$validator.errors.any()) {
			return Promise.resolve(true);
		}
		this.trace(`form has errors:${JSON.stringify(this.errors, null, 2)}`);
		return Promise.resolve(false);
	}

	public clearErrors(): void {
		this.$validator.errors.clear();
	}

	public validate(): Promise<boolean> {
		return this.$validator.validateAll().then(() => {
			return this.isValid();
		});
	}

	protected isFieldExits(fieldName: string): boolean {
		return !this.$validator.fields || !!this.$validator.fields.find({ name: fieldName });
	}

	public get errors(): any {
		return this.$validator.errors;
	}

	protected setFieldValidity(fieldName: string, isValid: boolean, error: string) {
		this.$validator.errors.remove(fieldName);
		this.$validator.errors.add({ field: fieldName, msg: error });
		const field = this.$validator.fields.find({ name: fieldName });
		if (!field) {
			throw new Error(`field '${fieldName}' not found`);
		}
		field.flags.invalid = !isValid;
		field.flags.valid = isValid;
	}

	public isFieldInvalid(fieldName: string): boolean {
		if (!this.$validator.fields) {
			return false;
		}
		const field = this.$validator.fields.find({ name: fieldName });
		if (!field) {
			return false;
		}
		return field.flags.invalid === true && (!field.flags.pristine || this.submitted);
	}

	public isFieldValid(fieldName: string): boolean {
		if (!this.$validator.fields) {
			return false;
		}
		const field = this.$validator.fields.find({ name: fieldName });
		if (!field) {
			return false;
		}
		return field.flags.valid === true && !field.flags.pristine && !this.submitted;
	}

	public isErrorBlockVisible(fieldName: string): boolean {
		if (!this.$validator.fields) {
			return false;
		}
		const field = this.$validator.fields.find({ name: fieldName });
		if (!field) {
			if (!this.submitted) {
				return false;
			}
			throw new Error(`field with name '${fieldName}' is missing`);
		}
		return (this.submitted || !field.flags.pristine) && field.flags.invalid === true;
	}

	public getFieldError(fieldName: string): string | null {
		return this.$validator.errors.first(fieldName);
	}
}
