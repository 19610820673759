import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { Validator } from 'NodeModules/vee-validate/dist/vee-validate.minimal.esm.js';
//@ts-ignore
import { required, min, max, confirmed, length, decimal, min_value } from 'NodeModules/vee-validate/dist/rules.esm.js';
import { container } from '../utils/ioc/container';
import { UtilsNamings } from '../utils/utils-namings';
export function registerValidators() {
	Validator.extend('required', required);
	Validator.extend('min', min);
	Validator.extend('max', max);
	Validator.extend('length', length);
	Validator.extend('decimal', decimal);
	Validator.extend('min_value', min_value);
	Validator.extend('confirmed', confirmed, { paramNames: confirmed.paramNames });

	Validator.extend('pnz-password-confirmation', {
		paramNames: ['targetValue'],
		validate(value: string, args: any): boolean {
			return String(value) === String(args.targetValue);
		},
		getMessage(): string {
			const localizationService = container.get<ILocalizationService>(UtilsNamings.localizationServiceName);
			return localizationService.localize('messagePasswordsNotMatch');
		}
	});

	Vue.use(VeeValidate, { inject: false });
}
