<pnz-panel id="auth-wizard" class="pzn-auth-wizard" :class="transitionDirection">
	<template slot="panel-toolbar-left">
		<pnz-back-button v-pnz-visible="isBackButtonVisible" v-on:click.prevent="onBackClick"></pnz-back-button>
	</template>
	<template slot="panel-header">
		{{ title }}
	</template>
	<template slot="panel-toolbar-right">
		<pnz-close-button v-pnz-visible="closable" v-on:click.prevent="onCloseClick"></pnz-close-button>
	</template>
	<template slot="panel-body">
		<div class="pzn-auth-wizard-container d-flex flex-row align-items-center justify-content-around">
			<transition name="fade-transform" mode="out-in" v-on:after-enter="transitionAfterEnter">
				<keep-alive>
					<router-view :key="key"></router-view>
				</keep-alive>
			</transition>
		</div>
	</template>
</pnz-panel>
