import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaAdTargetInstanceControllerService
		extends IRestServiceBase<
				{},
				IAdTargetInstanceResponse,
				IAdTargetInstanceFilterRequest,
				IAdTargetInstanceCreateRequest,
				IAdTargetInstanceUpdateRequest,
				IAdTargetInstancePartialUpdateRequest
			> {}
}

@injectable()
export class GaAdTargetInstanceControllerService
	extends RestServiceBase<
		{},
		IAdTargetInstanceResponse,
		IAdTargetInstanceFilterRequest,
		IAdTargetInstanceCreateRequest,
		IAdTargetInstanceUpdateRequest,
		IAdTargetInstancePartialUpdateRequest
	>
	implements IGaAdTargetInstanceControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/ad-target-instance', $http, $q, apiConfigProvider);
	}
}
