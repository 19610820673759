import { Component, Prop, Vue } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import { container } from 'utils/ioc/container';
import { MembershipNamings } from 'membership/membership-namings';
import { PasswordRecoveryRouteNames } from 'membership/auth/password-recovery/routes/password-recovery-route-namings';
import { RegistrationRouteNames } from 'membership/auth/registration/routes/registration-routes-namings';

import WithRender from './login-form.html';
import { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import { MembershipModule } from 'membership/stores/membership-module';

declare global {
	interface IPersistentLoginService {
		getPersistentLogin(): Promise<string | null>;

		loginWithSavedCredentials(): Promise<void>;

		logout(): Promise<void>;
	}
}

class Model {
	public login: string | null = null;
	public password: string | null = null;
	public rememberMe: boolean = false;
}

export class LoginFormEvents {
	public static PasswordRecoveryClick = 'password-recovery-click';
}

const beforeRouteEnter = (to: Route, from: Route, next: Function) => {
	if (!container.isBound(MembershipNamings.persistentLoginServiceName)) {
		next();
	} else {
		const persistentLoginService = container.get<IPersistentLoginService>(MembershipNamings.persistentLoginServiceName);
		persistentLoginService.getPersistentLogin().then((persistentLogin: string | null) => {
			next((view: LoginForm) => {
				if (persistentLogin == null) {
					return;
				}
				view.isPersistent = true;
				view.model = {
					login: persistentLogin,
					password: '******',
					rememberMe: true
				};
			});
		});
	}
};

@WithRender
@Component({
	beforeRouteEnter: beforeRouteEnter
})
export class LoginForm extends BootstrapFormContainer<Model> {
	private membershipService: IMembershipService;
	private persistentLoginService: IPersistentLoginService | null;
	public isPersistent!: boolean;
	private wasReset!: boolean;
	private redirectUrl!: string;

	private closeCallback!: (reason?: any) => {} | null;
	private dismissCallback!: (reason?: any) => {} | null;
	private passwordRecoveryClickCallback!: () => {};
	private registrationClickCallback!: () => {};

	public model: Model;

	public get rules() {
		return {
			login: ['required'],
			password: [],
			rememberMe: []
		};
	}

	constructor() {
		super();
		this.model = new Model();
		this.membershipService = this.get<IMembershipService>(MembershipNamings.membershipServiceName);
		this.persistentLoginService = this.getOptional<IPersistentLoginService>(
			MembershipNamings.persistentLoginServiceName
		);
	}

	public onPasswordRecoveryClick() {
		this.$router.push({
			name: PasswordRecoveryRouteNames.SelectPasswordRecovery,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public onRegistrationClick() {
		this.$router.push({
			name: RegistrationRouteNames.ShortRegistration,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public get registrationEnabled() {
		const config = getModule(MembershipModule, this.$store).membershipConfig;
		return config == null || config.registrationEnabled;
	}

	public processSubmit(): Promise<any> {
		if (!this.model.login) {
			return Promise.reject();
		}
		this.isLoading = true;
		if (this.isPersistent && this.persistentLoginService) {
			return this.persistentLoginService
				.loginWithSavedCredentials()
				.then(() => {
					this.closeCallback();
				})
				.catch((error: IResponseError) => {
					this.formError = this.getErrorMessage(error);
					return Promise.reject(error);
				})
				.finally(() => {
					this.isLoading = false;
				});
		} else {
			const redirectUrl = this.redirectUrl || (this.$route.query.redirectUrl as string);
			return this.membershipService
				.login(this.model.login, this.model.password, this.model.rememberMe, redirectUrl)
				.then((data: any) => {
					return data;
				})
				.finally(() => {
					this.isLoading = false;
				});
		}
	}

	public success(param: any) {
		if (this.closeCallback) {
			this.closeCallback();
		}
	}

	public cancel() {
		if (this.dismissCallback) {
			this.dismissCallback('cancel');
		}
	}

	public onLoginChanged() {
		if (this.isPersistent) {
			this.isPersistent = false;
			this.model.password = null;
		}
	}

	public onPasswordChanged() {
		if (this.isPersistent) {
			this.isPersistent = false;
			this.model.login = null;
			this.model.password = null;
		}
	}

	public onRememberMeChanged() {
		if (!this.model.rememberMe && this.isPersistent) {
			this.isPersistent = false;
			this.model.password = null;
		}
		if (this.persistentLoginService) {
			this.persistentLoginService.logout();
		}
	}
}
