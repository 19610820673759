import { UIComponent } from '../ui-component';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormContainer, FormContainerProvideKey, FormContainerEventNames } from './form-container-base';

export const FormIdProvideKey = 'form-id';

//@ts-ignore
@Component({
	provide(this: FormBase) {
		return {
			[FormIdProvideKey]: this.id
		};
	},
	inject: {
		container: FormContainerProvideKey
	}
})
export abstract class FormBase extends UIComponent {
	public formError: string | null = null;

	@Prop({ type: String, required: true })
	public id!: string;

	public container!: FormContainer<FormBase, any>;

	public mounted() {
		this.container.eventHub.bind(FormContainerEventNames.ErrorChange, () => {
			this.formError = this.container.formError;
		});
		this.$on('submit', () => {
			this.container.submit();
		});
		this.$on('reset', () => {
			this.container.reset();
		});
	}
}
