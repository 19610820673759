import Vue from 'vue';
import Vuex from 'vuex';
import { Plugin, Store } from 'vuex';
import { Uid } from '../common/utils';
import { createMutationsSharer } from 'utils/shared-mutations/shared-mutations';

Vue.use(Vuex);

let store: Store<any> & { uid?: string } | null = null;
export function createStore(plugins: Array<Plugin<any>> = []): Store<any> {
	if (store) {
		// tslint:disable-next-line:no-console
		console.log('reused store uid:' + store.uid);
		return store;
	}

	plugins = plugins || [];

	const mutationsSharer = createMutationsSharer({ predicate: ['system/setAntiForgeryToken'] });
	plugins.push(mutationsSharer);

	store = new Vuex.Store({
		strict: DEVELOPMENT,
		plugins: plugins
	});
	const uid = Uid.create();
	// tslint:disable-next-line:no-console
	console.log('created store uid:' + uid);
	store.uid = uid.toString();
	return store;
}
