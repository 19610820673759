import { configureRoutes } from '@/ws-configure-routes';
import { MembershipModule } from 'membership/membership-module';
import { ServerApi } from 'server-api/server-api-module';
import { UIControlsModule } from 'ui-controls/ui-controls-module';
import { Application } from 'utils/common/modules';
import { UtilsModule } from 'utils/utils-module';

import { MembershipNamings } from 'membership/membership-namings';
import { ServerApiNamings } from 'server-api/server-api-namings';
import { UIControlsNamings } from 'ui-controls/ui-controls-namings';
import { UtilsNamings } from 'utils/utils-namings';

import { container } from 'utils/ioc/container';
import VueI18n from 'vue-i18n';
import Router from 'vue-router';
import { LsAccountControllerService } from 'server-api/ls/ls-account-controller-service';
import { HttpReplayInterceptor } from 'utils/http/interceptors/http-replay-interceptor';
import { HttpInterceptor } from 'utils/http/interceptors/http-interceptor';
import { InterceptorBase } from 'utils/http/interceptors/interceptor-base';
import { WsNamings } from './ws-namings';
import { configureIoC } from './ws-configure-ioc';
import { Container } from 'inversify';
import { initControls } from './init-controls';
import { Store } from 'vuex';
import { configureStores } from './ws-configure-stores';

export class WebSiteApplication extends Application {
	protected context: IAppContext;
	public moduleName = 'pnz-tower-website-application';

	constructor(
		private i18n: VueI18n,
		private router: Router,
		private iocContainer: Container,
		private config: IClientConfig | undefined,
		private store: Store<any>
	) {
		super();
		this.context = { i18n, router, iocContainer, moduleContainer: this.moduleContainer, store: store };
	}

	public registerSubModules(context: IAppContext): Promise<void> {
		context.moduleContainer.register(UtilsNamings.moduleName, UtilsModule);
		context.moduleContainer.register(UIControlsNamings.moduleName, UIControlsModule);
		context.moduleContainer.register(MembershipNamings.moduleName, MembershipModule);
		context.moduleContainer.register(ServerApiNamings.moduleName, ServerApi);
		return Promise.resolve();
	}
	public configureIoC(context: IAppContext): Promise<void> {
		configureIoC();
		return Promise.resolve();
	}
	public configureRoutes(context: IAppContext): Promise<void> {
		configureRoutes(this.router);
		configureStores(this.store);
		return Promise.resolve();
	}
	public registerUIComponents(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}

	protected getInterceptors(context: IAppContext): Array<new (...args: any[]) => InterceptorBase> {
		return [HttpReplayInterceptor, HttpInterceptor];
	}
	public configureModule(context: IAppContext): Promise<void> {
		if (!DEVELOPMENT) {
			const apiConfigProvider = container.get<IApiConfigProvider>(UtilsNamings.apiConfigProviderName);
			apiConfigProvider.configureController(LsAccountControllerService, controllerConfigProvider => {
				controllerConfigProvider.requestConfig.retryCount = 10;
			});
		}
		return Promise.resolve();
	}
	public beforeRun(context: IAppContext): Promise<void> {
		const membershipService = container.get<IMembershipService>(MembershipNamings.membershipServiceName);
		const membershipActionInterceptor = container.get<IMembershipActionInterceptor>(
			WsNamings.wsMembershipActionInterceptorName
		);

		membershipService.addActionInterceptor(membershipActionInterceptor);

		const currentLocaleService = container.get<ICurrentLocaleService>(UtilsNamings.currentLocaleServiceName);
		currentLocaleService.setCurrentRegion('ru');
		currentLocaleService.setCurrentLocale(this.config!.language!, false);

		initControls(context);
		return Promise.all([]).then(() => {});
	}

	public afterRun(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}

	protected beforeStop(): void {}
}
