import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface ILsLauncherControllerService {
		checkRepairing(): IResponsePromise<void | IRepairingContentData>;

		getLauncherState(
			request: ILauncherStateRequest
		): IResponsePromise<IExternalAuthLauncherStateData | IPanzarLauncherStateData>;

		ping(): IResponsePromise<void>;

		versionCheck(request: IVersionCheckRequest): IResponsePromise<IVersionCheckResponse>;
	}
}

@injectable()
export class LsLauncherControllerService extends HttpServiceBase implements ILsLauncherControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public checkRepairing(): IResponsePromise<void | IRepairingContentData> {
		const path = '/api/ls/launcher/checkrepairing/';
		const config = this.getMethodConfig('checkRepairing');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void | IRepairingContentData>>(url, {}, config.requestConfig);
	}

	public getLauncherState(
		request: ILauncherStateRequest
	): IResponsePromise<IExternalAuthLauncherStateData | IPanzarLauncherStateData> {
		const path = '/api/ls/launcher/getlauncherstate/';
		const config = this.getMethodConfig('getLauncherState');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IExternalAuthLauncherStateData | IPanzarLauncherStateData>>(
			url,
			request,
			config.requestConfig
		);
	}

	public ping(): IResponsePromise<void> {
		const path = '/api/ls/launcher/ping/';
		const config = this.getMethodConfig('ping');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, {}, config.requestConfig);
	}

	public versionCheck(request: IVersionCheckRequest): IResponsePromise<IVersionCheckResponse> {
		const path = '/api/ls/launcher/versioncheck/';
		const config = this.getMethodConfig('versionCheck');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IVersionCheckResponse>>(url, request, config.requestConfig);
	}
}
