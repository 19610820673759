import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaWbMediaControllerService
		extends IRestServiceBase<
				{},
				IWbMediaResponse,
				IWbMediaFilterRequest,
				IWbMediaCreateRequest,
				IWbMediaModificationRequest,
				IWbMediaModificationRequest
			> {}
}

@injectable()
export class GaWbMediaControllerService
	extends RestServiceBase<
		{},
		IWbMediaResponse,
		IWbMediaFilterRequest,
		IWbMediaCreateRequest,
		IWbMediaModificationRequest,
		IWbMediaModificationRequest
	>
	implements IGaWbMediaControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/wb-media', $http, $q, apiConfigProvider);
	}
}
