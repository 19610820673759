<div class="card pnz-panel">
	<div class="card-header">
		<div class="d-flex flex-row justify-content-between">
			<div class="pnz-panel-toolbar d-flex flex-column align-items-baseline" role="toolbar" aria-label="">
				<slot name="panel-toolbar-left">
				</slot>
			</div>
			<h4 class="card-title d-flex align-items-center">
        <slot name="panel-header">
          <pnz-empty-row/>
        </slot>
      </h4>
			<div class="pnz-panel-toolbar d-flex align-items-center" role="toolbar" aria-label="">
				<slot name="panel-toolbar-right">
				</slot>
			</div>
		</div>
	</div>
	<div class="card-body">
		<slot name="panel-body">
			<pnz-empty-row />
		</slot>
	</div>
	<div class="card-footer">
		<pnz-empty-row />
	</div>
</div>
