import { WebSiteApplication } from './app';
import { container } from 'utils/ioc/container';
import Vue from 'vue';
import Vuei18n from 'vue-i18n';
import './polyfills';
import './assets/static';
import { WsRouteNamings } from './routes/ws-route-namings';
import { App } from './views/app/app';

import './styles/styles.ts';
import './bootstrap-dependencies.ts';
import { UtilsNamings } from 'utils/utils-namings';
import { createRouter } from 'utils/routing/router';
import { createStore } from 'utils/stores/store';
import { preloadImagesFromAssets } from 'utils/prefetching';
import { createMutationsSharer } from 'utils/shared-mutations/shared-mutations';

if (TRACE_ENABLED) {
	require('@/tracer');
}
Vue.config.productionTip = !DEVELOPMENT;

import assets from '@/../assets.json';
const prefetcher = preloadImagesFromAssets(assets as any);

const config = window.CLIENT_CONFIG;

const fallbackLanguage = 'en';

const i18n = new Vuei18n({
	locale: config ? config.language || fallbackLanguage : fallbackLanguage,
	fallbackLocale: config ? config.language || fallbackLanguage : fallbackLanguage
});

const MutationsSharer = createMutationsSharer({ predicate: ['system/setAntiForgeryToken'] });

const store = createStore([MutationsSharer]);
const router = createRouter();

const appElementId = 'app';

const application = new WebSiteApplication(i18n, router, container, config, store);
let root: Vue | null = null;
const element = document.getElementById(appElementId);
if (!element) {
	throw new Error('App element not found');
}
application
	.configure()
	.then(() => {
		root = new Vue({
			i18n,
			router,
			store,
			render: h => h(App)
		});
		container.bind(UtilsNamings.appRootElementName).toConstantValue(root);
		return application.run().then(() => {
			root!.$mount(element);
		});
	})
	.catch(error => {
		// tslint:disable-next-line:no-debugger
		debugger;
		router.push({ name: WsRouteNamings.Error });
		throw error;
	});

if (module.hot) {
	module.hot.dispose(() => {
		container.unbindAll();
		application.stop();
		if (root) {
			root.$destroy();
		}
	});
	module.hot.accept(() => {});
}
