<pnz-form id="login-form">
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="login" :label="'referralInputEmail' | localize" @keydown="onLoginChanged" :form-model="model" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="password" :label="'password' | localize" @keydown="onPasswordChanged" type="password" :form-model="model" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group col-sm="6" :with-top-margin="false">
			<pnz-checkbox name="rememberMe" :label-text="'rememberMe' | localize" v-model="model.rememberMe" @change="onRememberMeChanged"></pnz-checkbox>
		</pnz-form-group>
		<pnz-form-group col-sm="6" class="text-right" :with-top-margin="false">
			<a href="#" v-on:click.prevent="onPasswordRecoveryClick" class="pnz-link pnz-recover-link js-recover-password">{{"remindpassword" | localize}}</a>
			<span>&nbsp;&nbsp;</span>
			<a href="#" v-if="registrationEnabled" v-on:click.prevent="onRegistrationClick" class="pnz-link pnz-recover-link js-registration-password">{{"registration" | localize}}</a>
		</pnz-form-group>
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group :with-top-margin="false">
			<pnz-button type="submit" :is-loading="isLoading">{{ 'enter' | localize }}</pnz-button>
		</pnz-form-group>
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
</pnz-form>
