import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaWbNewsMessageControllerService
		extends IRestServiceBase<
				{},
				IWbNewsMessageResponse,
				IWbNewsMessageFilterRequest,
				IWbNewsMessageCreateRequest,
				IWbNewsMessageUpdateRequest,
				IWbNewsMessagePartialUpdateRequest
			> {}
}

@injectable()
export class GaWbNewsMessageControllerService
	extends RestServiceBase<
		{},
		IWbNewsMessageResponse,
		IWbNewsMessageFilterRequest,
		IWbNewsMessageCreateRequest,
		IWbNewsMessageUpdateRequest,
		IWbNewsMessagePartialUpdateRequest
	>
	implements IGaWbNewsMessageControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/wb-news-message', $http, $q, apiConfigProvider);
	}
}
