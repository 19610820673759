import { ProfileRouteNames } from './profile-routes-namings';
import { Wizard } from 'ui-controls/wizard/wizard';

declare global {
	interface IChangePasswordStateParams {
		preserveGameSession: boolean;
	}
}

export class ProfileRoutesOptions {
	public closable? = false;
	public onClose?: Function;
	public preserveGameSession? = false;
	public authorizedOnly? = true;
}

function GetProfileRoutes(options: ProfileRoutesOptions = new ProfileRoutesOptions()) {
	const profileRoutes: IWizardRouteConfig[] = [
		{
			path: ProfileRouteNames.ChangePassword,
			name: ProfileRouteNames.ChangePassword,
			component: () =>
				import('./../change-password-form/change-password-form' /* webpackChunkName: "change-password" */).then(
					(_) => _.ChangePasswordForm
				),
			meta: {
				title: 'ChangePassword' as const,
				authorizedOnly: options.authorizedOnly
			}
		},
		{
			path: ProfileRouteNames.HelpDesk,
			name: ProfileRouteNames.HelpDesk,
			props: (route) => ({ helpDeskReasonId: route.params.helpDeskReasonId }),
			component: () => import('./../helpdesk/helpdesk' /* webpackChunkName: "helpdesk" */).then((_) => _.HelpDesk),
			meta: {
				title: 'HelpDeskCreateTicket' as const,
				authorizedOnly: options.authorizedOnly
			}
		}
	];

	return [
		{
			path: '',
			name: 'profile-wizard',
			component: Wizard,
			children: profileRoutes,
			props: options,
			redirect: { name: ProfileRouteNames.ChangePassword }
		},
		{ path: '/', redirect: { name: ProfileRouteNames.ChangePassword } },
		{ path: '*', redirect: { name: ProfileRouteNames.ChangePassword } }
	];
}

export { GetProfileRoutes };
