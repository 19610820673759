import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IDsNewsControllerService {
		getList(request: INewsRequest): IResponsePromise<INewsListPageResponse>;
	}
}

@injectable()
export class DsNewsControllerService extends HttpServiceBase implements IDsNewsControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public getList(request: INewsRequest): IResponsePromise<INewsListPageResponse> {
		const path = '/api/ds/news/list';
		const config = this.getMethodConfig('getList');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<INewsListPageResponse>>(url, request, config.requestConfig);
	}
}
