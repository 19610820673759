import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IHelpDeskTicketRequest {
		email: string;
		message: string;
		helpDeskReasonId: string;
		ticket: string;
	}

	interface IDsHelpDeskControllerService {
		ticket(request: IHelpDeskTicketRequest): IResponsePromise<void>;
	}
}

@injectable()
export class DsHelpDeskControllerService extends HttpServiceBase implements IDsHelpDeskControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public ticket(request: IHelpDeskTicketRequest): IResponsePromise<void> {
		const path = '/api/ds/helpdesk/ticket';
		const config = this.getMethodConfig('ticket');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}
}
