import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './captcha.html';
import './captcha.scss';
import { UIComponent } from 'ui-controls-core/ui-component';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import axios from 'axios';
import { FormContainerProvideKey, FormContainerEventNames } from 'ui-controls-core/forms/form-container-base';

const preloader = require('./img/preloader.gif');

@WithRender
@Component({
	inject: {
		$validator: '$validator',
		container: FormContainerProvideKey
	}
})
export class Captcha extends UIComponent {
	public container!: BootstrapFormContainer;

	public loading: boolean = false;

	public imageData: string | null = null;

	public preloader = preloader;

	public hash: string | null = null;

	@Prop({ type: Object, required: true })
	public formModel!: any;

	@Prop({ type: String, default: 'captcha' })
	public field!: string;

	constructor() {
		super();
	}

	public mounted() {
		this.container.eventHub.bind(FormContainerEventNames.SubmitFailure, (form: BootstrapFormContainer) => {
			this.refresh(true);
			if (!this.isInvalid) {
				this.formModel[this.field] = null;
			}
		});
		this.refresh();
	}

	public refresh(force: boolean = false) {
		if (this.loading && !force) {
			return;
		}
		this.loading = true;
		this.imageData = null;
		axios.post('/api/ls/captcha/_image.axd', {}).then(
			(response: { data: { data: { data: string; hash: string } } }) => {
				const data = response.data.data;
				if (!data) {
					return Promise.reject('Failed to load captcha');
				}
				this.imageData = data.data;
				this.formModel.captchaHash = JSON.stringify(data);
				this.hash = data.hash;
				this.loading = false;
			},
			() => {
				this.timeoutService.timeout(20000).then(() => {
					this.refresh();
				});
			}
		);
	}

	public get error() {
		return this.$validator.errors.first(this.field);
	}

	public get isInvalid(): boolean {
		return this.$validator.errors.has(this.field);
	}

	public get isValid(): boolean {
		return this.container.isFieldValid(this.field);
	}
}
