import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaWbMediaCatalogueControllerService
		extends IRestServiceBase<
				{},
				IWbMediaCatalogueResponse,
				IWbMediaCatalogueFilterRequest,
				IWbMediaCatalogueCreateRequest,
				IWbMediaCatalogueModificationRequest,
				IWbMediaCatalogueModificationRequest
			> {}
}

@injectable()
export class GaWbMediaCatalogueControllerService
	extends RestServiceBase<
		{},
		IWbMediaCatalogueResponse,
		IWbMediaCatalogueFilterRequest,
		IWbMediaCatalogueCreateRequest,
		IWbMediaCatalogueModificationRequest,
		IWbMediaCatalogueModificationRequest
	>
	implements IGaWbMediaCatalogueControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/wb-media-catalogue', $http, $q, apiConfigProvider);
	}
}
