<div class="pnz-captcha input-group pnz-input-group">
	<input type="text" :id="field" :name="field" :data-vv-as="'captchaCode' | localize" class="pnz-form-control form-control js-captcha-input" v-model="formModel[field]" autocomplete="off" v-validate="'required|length:5'" maxlength="5" v-bind:class="{'is-invalid': isInvalid, 'is-valid': isValid }" />
	<div class="input-group-append pnz-input-group-addon">
		<div class="pnz-captcha-image-container">
			<img class="js-captha-img" alt="Checking symbols" :src="'data:image/jpg;base64,' + imageData" v-if="!loading" :data-hash="hash" />
			<div class="pnz-captcha-preloader" v-if="loading">&nbsp;</div>
		</div>
	</div>
	<span class="input-group-append pnz-input-group-btn">
		<button class="btn btn-default pnz-btn js-captcha-refresh-button" type="button" v-on:click="refresh()">
			<span class="fa fa-refresh"></span>
		</button>
	</span>
</div>
