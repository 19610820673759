import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IWsCrystalPackControllerService {
		purchaseCrystalPack(request: IBuyCrystalPackRequest, cancelToken: Promise<void>): IResponsePromise<{}>;
	}
}

@injectable()
export class WsCrystalPackControllerService extends HttpServiceBase implements IWsCrystalPackControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public purchaseCrystalPack(request: IBuyCrystalPackRequest, cancelToken: Promise<void>): IResponsePromise<{}> {
		const path = 'api/xp/payment/crystalpack/purchase/';
		const config = this.getMethodConfig('purchaseCrystalPack');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<{}>>(url, request, config.requestConfig, cancelToken);
	}
}
