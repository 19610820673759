import { inject, injectable } from 'inversify';
import { UtilsNamings } from 'utils/utils-namings';
import { LogSeverity } from '../logging/log-severity';
import { Uid } from '../common/utils';
import Vue from 'vue';

declare global {
	export interface ILocalizationLoadingService {
		hub: IEventHub<LocalizationStorageServiceEvents>;
		localize(key: ILocalizationKey, locale: string): ILocalizedData;
		ensureLocale(locale: string): Promise<void>;
		pushLoader(loader: ILocalizationLoader): void;
	}
	type ILocaleData = typeof import('#/locales/locale.ru.json');
	type ILocalizationKey = keyof ILocaleData;

	interface ILocalizedData {
		text: string | null;
	}

	export interface ILocalizationLoader {
		load(locale: string): Promise<void>;
	}
}

export class LocalizationStorageServiceEvents {
	public static LocaleLoaded = 'locale:loaded';
}

class InternalLocalizationLoader implements ILocalizationLoader {
	constructor(private store: (locale: string, data: any) => void) {}
	public load(locale: string): Promise<void> {
		return import(/* webpackChunkName: "[request]" */ /* webpackInclude: /\.json$/ */ `#/locales/locale.${locale}.json`).then(
			data => {
				this.store(locale, data);
			}
		);
	}
}

@injectable()
export class LocalizationLoadingService implements ILocalizationLoadingService {
	public hub: IEventHub<LocalizationStorageServiceEvents>;
	private localesLoad: { [key: string]: Promise<void> };
	private locales: { [key: string]: { [key: string]: ILocalizedData } };

	private loaders: ILocalizationLoader[] = [];

	constructor(
		@inject(UtilsNamings.qServiceName) private $q: IQService,
		@inject(UtilsNamings.eventHubServiceName) eventHubService: IEventHubService,
		@inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService
	) {
		this.localesLoad = {};
		this.locales = {};
		this.hub = eventHubService.create(Uid.create(), 'LocalizationLoadingService');
		this.loaders.push(
			new InternalLocalizationLoader((locale: string, messages: any) => this.storeLocale(locale, messages))
		);
	}

	public pushLoader(loader: ILocalizationLoader): void {
		this.loaders.push(loader);
	}

	public localize(key: ILocalizationKey, locale: string): ILocalizedData {
		if (!key) {
			throw new Error('localization key is required');
		}
		if (!this.locales[locale]) {
			this.locales[locale] = {};
		}
		if (!this.locales[locale][key.toLowerCase()]) {
			this.locales[locale][key.toLowerCase()] = Vue.observable({ text: null });
		}
		return this.locales[locale][key.toLowerCase()];
	}

	public ensureLocale(locale: string): Promise<void> {
		if (!locale) {
			throw new Error(`locale in undefined`);
		}
		this.log(`ensure '${locale}'`);
		if (this.locales[locale]) {
			return Promise.resolve();
		}
		this.log(`loading '${locale}'`);
		return this.loadLocale(locale);
	}

	private loadLocale(locale: string): Promise<void> {
		if (this.localesLoad[locale]) {
			return this.localesLoad[locale];
		}
		const loadTasks = this.loaders.map(_ => _.load(locale));
		return (this.localesLoad[locale] = Promise.all(loadTasks)
			.then(_ => {
				this.hub.trigger(LocalizationStorageServiceEvents.LocaleLoaded, locale, this.locales[locale]);
			})
			.catch(error => {
				delete this.locales[locale];
				delete this.localesLoad[locale];
				return error;
			}));
	}

	private storeLocale(locale: string, localeData: ILocaleData) {
		this.log(`store '${locale}'`);
		if (!this.locales[locale]) {
			this.locales[locale] = {};
		}
		Object.keys(localeData).forEach((localeKey: string) => {
			const text = (<any>localeData)[localeKey];
			if (!this.locales[locale][localeKey.toLowerCase()]) {
				this.locales[locale][localeKey.toLowerCase()] = { text: text };
			} else {
				const data = this.locales[locale][localeKey.toLowerCase()];
				Vue.set(data, 'text', text);
			}
		});
	}

	private log(message: string): void {
		this.loggingService.log('[LocalizationLoadingService] ' + message, LogSeverity.INFO);
	}
}
