export class ServerApiNamings {
	public static moduleName = 'pnz-server-api';
	public static lsAccountControllerServiceName = 'pnz-server-api-account-controller-service';
	public static chatControllerServiceName = 'pnz-server-api-chat-controller-service';
	public static commentsControllerServiceName = 'pnz-server-api-comments-controller-service';
	public static externalAuthenticationControllerServiceName = 'pnz-server-api-external-authentication-controller-service';
	public static helpdeskControllerServiceName = 'pnz-server-api-helpdesk-controller-service';
	public static lsGameLoginControllerServiceName = 'pnz-server-api-game-login-controller-service';

	public static lsLauncherControllerServiceName = 'pnz-server-api-launcher-controller-service';
	public static newsControllerServiceName = 'pnz-server-api-news-controller-service';
	public static societyControllerServiceName = 'pnz-server-api-society-controller-service';
	public static streamControllerServiceName = 'pnz-server-api-stream-controller-service';
	public static surveyControllerServiceName = 'pnz-server-api-survey-controller-service';
	public static gaWbNewsMessageControllerServiceName = 'pnz-server-api-ga-wb-news-message-controller-service';

	public static gaWbNewsMessagePlacementControllerServiceName = 'pnz-server-api-ga-wb-news-message-placement-controller-service';
	public static gaWbTagControllerServiceName = 'pnz-server-api-ga-wb-tag-controller-service';
	public static gaWbCategoryControllerServiceName = 'pnz-server-api-ga-wb-category-controller-service';
	public static gaWbMediaCatalogueControllerServiceName = 'pnz-server-api-ga-wb-media-catalogue-controller-service';
	public static gaWbMediaControllerServiceName = 'pnz-server-api-ga-wb-media-controller-service';
	public static gaWbMediaLocalizationControllerServiceName = 'pnz-server-api-ga-wb-media-localization-controller-service';

	public static gaAdCampaignControllerServiceName = 'pnz-server-api-ga-ad-campaign-controller-service';
	public static gaAdPixelControllerServiceName = 'pnz-server-api-ga-ad-pixel-controller-service';
	public static gaAdSourceControllerServiceName = 'pnz-server-api-ga-ad-source-controller-service';
	public static gaAdTargetInstanceControllerServiceName = 'pnz-server-api-ga-ad-target-instance-controller-service';

	public static gaAccountControllerServiceName = 'pnz-server-api-ga-account-controller-service';
	public static gaTournamentsControllerServiceName = 'pnz-server-api-ga-tournament-controller-service';

	public static dsMediaUrlServiceName = 'pnz-server-api-ds-media-url-service';
	public static dsNewsControllerServiceName = 'pnz-server-api-ds-news-controller-service';
	public static dsCrystalPackControllerServiceName = 'pnz-server-api-ds-crystal-pack-controller-service';
	public static dsPromoCodeControllerServiceName = 'pnz-server-api-ds-promo-code-controller-service';
	public static dsHelpDeskControllerServiceName = 'pnz-server-api-ds-help-desk-controller-service';

	public static gaAccountsControllerServiceName = 'pnz-server-api-ga-accounts-controller-service';

	public static gaAccountLoginControllerServiceName = 'pnz-server-api-ga-account-login-controller-service';

	public static gaPlayerControllerServiceName = 'pnz-server-api-ga-player-controller-service';

	public static gaHelpDeskTicketControllerServiceName = 'pnz-server-api-ga-help-desk-ticket-controller-service';

	public static gaComputerBanControllerServiceName = 'pnz-server-api-ga-computer-ban-controller-service';
	public static gaAccountBanControllerServiceName = 'pnz-server-api-ga-account-ban-controller-service';

	public static wsAuthControllerServiceName = 'pnz-server-api-ws-auth-controller-service';

	public static wsNavigateControllerServiceName = 'pnz-server-api-ws-navigate-controller-service';

	public static lsTicketControllerServiceName = 'pnz-server-api-ls-ticket-controller-service';

	public static wsCrystalPackControllerServiceName = 'pnz-server-api-ws-crystal-pack-controller-service';

	public static wsPaymentSteamControllerServiceName = 'pnz-server-api-ws-payment-steam-controller-service';

	public static wsPaymentControllerServiceName = 'pnz-server-api-ws-payment-controller-service';
}
