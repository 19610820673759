import { createDecorator } from 'vue-class-component';

type GettersIn<TStore> = TStore extends { getters: infer TGetters } ? TGetters : never;

export function Getter<TStore>(path: GettersIn<TStore>) {
	return createDecorator((options, key) => {
		if (!options.computed) {
			options.computed = {};
		}
		options.computed[key] = function() {
			return (<any>this.$store).getters[path];
		};
	});
}

export function State<TStore>(path: keyof TStore) {
	return DeepState<TStore>([path] as any);
}

export function DeepState<TStore>(path: PathIn<TStore>) {
	return createDecorator((options, key) => {
		if (!options.computed) {
			options.computed = {};
		}
		options.computed[key] = function() {
			const fragments = path as string[];
			let slice = (<any>this.$store).state;
			for (const fragment of fragments) {
				slice = slice[fragment];
			}
			return slice;
		};
	});
}
