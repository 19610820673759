import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './panel.html';

import './panel.scss';

@WithRender
@Component
export class Panel extends Vue {
	@Prop()
	public title!: string | null;
}
