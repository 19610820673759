import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './checkbox.html';

import './checkbox.scss';

@WithRender
@Component
export class Checkbox extends Vue {
	@Prop()
	public value!: boolean;
	@Prop()
	public modelValue!: any;
	@Prop()
	public name!: string;
	@Prop()
	public labelText!: string;

	@Prop({ default: true })
	public trueValue!: boolean;

	@Prop({ default: false })
	public falseValue!: boolean;

	public currentValue: boolean = false;

	@Watch('value')
	public onValueChanged(val: boolean) {
		this.updateModel();
	}

	public get inputListeners(): Record<string, Function | Function[]> {
		return Object.assign({}, this.$listeners, {
			change: (event: any) => {
				const checked = event.target.checked;
				this.currentValue = checked;
				const value = checked ? this.trueValue : this.falseValue;
				this.$emit('input', value);
				this.$emit('change');
			}
		});
	}

	private updateModel() {
		this.currentValue = this.value === this.trueValue;
	}
}
