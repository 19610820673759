import { RouteConfig } from 'vue-router';
import { injectable } from 'inversify';

declare global {
	interface IRouteUtilsService {
		indexRoutes(routes: RouteConfig[]): void;
		getRouteById(routeId: string): RouteConfig;
		getRouteByName(routeName: string): RouteConfig;
	}
}

@injectable()
export class RouteUtilsService implements IRouteUtilsService {
	private count: number = 0;
	public itemsByIdHash: { [key: string]: IRouteConfigEx } = {};
	public itemsByNameHash: { [key: string]: IRouteConfigEx } = {};
	public indexRoutes(routes: RouteConfig[]): void {
		this.indexRoutesRecursive(routes);
	}

	public getRouteById(routeId: string): RouteConfig {
		const route = this.itemsByIdHash[routeId];
		if (!route) {
			throw new Error(`route with id:'${routeId}' not found`);
		}
		return route;
	}

	public getRouteByName(routeName: string): RouteConfig {
		const route = this.itemsByNameHash[routeName];
		if (!route) {
			throw new Error(`route with name:'${routeName}' not found`);
		}
		return route;
	}

	private indexRoutesRecursive(routes: RouteConfig[]) {
		for (const _ of routes) {
			const meta: IIndexedRouteConfigMeta = _.meta || (_.meta = {});
			meta.id = this.count.toString();
			this.addToCache(_, meta.id);
			this.count++;
			if (_.children) {
				this.indexRoutesRecursive(_.children);
			}
		}
	}

	private addToCache(config: RouteConfig, id: string) {
		if (this.itemsByIdHash[id]) {
			throw new Error(`route with id:'${id}' already exists`);
		}
		this.itemsByIdHash[id] = config;
		if (config.name) {
			if (this.itemsByNameHash[config.name]) {
				throw new Error(`route with name:'${config.name}' already exists`);
			}
			this.itemsByNameHash[config.name] = config;
		}
	}
}
