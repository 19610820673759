<form class="pnz-form" :id="id" v-on:submit.prevent="$emit('submit', $event)" v-on:reset.prevent="$emit('reset', $event)">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="pnz-form-message">
					<p class="pnz-error-message js-form-message text-center">{{ formError }}</p>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="container-fluid">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</form>
