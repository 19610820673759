import { LogSeverity } from './log-severity';
import { injectable } from 'inversify';

declare global {
	interface ILogContextProvider {
		processLogContext(context: any, severity: LogSeverity): void;
	}

	interface ILoggingService {
		log(message: string, severity: LogSeverity): void;
		addLogContextProvider(provider: ILogContextProvider): void;
	}
}

@injectable()
export class LoggingService implements ILoggingService {
	private contextProviders: ILogContextProvider[];

	constructor() {
		this.contextProviders = [];
	}

	public log(message: string, severity: LogSeverity): void {
		const dd = new Date();
		const hh = dd.getHours();
		const mm = dd.getMinutes();
		const ss = dd.getSeconds();
		const ms = dd.getMilliseconds();
		const context = {};
		this.contextProviders.forEach(provider => {
			provider.processLogContext(context, severity);
		});
		const $log = console;
		switch (severity) {
			case LogSeverity.TRACE:
				$log.trace('%c [t] ' + message + ' ' + JSON.stringify(context, null, 2), 'background: #dff;');
				break;
			case LogSeverity.DEBUG:
				$log.debug('%c [t] ' + message + ' ' + JSON.stringify(context, null, 2), 'background: #dff;');
				break;
			case LogSeverity.INFO:
				$log.log('%c [i] ' + message + ' ' + JSON.stringify(context, null, 2), 'background: #dff;');
				break;
			case LogSeverity.WARNING:
				$log.warn('%c [w] ' + message + ' ' + JSON.stringify(context, null, 2), 'background: #dff;');
				break;
			case LogSeverity.ERROR:
				$log.error('%c [e] ' + message + ' ' + JSON.stringify(context, null, 2), 'background: #dff;');
				break;
			default:
		}
	}

	public addLogContextProvider(provider: ILogContextProvider): void {
		this.contextProviders.push(provider);
	}
}
