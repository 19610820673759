import { UtilsNamings } from '../utils-namings';
import { inject, injectable } from 'inversify';

declare global {
	interface ILocalizationService {
		localize(key: ILocalizationKey): string;
		localizeFormat(source: IFormatable): string;
		localizeLocalizableEntities<T extends INamedLocalizableEntity>(items: T[]): Array<T & INamedLocalizedEntity>;
	}
}

@injectable()
export class LocalizationService implements ILocalizationService {
	constructor(
		@inject(UtilsNamings.currentLocaleServiceName) private currentLocaleService: ICurrentLocaleService,
		@inject(UtilsNamings.localizationLoadingService) private localizationStorageService: ILocalizationLoadingService,
		@inject(UtilsNamings.formattingServiceName) private formattingService: IFormattingService
	) {}

	public localizeLocalizableEntities<T extends INamedLocalizableEntity>(items: T[]): Array<T & INamedLocalizedEntity> {
		return items.map(item => {
			return {
				localizedTitle: this.localize(item.nameKey as ILocalizationKey) || item.name,
				...item
			};
		});
	}

	public localize(key: ILocalizationKey): string {
		const currentLocale = this.currentLocaleService.getCurrentLocaleName();

		const current = this.localizationStorageService.localize(key, currentLocale);
		if (current) {
			return current.text!;
		}
		// NOT_FOUND is returned only if locale is already loaded but resource is not present.
		return 'NOT_FOUND';
	}

	public localizeFormat(source: IFormatable): string {
		let result = this.localize(source.textKey);
		if (source.orderedParameters) {
			result = this.formattingService.formatOrdered(result, source.orderedParameters);
		}
		if (source.namedParameters) {
			result = this.formattingService.formatKeyed(result, source.namedParameters);
		}
		return result;
	}
}
