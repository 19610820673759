import { configureIoC } from './configure-ioc';
import { registerUIComponents } from 'ui-controls/register-ui-components';
import { UtilsNamings } from 'utils/utils-namings';
import { UtilsModule } from 'utils/utils-module';
import { UIControlsNamings } from 'ui-controls/ui-controls-namings';
import { UIControlsCoreModule } from 'ui-controls-core/ui-controls-core-module';
import { UIControlsCoreNamings } from 'ui-controls-core/ui-controls-core-namings';
import { registerValidators } from './register-validators';

registerValidators();

import './ui-controls.scss';
import { VeeValidateLocalizationLoader } from './vee-validate-localization-loader';

export class UIControlsModule implements IModule {
	public moduleName = UIControlsNamings.moduleName;

	public registerSubModules(context: IAppContext): Promise<void> {
		context.moduleContainer.register(UtilsNamings.moduleName, UtilsModule);
		context.moduleContainer.register(UIControlsCoreNamings.moduleName, UIControlsCoreModule);
		return Promise.resolve();
	}
	public configureIoC(context: IAppContext): Promise<void> {
		configureIoC();
		return Promise.resolve();
	}
	public configureRoutes(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}

	public registerUIComponents(context: IAppContext): Promise<void> {
		registerUIComponents();
		return Promise.resolve();
	}
	public configureModule(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public beforeRun(context: IAppContext): Promise<void> {
		const localizationLoadingService = context.iocContainer.get<ILocalizationLoadingService>(
			UtilsNamings.localizationLoadingService
		);
		localizationLoadingService.pushLoader(new VeeValidateLocalizationLoader(context.i18n));
		return Promise.resolve();
	}
	public afterRun(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
}
