import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaAccountLoginControllerService
		extends IRestServiceBase<
				{},
				IAccountLoginResponse,
				IAccountLoginFilterRequest,
				IAccountLoginCreateRequest,
				IAccountLoginUpdateRequest,
				IAccountLoginPartialUpdateRequest
			> {}
}

@injectable()
export class GaAccountLoginControllerService
	extends RestServiceBase<
		{},
		IAccountLoginResponse,
		IAccountLoginFilterRequest,
		IAccountLoginCreateRequest,
		IAccountLoginUpdateRequest,
		IAccountLoginPartialUpdateRequest
	>
	implements IGaAccountLoginControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/account-login', $http, $q, apiConfigProvider);
	}
}
