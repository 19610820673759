<pnz-form id="short-registration-form">
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="6" field="email" :label="'referralInputEmail' | localize" :auto-focus="true" />
		<pnz-form-group-with-error-block col-sm="6" field="password" :label="'password' | localize " type="password" field-ref="short-registration-form-password" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="6" field="passwordConfirmation" :label="'confirmPassword' | localize" type="password" />
		<pnz-form-group-with-error-block col-sm="6" field="promoCode" :label="'promoCode' | localize" type="text" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group>
			<pnz-button type="submit" :is-loading="isLoading">{{ 'registration' | localize }}</pnz-button>
		</pnz-form-group>
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
	<pnz-form-row class="justify-content-center">
		{{ 'registeringYouAgreeToUserAgreementPart1' | localize }}
		<a href="javascript:void(0)" v-on:click.prevent="licenseLinkClick()" class="pnz-link js-license-link pnz-license-link">{{ 'registeringYouAgreeToUserAgreementPart2' | localize }}</a>
	</pnz-form-row>
</pnz-form>
