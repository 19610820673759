import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './bootstrap-form.html';

import './bootstrap-form.scss';
import { FormBase } from 'ui-controls-core/forms/form-base';

@WithRender
@Component({
	name: 'PnzBootstrapForm'
})
export class PnzBootstrapForm extends FormBase {}
