<pnz-form-group :col-sm="colSm" :offset-sm="offsetSm" :with-error="true" :with-top-margin="false" class="has-feedback" :class="{'has-warning': isErrorBlockVisible && !hideValidation }">
	<label :for="containerId + field">{{label}}:</label>
	<div class="pnz-form-group-input-wrapper">
		<pnz-captcha v-on="$listeners" v-bind="inputOptions" v-if="type === 'captcha'" :form-model="formModel" :id="containerId + 'captcha'" :v-pnz-autofocus="autoFocus" :ref="fieldRef || containerId + 'captcha'" />
		<input v-on="$listeners" v-bind="inputOptions" v-else-if="type === 'number'" type="number" class="pnz-form-control form-control" :id="containerId + field" :name="field" :ref="fieldRef || containerId + field" :data-vv-as="label" v-model.number="formModel[field]" v-pnz-autofocus="autoFocus" v-bind:class="{'is-invalid': isInvalid && !hideValidation, 'is-valid': isValid && !hideValidation }" v-validate="rules" />
		<b-form-select v-on="$listeners" v-bind="inputOptions" :plain="true" v-else-if="type === 'select'" class="pnz-form-control" :id="containerId + field" :name="field" :ref="fieldRef || containerId + field" :data-vv-as="label" v-model.number="formModel[field]" v-pnz-autofocus="autoFocus" v-bind:class="{'is-invalid': isInvalid && !hideValidation, 'is-valid': isValid && !hideValidation }" v-validate="rules">
			<template v-slot:first>
				<b-form-select-option :value="null" disabled style="display: none;">Please select an option</b-form-select-option>
			</template>
		</b-form-select>
		<textarea v-on="$listeners" v-bind="inputOptions" v-else-if="type === 'textarea'" :rows="15" class="pnz-form-control form-control" :id="containerId + field" :name="field" :ref="fieldRef || containerId + field" :data-vv-as="label" v-model.number="formModel[field]" v-pnz-autofocus="autoFocus" v-bind:class="{'is-invalid': isInvalid && !hideValidation, 'is-valid': isValid && !hideValidation }" v-validate="rules" />
		<input v-on="$listeners" v-bind="inputOptions" v-else :type="type" class="pnz-form-control form-control" :maxlength="maxLength" :id="containerId + field" :name="field" :ref="fieldRef || containerId + field" :data-vv-as="label" v-model.trim="formModel[field]" v-pnz-autofocus="autoFocus" v-bind:class="{'is-invalid': isInvalid && !hideValidation, 'is-valid': isValid && !hideValidation }" v-validate="rules" />
		<i class="fa fa-times helper-icon error" aria-hidden="true" v-if="isInvalid && !hideValidation"></i>
		<i class="fa fa-check helper-icon success" aria-hidden="true" v-if="isValid && !hideValidation"></i>
	</div>
	<pnz-error-block :error="error" v-pnz-visible="isErrorBlockVisible" />
</pnz-form-group>
