import Router, { RouteRecord, RouterOptions } from 'vue-router';
import { container } from '../ioc/container';
import { UtilsNamings } from '../utils-namings';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '../stores/user-module';
import some from 'lodash/some';
import { LogSeverity } from '../logging';

function trace(message: string, severity: LogSeverity = LogSeverity.INFO) {
	const loggingService = container.get<ILoggingService>(UtilsNamings.loggingServiceName);
	loggingService.log(`[ROUTER] ${message}`, severity);
}

export const AuthorizedRoute = '/authorized';
export const UnauthorizedRoute = '/unauthorized';
export const UnauthenticatedRoute = '/unauthenticated';
export const enum RouteCheckResult {
	Success,
	Unauthorized,
	Unauthenticated,
	AuthorizationUnexpected
}

export function checkIfUserCanProceed(meta: IRouteConfigMeta, userModule: IUserState): RouteCheckResult {
	if (meta.authorizedOnly) {
		if (!userModule.username) {
			return RouteCheckResult.Unauthenticated;
		}
	} else if (meta.unauthorizedOnly) {
		if (userModule.username) {
			return RouteCheckResult.AuthorizationUnexpected;
		}
	}
	if (meta.roles) {
		if (!some(meta.roles, (role: string) => userModule.roleIds.indexOf(role.toString()) >= 0)) {
			trace('declined route due to required role missing', LogSeverity.WARNING);
			return RouteCheckResult.Unauthorized;
		}
	}
	return RouteCheckResult.Success;
}

function createRouter(options?: RouterOptions): Router {
	const router = new Router(options);

	router.beforeEach((to, from, next) => {
		const store = container.get<Store<any>>(UtilsNamings.appStoreName);
		const userModule = getModule(UserModule, store);
		let canProceed = true;
		to.matched.forEach(matched => {
			const checkResult = checkIfUserCanProceed(matched.meta, userModule);
			switch (checkResult) {
				case RouteCheckResult.Unauthenticated: {
					canProceed = false;
					trace(`declined route '${to.name}' due to authentication missing`, LogSeverity.WARNING);
					next(UnauthenticatedRoute);
					return false;
				}
				case RouteCheckResult.Unauthorized: {
					canProceed = false;
					trace(`declined route '${to.name}' due to roles missing`, LogSeverity.WARNING);
					next(UnauthorizedRoute);
					return false;
				}
				case RouteCheckResult.AuthorizationUnexpected: {
					canProceed = false;
					trace(`declined route '${to.name}' due to authentication presents`, LogSeverity.WARNING);
					next(AuthorizedRoute);
					return false;
				}
			}
		});
		if (canProceed) {
			trace(`route '${to.name}' is allowed`, LogSeverity.INFO);
			next();
		}
	});

	return router;
}

export { createRouter };
