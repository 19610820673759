import { UtilsNamings } from 'utils/utils-namings';
import { LogSeverity } from 'utils/logging/log-severity';
import { injectable, inject } from 'inversify';

declare global {
	interface IAuthStateService {
		setAuthToken(authToken: string | null): void;
		getAuthToken(): string | null;
	}
}

@injectable()
export class AuthStateService implements IAuthStateService {
	private static key = 'auth.token';

	constructor(@inject(UtilsNamings.localStorageServiceName) private localStorageService: ILocalStorageService, @inject(UtilsNamings.loggingServiceName) private loggingService: ILoggingService) {}

	public setAuthToken(authToken: string | null): void {
		this.trace(`set auth token: '${authToken}'`);
		this.localStorageService.set(AuthStateService.key, authToken);
	}

	public getAuthToken(): string | null {
		return this.localStorageService.get<string>(AuthStateService.key);
	}

	private trace(message: string, severity: LogSeverity = LogSeverity.INFO) {
		this.loggingService.log('[AuthStateService] ' + message, severity);
	}
}
