import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaAccountBanControllerService
		extends IRestServiceBase<
				{},
				IAccountBanResponse,
				IAccountBanFilterRequest,
				IAccountBanCreateRequest,
				IAccountBanUpdateRequest,
				IAccountBanPartialUpdateRequest
			> {
		deleteWithNote(request: IAccountBanDeleteWithNoteRequest): Promise<IResponsePayload<void>>;
	}
}

@injectable()
export class GaAccountBanControllerService
	extends RestServiceBase<
		{},
		IAccountBanResponse,
		IAccountBanFilterRequest,
		IAccountBanCreateRequest,
		IAccountBanUpdateRequest,
		IAccountBanPartialUpdateRequest
	>
	implements IGaAccountBanControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/account-ban', $http, $q, apiConfigProvider);
	}

	public deleteWithNote(request: IAccountBanDeleteWithNoteRequest) {
		const path = '/internal/ga/account-ban/deletewithnote/' + `${request.id}`;
		const config = this.getMethodConfig('deleteWithNote');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makeDelete<IResponsePayload<void>>(url, { data: request });
	}
}
