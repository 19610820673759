import { RegistrationRouteNames } from './registration-routes-namings';
import { ShortRegistrationForm } from '../views/short-registration-form/short-registration-form';
import { LoginRouteNames } from 'membership/auth/login/routes/login-routes-namings';

export const RegistrationRoutes: IWizardRouteConfig[] = [
	{
		path: RegistrationRouteNames.ShortRegistration,
		name: RegistrationRouteNames.ShortRegistration,
		component: ShortRegistrationForm,
		meta: {
			title: 'registration' as const,
			back: LoginRouteNames.Login
		}
	},
	{
		path: RegistrationRouteNames.LandingRegistration,
		name: RegistrationRouteNames.LandingRegistration,
		component: ShortRegistrationForm,
		meta: {
			title: 'registration' as const
		}
	},
	{
		path: RegistrationRouteNames.StandaloneRegistration,
		name: RegistrationRouteNames.StandaloneRegistration,
		component: ShortRegistrationForm,
		meta: {
			title: 'registration' as const
		}
	}
];
