import { injectable } from 'inversify';
import axios, { AxiosTransformer } from 'axios';
import qs from 'qs';
import mapValues from 'lodash/mapValues';
import { convertFromUserDateToUtc, convertFromUtcToUserDate } from '../current-date';
import { DefaultHttpErrorMessage } from './consts';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
/*if (DEVELOPMENT) {
	axios.defaults.baseURL = 'http://localhost/';
}*/

axios.defaults.paramsSerializer = params => {
	return qs.stringify(params, { arrayFormat: 'indices', allowDots: true });
};

const mapValuesDeep = (v: any, callback: Function): any => {
	if (v === null) {
		return v;
	} else if (typeof v === 'undefined') {
		return v;
	} else if (v instanceof Array) {
		return v.map(_ => mapValuesDeep(_, callback));
	} else if (typeof v === 'object' && !(v instanceof Date)) {
		return mapValues(v, v1 => mapValuesDeep(v1, callback));
	} else {
		return callback(v);
	}
};

const requestTransformers = axios.defaults.transformRequest as AxiosTransformer[];
const requestDateTransformer = (data: any) => {
	if (!data) {
		return data;
	}
	if (typeof data === 'object') {
		return mapValuesDeep(data, (value: any) => {
			if (value instanceof Date) {
				return convertFromUserDateToUtc(value);
			}
			return value;
		});
	}
	return data;
};

requestTransformers.unshift(requestDateTransformer);

const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]{0,5})?(Z)?$/;

export const dateTimeReviver = (key: any, value: any) => {
	let a;
	if (typeof value === 'string') {
		a = dateRegex.exec(value);
		if (a) {
			const date = new Date(value);
			return date;
		}
	}
	return value;
};
axios.defaults.transformResponse = data => {
	if (typeof data === 'string') {
		if (data === '' || data.startsWith('<!')) {
			throw new Error(data || DefaultHttpErrorMessage);
		}
		try {
			data = JSON.parse(data, dateTimeReviver);
		} catch (e) {
			// tslint:disable-next-line:no-console
			console.log(e);
			throw e;
		}
	}
	return data;
};

@injectable()
export class AxiosHttpService implements IHttpService {
	public makeGet<T>(url: string, config?: IRequestShortcutConfig | undefined, cancelToken?: Promise<void>): Promise<T> {
		const axiosCancelToken = this.getAxiosCancelToken(cancelToken);
		return axios
			.get<T>(url, { ...config, cancelToken: axiosCancelToken })
			.then(axiosResponse => {
				return axiosResponse.data;
			});
	}
	public makePost<T>(
		url: string,
		data: any,
		config?: IRequestShortcutConfig | undefined,
		cancelToken?: Promise<void>
	): Promise<T> {
		const axiosCancelToken = this.getAxiosCancelToken(cancelToken);
		return axios
			.post<T>(url, data, { ...config, cancelToken: axiosCancelToken })
			.then(axiosResponse => {
				return axiosResponse.data;
			});
	}

	public makeDelete<T>(
		url: string,
		config?: IRequestShortcutConfig | undefined,
		cancelToken?: Promise<void>
	): Promise<T> {
		const axiosCancelToken = this.getAxiosCancelToken(cancelToken);
		return axios.delete(url, { ...config, cancelToken: axiosCancelToken }).then(axiosResponse => {
			return axiosResponse.data;
		});
	}
	public makeHead<T>(
		url: string,
		config?: IRequestShortcutConfig | undefined,
		cancelToken?: Promise<void>
	): Promise<T> {
		const axiosCancelToken = this.getAxiosCancelToken(cancelToken);
		return axios.head(url, { ...config, cancelToken: axiosCancelToken }).then(axiosResponse => {
			return axiosResponse.data;
		});
	}
	public makePut<T>(
		url: string,
		data: any,
		config?: IRequestShortcutConfig | undefined,
		cancelToken?: Promise<void>
	): Promise<T> {
		const axiosCancelToken = this.getAxiosCancelToken(cancelToken);
		return axios
			.put<T>(url, data, { ...config, cancelToken: axiosCancelToken })
			.then(axiosResponse => {
				return axiosResponse.data;
			});
	}
	public makePatch<T>(
		url: string,
		data: any,
		config?: IRequestShortcutConfig | undefined,
		cancelToken?: Promise<void>
	): Promise<T> {
		const axiosCancelToken = this.getAxiosCancelToken(cancelToken);
		return axios
			.patch<T>(url, data, { ...config, cancelToken: axiosCancelToken })
			.then(axiosResponse => {
				return axiosResponse.data;
			});
	}

	private getAxiosCancelToken(cancelToken?: Promise<void>): CancelToken {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		if (cancelToken) {
			cancelToken.finally(() => {
				source.cancel('cancellation');
			});
		}
		return source.token;
	}
}
