import { RegistrationRouteNames } from 'membership/auth/registration/routes/registration-routes-namings';
import { LoginRouteNames } from 'membership/auth/login/routes/login-routes-namings';
import { ProfileRouteNames } from 'membership/profile/@routes/profile-routes-namings';

function initRegistrationButtons(context: IAppContext) {
	$(window.document.body).on('click', '[data-role="registration-button"]', e => {
		const redirectUrl = $(e.target).data('redirect-to');
		const isLanding = $(e.target).data('landing') === true;
		context.router.push({
			name: isLanding ? RegistrationRouteNames.LandingRegistration : RegistrationRouteNames.StandaloneRegistration,
			query: { redirectUrl: redirectUrl }
		});
	});
}

function initChangePasswordButton(context: IAppContext) {
	$(window.document.body).on('click', '[data-role="change-password-button"]', e => {
		const redirectUrl = $(e.target).data('redirect-to');
		context.router.push({
			name: ProfileRouteNames.ChangePassword,
			query: { redirectUrl: redirectUrl }
		});
	});
}

function initHelpDeskButton(context: IAppContext) {
	$(window.document.body).on('click', '[data-role="help-desk-button"]', e => {
		const redirectUrl = $(e.target).data('redirect-to');
		context.router.push({
			name: ProfileRouteNames.HelpDesk,
			query: { redirectUrl: redirectUrl }
		});
	});
}

function initLoginButton(context: IAppContext) {
	$(window).on('nk-open-sign-form', () => {
		context.router.push({ name: LoginRouteNames.Login });
	});
}

export function initControls(context: IAppContext) {
	initLoginButton(context);
	initRegistrationButtons(context);
	initChangePasswordButton(context);
	initHelpDeskButton(context);
}
