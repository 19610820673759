import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IWsPaymentControllerService {
		buyItem(request: IBuyItemRequest, cancelToken: Promise<void> | null): IResponsePromise<IBuyItemResponse>;
	}
}

@injectable()
export class WsPaymentControllerService extends HttpServiceBase implements IWsPaymentControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public buyItem(request: IBuyItemRequest, cancelToken: Promise<void>): IResponsePromise<IBuyItemResponse> {
		const path = '/api/xp/payment/buyItem';
		const config = this.getMethodConfig('buyItem');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<IBuyItemResponse>>(url, request, config.requestConfig, cancelToken);
	}
}
