const DEFAULT_CHANNEL = 'pnz-shared-mutations';

export class BroadcastChannelStrategy implements ISharedMutationStrategy {
	private channel: BroadcastChannel;
	public static available() {
		return !(typeof BroadcastChannel !== 'function');
	}

	constructor(options: any = {}) {
		const key = options.key || DEFAULT_CHANNEL;

		if (!BroadcastChannelStrategy.available()) {
			throw new Error('Broadcast strategy not available');
		}

		this.channel = new BroadcastChannel(key);
	}

	public addEventListener(fn: Function) {
		this.channel.addEventListener('message', e => {
			fn(e.data);
		});
	}

	public share(message: any) {
		return this.channel.postMessage(message);
	}
}
