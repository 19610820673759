import { LoginRouteNames } from './login-routes-namings';
import { LoginForm } from '../login-form/login-form';

export const LoginRoutes: IWizardRouteConfig[] = [
	{
		path: LoginRouteNames.Login,
		name: LoginRouteNames.Login,
		component: LoginForm,
		meta: {
			title: 'authorization' as const,
			unauthorizedOnly: true
		}
	}
];
