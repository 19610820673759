import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators';
import { clientConfig } from 'utils/config';

declare global {
	interface IUserState {
		userId: string | null;
		username: string | null;

		roleIds: string[];
	}

	interface IClientConfig {
		userId: string | null;
		username?: string | null;
		roleIds?: string[];
	}
}

export const UserModuleName = 'user';

@Module({ namespaced: true, name: UserModuleName })
class UserModule extends VuexModule implements IUserState {
	public userId: string | null;
	public username: string | null;

	public roleIds: string[];

	constructor(arg: any) {
		super(arg);
		this.userId = clientConfig.userId ? clientConfig.userId : null;
		this.username = clientConfig.username ? clientConfig.username : null;
		this.roleIds = clientConfig.roleIds || [];
	}

	@MutationAction
	public async update(data: { userId: string; username: string; roleIds: string[] }) {
		const { userId, username, roleIds } = data;
		return {
			userId: userId,
			username: username,
			roleIds: roleIds
		};
	}

	@MutationAction
	public async logout() {
		return {
			userId: '',
			username: '',
			roleIds: [] as any
		};
	}
}

export { UserModule };
