import { Component, Prop } from 'vue-property-decorator';

import { UIComponent } from 'ui-controls-core/ui-component';
import { FormContainerProvideKey, FormContainer } from 'ui-controls-core/forms/form-container-base';
import { FormIdProvideKey, FormBase } from 'ui-controls-core/forms/form-base';

@Component({
	inject: {
		container: FormContainerProvideKey,
		formId: FormIdProvideKey
	}
})
export class FormElementBase<
	TFormContainer extends FormContainer<TForm, TRule, TModel>,
	TForm extends FormBase,
	TRule,
	TModel extends object = any
> extends UIComponent {
	public container!: TFormContainer;

	public formId!: string;

	@Prop({ type: String, required: true })
	public field!: string;

	@Prop({ type: String })
	public label!: string;

	@Prop({
		type: Boolean,
		default: function(this: FormElementBase<TFormContainer, TForm, TRule>) {
			return this.container.isReadonly;
		}
	})
	public isReadonly!: boolean;

	@Prop({ type: Boolean, default: false })
	public isRequired!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideValidation!: boolean;

	@Prop({
		type: Object,
		default: function(this: FormElementBase<TFormContainer, TForm, TRule>) {
			return this.container.model;
		}
	})
	public formModel!: TModel;

	public get isValid() {
		return this.container.isFieldValid(this.field);
	}

	public get isInvalid() {
		return this.container.isFieldInvalid(this.field);
	}

	public get containerId(): string {
		return this.formId ? this.formId + '-' : '';
	}

	public get error(): string | null {
		return this.container.getFieldError(this.field);
	}
}
