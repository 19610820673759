import { configureIoC } from './configure-ioc';
import { registerUIComponents } from 'ui-controls-core/register-ui-components';
import { UtilsNamings } from 'utils/utils-namings';
import { UtilsModule } from 'utils/utils-module';
import { UIControlsCoreNamings } from 'ui-controls-core/ui-controls-core-namings';
import VueI18n from 'vue-i18n';
import Vuei18n from 'vue-i18n';
import Vue from 'vue';
import { registerValidators } from './register-validators';
import { container } from 'utils/ioc/container';

registerValidators();

Vue.use(VueI18n);

import './ui-controls-core.scss';
import { CurrentLocaleServiceEvents } from '../utils/localization/current-locale-service';
import { LocalizationStorageServiceEvents } from '../utils/localization/localization-loading-service';
import { clientConfig } from '../utils/config';

declare global {
	interface IAppContext {
		readonly i18n: Vuei18n;
	}
}

const defaultLanguage = 'en';

export class UIControlsCoreModule implements IModule {
	public moduleName = UIControlsCoreNamings.moduleName;

	public registerSubModules(context: IAppContext): Promise<void> {
		context.moduleContainer.register(UtilsNamings.moduleName, UtilsModule);
		return Promise.resolve();
	}
	public configureIoC(context: IAppContext): Promise<void> {
		configureIoC();
		container.bind(UtilsNamings.appI18nName).toConstantValue(context.i18n);
		return Promise.resolve();
	}
	public configureRoutes(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}

	public registerUIComponents(context: IAppContext): Promise<void> {
		registerUIComponents();
		return Promise.resolve();
	}
	public configureModule(context: IAppContext): Promise<void> {
		context.i18n.locale = clientConfig.language || defaultLanguage;
		context.i18n.fallbackLocale = defaultLanguage;
		return Promise.resolve();
	}
	public beforeRun(context: IAppContext): Promise<void> {
		Vue.prototype.$localizationService = context.iocContainer.get<ILocalizationService>(
			UtilsNamings.localizationServiceName
		);
		Vue.prototype.$container = context.iocContainer;
		const currentLocaleService = container.get<ICurrentLocaleService>(UtilsNamings.currentLocaleServiceName);
		const i18n = context.i18n;
		currentLocaleService.hub.bind(CurrentLocaleServiceEvents.LocaleChanged, (locale: string) => {
			i18n.locale = locale;
		});
		const localizationLoadingService = container.get<ILocalizationLoadingService>(
			UtilsNamings.localizationLoadingService
		);
		localizationLoadingService.hub.bind(
			LocalizationStorageServiceEvents.LocaleLoaded,
			(locale: string, messages: any) => {
				i18n.mergeLocaleMessage(locale, messages);
			}
		);
		return Promise.resolve();
	}

	public afterRun(context: IAppContext): Promise<void> {
		const i18n = context.i18n;
		const currentLocaleService = container.get<ICurrentLocaleService>(UtilsNamings.currentLocaleServiceName);
		i18n.locale = currentLocaleService.getCurrentLocaleName();
		const localizationLoadingService = container.get<ILocalizationLoadingService>(
			UtilsNamings.localizationLoadingService
		);
		const ensureLocaleDeferred = localizationLoadingService.ensureLocale(i18n.locale);
		return ensureLocaleDeferred;
	}
}
