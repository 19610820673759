import { container } from 'utils/ioc/container';
import { UtilsNamings } from 'utils/utils-namings';
import { EventHubService } from 'utils/events';
import { LoggingService } from 'utils/logging/logging-service';
import { TimeoutService } from 'utils/misc/timeout-service';
import { QService } from 'utils/misc/q-service';
import { FormattingService } from 'utils/formatting/formatting-service';
import { ApiConfigProvider } from 'utils/http/http-service-base';
import { AxiosHttpService } from 'utils/http/axios-http-service';
import { CurrentLocaleService } from 'utils/localization/current-locale-service';
import { AuthStateService } from 'utils/auth/auth-state-service';
import { LocalStorageService } from 'utils/local-storage/local-storage-service';
import { StaticService } from 'utils/static-service';
import { StateService } from 'utils/routing/state-service';
import { LocalizationService } from 'utils/localization/localization-service';
import { LocalizationLoadingService } from 'utils/localization/localization-loading-service';
import { IntervalService } from './misc/interval-service';
import { RouteUtilsService } from './routing/route-utils-service';
import { Uid } from './common/utils';

export function configureIoC() {
	container
		.bind<ILoggingService>(UtilsNamings.loggingServiceName)
		.to(LoggingService)
		.inSingletonScope();
	container
		.bind<IEventHubService>(UtilsNamings.eventHubServiceName)
		.to(EventHubService)
		.inSingletonScope();
	container
		.bind<IEventHub<any>>(UtilsNamings.globalEventHubName)
		.toDynamicValue(context => {
			const eventHubService = context.container.get<IEventHubService>(UtilsNamings.eventHubServiceName);
			return eventHubService.create(Uid.create(), 'global');
		})
		.inSingletonScope();
	container
		.bind<ITimeoutService>(UtilsNamings.timeoutServiceName)
		.to(TimeoutService)
		.inSingletonScope();
	container
		.bind<IQService>(UtilsNamings.qServiceName)
		.to(QService)
		.inSingletonScope();
	container
		.bind<IFormattingService>(UtilsNamings.formattingServiceName)
		.to(FormattingService)
		.inSingletonScope();
	container
		.bind<IApiConfigProvider>(UtilsNamings.apiConfigProviderName)
		.to(ApiConfigProvider)
		.inSingletonScope();
	container
		.bind<IHttpService>(UtilsNamings.httpServiceName)
		.to(AxiosHttpService)
		.inSingletonScope();
	container
		.bind<ICurrentLocaleService>(UtilsNamings.currentLocaleServiceName)
		.to(CurrentLocaleService)
		.inSingletonScope();
	container
		.bind<IAuthStateService>(UtilsNamings.authStateServiceName)
		.to(AuthStateService)
		.inSingletonScope();
	container
		.bind<ILocalStorageService>(UtilsNamings.localStorageServiceName)
		.to(LocalStorageService)
		.inSingletonScope();
	container
		.bind<IStaticService>(UtilsNamings.staticServiceName)
		.to(StaticService)
		.inSingletonScope();
	container
		.bind<IStateService>(UtilsNamings.stateServiceName)
		.to(StateService)
		.inSingletonScope();
	container
		.bind<ILocalizationService>(UtilsNamings.localizationServiceName)
		.to(LocalizationService)
		.inSingletonScope();
	container
		.bind<ILocalizationLoadingService>(UtilsNamings.localizationLoadingService)
		.to(LocalizationLoadingService)
		.inSingletonScope();
	container
		.bind<IIntervalService>(UtilsNamings.intervalServiceName)
		.to(IntervalService)
		.inSingletonScope();
	container
		.bind<IRouteUtilsService>(UtilsNamings.routeUtilsServiceName)
		.to(RouteUtilsService)
		.inSingletonScope();
	// container
	// 	.bind<IClientConfigService>(UtilsNamings.clientConfigServiceName)
	// 	.to(StaticClientConfigService)
	// 	.inSingletonScope();
}
