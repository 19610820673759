import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './button.html';

import './button.scss';
import './../preloader/preloader.scss';

@WithRender
@Component
export class Button extends Vue {
	@Prop()
	public autofocus!: boolean;
	@Prop()
	public isLoading!: boolean;
	@Prop()
	public type!: string;
	@Prop()
	public title!: string;
}
