import { FormRow } from 'ui-controls/forms/layout/form-row';
import { FormGroup } from 'ui-controls/forms/layout/form-group';
import { PnzBootstrapForm } from 'ui-controls/forms/bootstrap-form/bootstrap-form';
import { Preloader } from 'ui-controls/preloader/preloader';
import { Button } from 'ui-controls/buttons/button';
import { Checkbox } from 'ui-controls/checkboxes/checkbox';
import { Panel } from 'ui-controls/panels/panel';
import { EmptyRow } from 'ui-controls/grids/empty-row';
import { ErrorBlock } from 'ui-controls/forms/error-block/error-block';
import { FromGroupWithErrorBlock } from 'ui-controls/forms/form-group-with-error/form-group-with-error';
import { Captcha } from 'ui-controls/captcha/captcha';
import { ModalDialogPlugin } from './dialogs';
import Vue from 'vue';

export function registerUIComponents() {
	Vue.use(ModalDialogPlugin);

	Vue.component('pnz-panel', Panel);
	Vue.component('pnz-empty-row', EmptyRow);
	Vue.component('pnz-error-block', ErrorBlock);
	Vue.component('pnz-form-group-with-error-block', FromGroupWithErrorBlock);
	Vue.component('pnz-form', PnzBootstrapForm);
	Vue.component('pnz-form-row', FormRow);
	Vue.component('pnz-form-group', FormGroup);
	Vue.component('pnz-preloader', Preloader);
	Vue.component('pnz-button', Button);
	Vue.component('pnz-checkbox', Checkbox);
	Vue.component('pnz-captcha', Captcha);
}
