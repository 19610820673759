declare global {
	type IAssetItem = string | string[];

	interface IAssets {
		jpg?: IAssetItem;
		png?: IAssetItem;
		gif?: IAssetItem;
	}
}

export function isFileSystem(): boolean {
	return document.location.protocol === 'file:';
}
export function preloadImage(url: string): Promise<void> {
	const image = new Image();
	return new Promise(resolve => {
		if (isFileSystem()) {
			url = url.replace(/^\//, ''); //remove leading slash
		}
		image.onload = () => {
			resolve();
		};
		image.src = url;
	});
}

function getArrayForExtension(input: string | string[] | undefined): string[] {
	if (!input) {
		return [];
	}
	return typeof input === 'string' ? [input] : input;
}
export function preloadImagesFromAssets(assets: { '': IAssets }): Promise<void> {
	const resources = assets[''];
	const pngs = getArrayForExtension(resources.png);
	const jpgs = getArrayForExtension(resources.jpg);
	const gifs = getArrayForExtension(resources.gif);
	const urls = Array.prototype.concat(jpgs, pngs, gifs);
	const loaders: Array<Promise<void>> = [];
	for (let url of urls) {
		if (!DEVELOPMENT) {
			url = url.replace('/dev-app/', '/app/');
		} else {
			url = url.replace('/app/', '/dev-app/');
		}
		loaders.push(preloadImage(url));
	}
	return Promise.all(loaders)
		.then(_ => {})
		.catch();
}
