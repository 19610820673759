import { LoginRouteNames } from '../login/routes/login-routes-namings';
import { PasswordRecoveryRoutes } from '../password-recovery/routes/password-recovery-routes';
import { RegistrationRoutes } from '../registration/routes/registration-routes';
import { LoginRoutes } from '../login/routes/login-routes';
import { LicenseAgreement } from '../license-agreement/license-agreement';
import { RegistrationRouteNames } from '../registration/routes/registration-routes-namings';
import { LicenseAgreementRouteNames } from '../license-agreement/license-agreement-route-namings';
import { Wizard } from 'ui-controls/wizard/wizard';

const authRoutes: IWizardRouteConfig[] = [
	...LoginRoutes,
	...PasswordRecoveryRoutes,
	...RegistrationRoutes,
	{
		path: LicenseAgreementRouteNames.LicenseAgreement,
		name: LicenseAgreementRouteNames.LicenseAgreement,
		component: LicenseAgreement,
		meta: {
			title: 'licenseAgreement' as const,
			back: RegistrationRouteNames.ShortRegistration
		}
	},
	{
		path: LicenseAgreementRouteNames.LandingLicenseAgreement,
		name: LicenseAgreementRouteNames.LandingLicenseAgreement,
		component: LicenseAgreement,
		meta: {
			title: 'licenseAgreement' as const,
			back: RegistrationRouteNames.LandingRegistration
		}
	},
	{ path: '*', redirect: { name: LoginRouteNames.Login } },
	{ path: '', redirect: { name: LoginRouteNames.Login } }
];

export class AuthRoutesOptions {
	public closable = false;
	public onClose?: Function;
}

function GetAuthRoutes(options: AuthRoutesOptions) {
	return [
		{
			path: '',
			component: Wizard,
			children: authRoutes,
			props: options,
			redirect: { name: LoginRouteNames.Login }
		},
		{ path: '/', redirect: { name: LoginRouteNames.Login } },
		{ path: '*', redirect: { name: LoginRouteNames.Login } }
	];
}

export { GetAuthRoutes };
