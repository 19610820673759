import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IDsCrystalPackControllerService {
		getList(): IResponsePromise<ICrystalPackResponse[]>;
	}
}

@injectable()
export class DsCrystalPackControllerService extends HttpServiceBase implements IDsCrystalPackControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public getList(): IResponsePromise<ICrystalPackResponse[]> {
		const path = '/api/ds/payments/crystalpack/list';
		const config = this.getMethodConfig('getList');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<ICrystalPackResponse[]>>(url, {}, config.requestConfig);
	}
}
