import { UtilsNamings } from 'utils/utils-namings';
import { container } from 'utils/ioc/container';

const LocalizeMediaFilter: IFilter<IWbMediaLocalizationCollectionResponse> = function(
	this: undefined,
	input: IWbMediaLocalizationCollectionResponse
): string | null {
	const currentLocaleService = container.get<ICurrentLocaleService>(UtilsNamings.currentLocaleServiceName);
	const currentLocale = currentLocaleService.getCurrentLocaleId();
	const forCurrentLocale = (input.byLocales as { [key: string]: IWbMediaLocalizationResponse })[currentLocale];
	if (forCurrentLocale) {
		return forCurrentLocale.url;
	}
	if (input.default) {
		return input.default.url;
	}
	return null;
};

export { LocalizeMediaFilter };
