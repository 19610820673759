import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface ILsTicketControllerService {
		ticket(): IResponsePromise<ITicketResponse>;
	}
}

@injectable()
export class LsTicketControllerService extends HttpServiceBase implements ILsTicketControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public ticket(): IResponsePromise<ITicketResponse> {
		const path = '/api/ls/auth/ticket/';
		const config = this.getMethodConfig('ticket');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<ITicketResponse>>(url, {}, config.requestConfig);
	}
}
