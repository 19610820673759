import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';

import './modal.scss';
import { CreateElement, VNodeChildrenArrayContents, VNode } from 'vue';
import { CloseIcon } from 'ui-controls-core/icons/close-icon/close-icon';

@Component
export class Modal extends UIComponent {
	@Prop({ type: String })
	public title!: string;

	@Prop({ type: String })
	public className!: string;

	@Prop({ type: Boolean, default: false })
	public isScroll!: boolean;

	@Prop({ type: Boolean, default: false })
	public escapable!: boolean;

	@Prop({ type: Boolean, default: true })
	public dismissable!: boolean;

	@Prop({ type: Boolean, default: false })
	public fullscreen!: boolean;

	@Prop({ type: Boolean, default: false })
	public isTop!: boolean;

	@Prop({ type: Boolean, default: false })
	public isBottom!: boolean;

	@Prop({ type: Boolean, default: false })
	public isLeft!: boolean;

	@Prop({ type: Boolean, default: false })
	public isRight!: boolean;

	@Prop({ type: Boolean, default: false })
	public center!: boolean;

	@Prop({ type: String, default: 'md' })
	public size!: string;

	@Prop({ type: Boolean, default: true })
	public bodyPadding!: boolean;

	constructor() {
		super();
	}

	public get propsData() {
		const parentNodeProps = this.$parent!.$vnode!.data!.props;
		return parentNodeProps && parentNodeProps.vModal ? parentNodeProps.vModal : this.$props;
	}

	public render(h: CreateElement): VNode {
		const {
			dismissable,
			title,
			isScroll,
			fullscreen,
			isTop,
			isBottom,
			isLeft,
			isRight,
			center,
			size,
			className,
			bodyPadding
		} = this.propsData;

		const closeBtn = dismissable
			? h(
					'div',
					{
						class: 'vu-modal__close-btn',
						on: {
							click: () => {
								this.$modals.dismiss();
							}
						}
					},
					[h(CloseIcon)]
			  )
			: null;

		const headerContent = this.$slots.header
			? this.$slots.header
			: title
			? h('span', { class: ['vu-modal__cmp-header-title'] }, title)
			: null;

		const header = headerContent
			? h(
					'div',
					{
						class: ['vu-modal__cmp-header']
					},
					[headerContent]
			  )
			: null;
		const body = h(
			'div',
			{
				class: ['vu-modal__cmp-body'],
				style: {
					overflowY: isScroll ? 'auto' : null,
					padding: bodyPadding ? '1em' : 0
				}
			},
			[this.$slots.default]
		);

		const footer = this.$slots.footer
			? h(
					'div',
					{
						class: ['vu-modal__cmp-footer']
					},
					[this.$slots.footer]
			  )
			: null;

		const style: any = {};
		let translateX = '-50%';
		let translateY = '0';
		if (center) {
			translateX = '-50%';
			translateY = '-50%';
		}
		if (isRight || isLeft) {
			translateX = '0%';
		}

		if ((isTop || isBottom) && !isScroll && !center) {
			translateY = '0%';
		}
		style.transform = `translate(${translateX}, ${translateY})`;

		const vNodeChildrenArrayContents: VNodeChildrenArrayContents = [closeBtn!, header!, body!, footer!];

		return h(
			'div',
			{
				style,
				attrs: { tabindex: -1 },
				class: [
					'vu-modal__cmp',
					{
						'vu-modal__cmp--is-fullscreen': fullscreen,
						'vu-modal__cmp--is-center': center,
						'vu-modal__cmp--is-top': isTop && !isScroll && !center,
						'vu-modal__cmp--is-bottom': isBottom && !isScroll && !center,
						'vu-modal__cmp--is-left': isLeft,
						'vu-modal__cmp--is-right': isRight
					},
					isScroll && fullscreen && 'vu-modal__cmp--is-scroll-fullscreen',
					isScroll && !fullscreen && 'vu-modal__cmp--is-scroll',
					!fullscreen && `vu-modal__cmp--${size}`,
					className
				],
				on: {
					click: (event: Event) => {
						event.stopPropagation();
					}
				}
			},
			vNodeChildrenArrayContents
		);
	}

	public mounted(): void {
		const htmlElement = this.$el as HTMLElement;
		if (htmlElement.focus) {
			htmlElement.focus();
		}
	}
}
