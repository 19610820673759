export class MembershipNamings {
	public static moduleName = 'pnz-membership';

	public static loginFromController = 'pnz-membership-login-from-controller';
	public static shunnetLoginController = 'pnz-shunnet-login-controller';
	public static registrationFormController = 'pnz-membership-registration-form-controller';
	public static shortRegistrationFormController = 'pnz-membership-short-registration-form-controller';
	public static registrationFormFieldController = 'pnz-membership-registration-form-field-controller';
	public static loginConfirmationController = 'pnz-login-confirmation-controller';
	public static loginConfirmationFormController = 'pnz-login-confirmation-form-controller';

	public static settingsProviderName = 'pnz-membership-settings-provider';
	public static externalLoginServiceProviderName = 'pnz-membership-external-login-service-provider';
	public static membershipServiceName = 'pnz-membership-service';
	public static banServiceName = 'pnz-ban-service';
	public static bootstrapServiceName = 'pnz-membership-bootstrap-service';
	public static externalLoginHost = 'pnz-external-login-host';
	public static legacyUIserviceName = 'pnz-legacy-ui-service';
	public static setPasswordServiceName = 'pnz-set-password';
	public static eventHubServiceName = 'pnz-event-hub-service';
	public static registrationHelperService = 'pnz-registration-helper-service';
	public static registrationConfigurationServiceName = 'pnz-membership-settings-service';
	public static registrationFormIconControllerName = 'pnz-registration-form-icon-controller';

	public static confirmNewbieController = 'pnz-confirm-newbie-controller';
	public static bindLoginsController = 'pnz-bind-logins-controller';
	public static bindPanzarLoginController = 'pnz-bind-panzar-login-controller';

	public static passwordRecoveryWizardController = 'pnz-password-recovery-wizard-controller';
	public static passwordRecoveryController = 'pnz-password-recovery-controller';
	public static emailPasswordRecoveryController = 'pnz-email-password-recovery-controller';
	public static secretQuestionRecoveryController = 'pnz-secret-question-recovery-controller';
	public static secretQuestionAnswerController = 'pnz-secret-question-answer-controller';
	public static verificationCodeController = 'pnz-verification-code-controller';
	public static passwordSetControllerName = 'pnz-password-set-controller';
	public static banDialogControllerName = 'pnz-ban-dialog-controller';

	public static persistentLoginServiceName = 'pnz-membership-persistent-login-service';
}
