import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IAdCampaignPartialUpdateRequest extends IAdCampaignRequestBase {}

	interface IGaAdCampaignControllerService
		extends IRestServiceBase<
				{},
				IAdCampaignResponse,
				IAdCampaignFilterRequest,
				IAdCampaignCreateRequest,
				IAdCampaignUpdateRequest,
				IAdCampaignPartialUpdateRequest
			> {
		copy(id: string, data: IAdCampaignCloneRequest, cancelToken?: Promise<void>): Promise<IResponsePayload<void>>;
	}
}

@injectable()
export class GaAdCampaignControllerService
	extends RestServiceBase<
		{},
		IAdCampaignResponse,
		IAdCampaignFilterRequest,
		IAdCampaignCreateRequest,
		IAdCampaignUpdateRequest,
		IAdCampaignPartialUpdateRequest
	>
	implements IGaAdCampaignControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/ad-campaign', $http, $q, apiConfigProvider);
	}

	public copy(id: string, data: IAdCampaignCloneRequest, cancelToken?: Promise<void>): Promise<IResponsePayload<void>> {
		return super.makePost<IResponsePayload<void>>(this.baseUrl + `${id}/copy`, data, {}, cancelToken);
	}
}
