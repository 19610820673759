import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaComputerBanControllerService
		extends IRestServiceBase<
				{},
				IComputerBanResponse,
				IComputerBanFilterRequest,
				IComputerBanCreateRequest,
				IComputerBanUpdateRequest,
				IComputerBanPartialUpdateRequest
			> {}
}

@injectable()
export class GaComputerBanControllerService
	extends RestServiceBase<
		{},
		IComputerBanResponse,
		IComputerBanFilterRequest,
		IComputerBanCreateRequest,
		IComputerBanUpdateRequest,
		IComputerBanPartialUpdateRequest
	>
	implements IGaComputerBanControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/computer-ban', $http, $q, apiConfigProvider);
	}
}
