import { container } from './ioc/container';
import { Store } from 'vuex';
import { UtilsNamings } from './utils-namings';
import { getModule } from 'vuex-module-decorators';
import { UserSettingsModule, DateTimeFormat } from './stores/user-settings-module';
import { DateTime, Zone } from 'luxon';
import { getCurrentTimezoneOffset } from './common/utils';
import { clientConfig } from './config';

export const convertFromUserDateToUtc = (date: Date): string | null => {
	const store = container.get<Store<any>>(UtilsNamings.appStoreName);
	const userSettings = getModule(UserSettingsModule, store);
	switch (userSettings.dateTimeFormat) {
		case DateTimeFormat.Local: {
			return DateTime.fromJSDate(date)
				.toUTC()
				.toISO();
		}
		case DateTimeFormat.Server: {
			return DateTime.fromJSDate(date)
				.plus({ hours: clientConfig.serverTimeZoneOffset - getCurrentTimezoneOffset() })
				.toUTC()
				.toISO();
		}
		default: {
			return DateTime.fromJSDate(date)
				.plus({ minutes: getCurrentTimezoneOffset() })
				.toUTC()
				.toISO();
		}
	}
};

export const convertFromUtcToUserDate = (date: Date): Date => {
	const store = container.get<Store<any>>(UtilsNamings.appStoreName);
	const userSettings = getModule(UserSettingsModule, store);
	switch (userSettings.dateTimeFormat) {
		case DateTimeFormat.Local: {
			return DateTime.fromJSDate(date).toJSDate();
		}
		case DateTimeFormat.Server: {
			return DateTime.fromJSDate(date)
				.minus({ minutes: clientConfig.serverTimeZoneOffset - getCurrentTimezoneOffset() })
				.toJSDate();
		}
		default: {
			return DateTime.fromJSDate(date)
				.minus({ minutes: getCurrentTimezoneOffset() })
				.toJSDate();
		}
	}
};

export const getCurrentDate = () => {
	const store = container.get<Store<any>>(UtilsNamings.appStoreName);
	const userSettings = getModule(UserSettingsModule, store);
	switch (userSettings.dateTimeFormat) {
		case DateTimeFormat.Local: {
			return DateTime.local();
		}
		case DateTimeFormat.Server: {
			return DateTime.utc().plus({ minutes: clientConfig.serverTimeZoneOffset });
		}
		default: {
			return DateTime.local().minus({ minutes: getCurrentTimezoneOffset() });
		}
	}
};
