import { HttpServiceBase } from 'utils/http';
import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';

declare global {
	interface IDsPromoCodeControllerService {
		use(request: IPromoCodeRequest): IResponsePromise<void>;
	}
}

@injectable()
export class DsPromoCodeControllerService extends HttpServiceBase implements IDsPromoCodeControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super($http, $q, apiConfigProvider);
	}

	public use(request: IPromoCodeRequest): IResponsePromise<void> {
		const path = '/api/ds/promocode/use';
		const config = this.getMethodConfig('use');
		const url = config.getMethodUrl(path, arguments[0]);
		return this.makePost<IResponsePayload<void>>(url, request, config.requestConfig);
	}
}
