import { configureIoC } from './configure-ioc';
import { UtilsNamings } from 'utils/utils-namings';
import { Container } from 'inversify';
import Router from 'vue-router';
import Vue from 'vue';
import { container } from './ioc/container';
import { Store } from 'vuex';

Vue.use(Router);

declare global {
	interface IAppContext {
		readonly iocContainer: Container;

		readonly router: Router;

		readonly store: Store<any>;
	}
}

export class UtilsModule implements IModule {
	public moduleName = UtilsNamings.moduleName;

	public registerSubModules(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public configureIoC(context: IAppContext): Promise<void> {
		configureIoC();
		container.bind(UtilsNamings.appRouterName).toConstantValue(context.router);
		container.bind(UtilsNamings.appStoreName).toConstantValue(context.store);
		return Promise.resolve();
	}
	public configureRoutes(): Promise<void> {
		return Promise.resolve();
	}
	public registerUIComponents(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
	public configureModule(): Promise<void> {
		return Promise.resolve();
	}
	public beforeRun(): Promise<void> {
		return Promise.resolve();
	}
	public afterRun(context: IAppContext): Promise<void> {
		return Promise.resolve();
	}
}
