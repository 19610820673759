import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';
import WithRender from './license-agreement.html';

import './license-agreement.scss';

@WithRender
@Component
export class LicenseAgreement extends UIComponent {
	constructor() {
		super();
	}
}
