import { Vue, Component } from 'vue-property-decorator';
import { UtilsNamings } from 'utils/utils-namings';
import { LogSeverity } from 'utils/logging/log-severity';
import { DeepState } from '../utils/state';
import { Deferred } from '../utils/misc/deferred';
import { Uid } from '../utils/common/utils';
import { SystemModuleName } from '../utils/stores/system-module';
import { UserModuleName } from 'utils/stores/user-module';
import { HostModuleName } from 'ui-controls/stores/host-module';
import { UserSettingsModuleName, UserSettingsModule } from 'utils/stores/user-settings-module';
import { DefaultHttpErrorMessage } from 'utils/http/consts';

//@ts-ignore
@Component
export class UIComponent<TEvents = any> extends Vue implements IController<any, any> {
	private loggingService: ILoggingService;
	private eventHubService: IEventHubService;
	public eventHub: IEventHub<TEvents>;
	public globalEventHub: IEventHub;
	protected timeoutService: ITimeoutService;

	private httpErrorHandler: IHttpErrorHandler | null;
	private name: string;
	private destroyHandlers: Function[] = [];
	protected destroyPromise = new Deferred<void>();
	public readonly uid: Uid;
	constructor() {
		super();
		this.loggingService = this.get<ILoggingService>(UtilsNamings.loggingServiceName);
		this.eventHubService = this.get<IEventHubService>(UtilsNamings.eventHubServiceName);
		this.timeoutService = this.get<ITimeoutService>(UtilsNamings.timeoutServiceName);
		this.globalEventHub = this.get<IEventHub<TEvents>>(UtilsNamings.globalEventHubName);
		this.name = this.getName();
		this.uid = Uid.create();
		this.eventHub = this.eventHubService.create(this.uid, this.name);
		this.httpErrorHandler = this.getOptional<IHttpErrorHandler>(UtilsNamings.httpErrorHandlerName);
	}

	@DeepState<{ [HostModuleName]: IHostState }>([HostModuleName, 'screen'])
	public screen!: HostScreen;

	@DeepState<{ [UserModuleName]: IUserState }>([UserModuleName])
	public user!: IUserState;

	@DeepState<{ [UserSettingsModuleName]: IUserSettings }>([UserSettingsModuleName])
	public userSettings!: IUserSettings;

	@DeepState<{ [SystemModuleName]: ISystemState }>([SystemModuleName])
	public system!: ISystemState;

	protected get<T>(name: string): T {
		if (!this.$container) {
			throw new Error('$container not initialized');
		}
		return this.$container.get<T>(name);
	}

	protected getOptional<T>(name: string): T | null {
		if (!this.$container.isBound(name)) {
			return null;
		}
		return this.$container.get<T>(name);
	}

	public localize(key: ILocalizationKey): string {
		return this.$localizationService.localize(key);
	}

	private getName() {
		const funcNameRegex = /function (.{1,})\(/;
		const results = funcNameRegex.exec((<any>this).constructor.toString());
		return results && results.length > 1 ? results[1] : '';
	}

	protected getErrorMessage(error: IResponseError): string {
		if (typeof error === 'string') {
			return error;
		} else if (error.errorMessage) {
			return error.errorMessage;
		} else if (error.validationErrors && error.validationErrors.length > 0) {
			return error.validationErrors[0].error;
		} else {
			return DefaultHttpErrorMessage;
		}
	}
	protected trace(message: string, severity: LogSeverity = LogSeverity.INFO) {
		this.loggingService.log(`[CMP] [${this.uid}] [${this.$options.name}] ${message}`, severity);
	}

	protected trigger(eventName: TEvents, ...args: any[]) {
		this.eventHub.trigger(eventName, ...args);
	}

	protected bindGlobalEvent(name: string, fn: (...params: any[]) => void) {
		const unbind = this.globalEventHub.bind(name, fn.bind(this));
		this.destroyHandlers.push(unbind);
	}

	protected processFailure(actionFailure: IResponseError) {
		if (this.httpErrorHandler) {
			// tslint:disable-next-line:no-debugger
			debugger;
			//this.httpErrorHandler.handleErrorServerResponse(actionFailure as any);
		}
	}

	protected registerDestroyHandler(fn: Function) {
		this.destroyHandlers.push(fn);
	}

	public beforeDestroy(): void {
		this.eventHub.close();
		this.destroyHandlers.forEach(_ => _());
		this.destroyPromise.resolve();
	}
}
