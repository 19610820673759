import $ from 'jquery';
import { DirectiveOptions, VNodeDirective, VNode } from 'vue';

export class VisibilityDirective implements DirectiveOptions {
	public inserted(el: HTMLElement, binding: VNodeDirective, vnode: VNode, oldVnode: VNode) {
		$(el).css('visibility', binding.value ? 'visible' : 'hidden');
	}

	public update(el: HTMLElement, binding: VNodeDirective, vnode: VNode, oldVnode: VNode) {
		if (binding.value === binding.oldValue) {
			return;
		}
		$(el).css('visibility', binding.value ? 'visible' : 'hidden');
	}
}
