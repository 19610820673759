import { UtilsNamings } from 'utils/utils-namings';
import { injectable, inject } from 'inversify';
import { RestServiceBase } from 'utils/http/rest-service-base';

declare global {
	interface IGaWbNewsMessagePlacementControllerService
		extends IRestServiceBase<
				{},
				IWbNewsMessagePlacementResponse,
				IWbNewsMessagePlacementFilterRequest,
				IWbNewsMessagePlacementCreateRequest,
				IWbNewsMessagePlacementUpdateRequest,
				IWbNewsMessagePlacementPartialUpdateRequest
			> {}
}

@injectable()
export class GaWbNewsMessagePlacementControllerService
	extends RestServiceBase<
		{},
		IWbNewsMessagePlacementResponse,
		IWbNewsMessagePlacementFilterRequest,
		IWbNewsMessagePlacementCreateRequest,
		IWbNewsMessagePlacementUpdateRequest,
		IWbNewsMessagePlacementPartialUpdateRequest
	>
	implements IGaWbNewsMessagePlacementControllerService {
	constructor(
		@inject(UtilsNamings.httpServiceName) $http: IHttpService,
		@inject(UtilsNamings.qServiceName) $q: IQService,
		@inject(UtilsNamings.apiConfigProviderName) apiConfigProvider: IApiConfigProvider
	) {
		super('/internal/ga/wb-news-message-placement', $http, $q, apiConfigProvider);
	}
}
